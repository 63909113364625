.mobile-nav {
  background-color: #1f2130;
  height: 83vh;
  width: 85%;
  margin: 0 auto !important;
  border-radius: 8px;
  position: absolute;
  z-index: 1000;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-loading-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 9000;
  background-color: #000;
  opacity: 0.5;

  &__spinner-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
