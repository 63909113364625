.content-card-container {
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;

  .c-icon {
    color: #8c8c8c;
    font-size: 22px;
  }

  .c-right {
    flex: 1;
    padding-left: 20px;

    .c-title-contaner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .c-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px !important;
        color: #73847f;
      }

      .c-delete {
        width: 28px;
        height: 28px;
        background: #f05a5a;
        border-radius: 4px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .c-video {
      .cv-top {
        display: flex;

        .cv-filename {
          border-bottom: 1px solid #6a6878;
          color: white;
          flex: 1;
        }

        .cv-button {
          button {
            width: 140px;
            height: 36px;
            background: #494c67;
            border-radius: 6px;
            font-size: 16px;
            color: white;
          }
        }
      }
    }
  }
}

.content-video-card-container-preview {
  background-color: white;
  padding-bottom: 10px;

  video {
    width: 100%;
  }
}
