@import './../styles/variables';

.practise-exam-card {
  background-color: map-get($colors, primary-blue-light);
  //   background-color: red;
  font-size: 15px;
  border-radius: 8px;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;

  * {
    color: map-get($colors, pure);
    line-height: 1.1;
  }

  .practise-exam-card__visible {
    display: flex;
    width: 100%;
  }

  .practise-exam-card__date-time {
    min-width: 160px;
    max-width: 160px;
    align-self: center;
  }

  .practise-exam-card__date {
    font-size: 10px;
  }

  .practise-exam-card__start-date-con {
    display: flex;
  }

  .practise-exam-card__end-date-com {
    display: flex;
  }

  .practise-exam-card__start-label,
  .practise-exam-card__start-time,
  .practise-exam-card__end-label,
  .practise-exam-card__end-time {
    color: #979797;
  }

  .practise-exam-card__start-label,
  .practise-exam-card__end-label {
    width: 35px;
  }

  .practise-exam-card__start-time,
  .practise-exam-card__end-time {
    margin-left: 10px;
  }

  .practise-exam-card__time {
    font-size: 10px;
    margin-top: 2px;
    color: map-get($colors, grey);
  }

  .practise-exam-card__title {
    flex: 1;
    align-self: center;
    min-width: 15px;
    display: none;
  }

  .practise-exam-card__price {
    min-width: 77px;
    max-width: 100px;
    padding-right: 10px;
    padding-left: 10px;
    height: 23px;
    background-color: map-get($colors, blue-type-1);
    border-radius: 3px;
    color: map-get($colors, pure);
    text-align: center;
    overflow: hidden;
    align-self: center;

    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 300;
    }
  }

  .practise-exam-card__icon {
    align-self: center;
    width: 20px;
    margin-left: 10px;
    text-align: right;
  }

  .practise-exam-card__hidden {
    display: flex;
    margin-top: 15px;
    align-items: center;

    .practise-exam-card__status {
      font-size: 13px;
      font-weight: 400;
      color: #979797;
      width: 115px;
      align-self: center;

      span {
        color: white;
      }
    }

    .practise-exam-card__type {
      font-size: 16px;
    }
  }
}

@media (min-width: 576px) {
  .practise-exam-card {
    .practise-exam-card__price {
      margin-left: 5px !important;
    }
  }
}

@media (min-width: 768px) {
  .practise-exam-card {
    padding: 15px;
    .practise-exam-card-md__status {
      font-size: 13px;
      width: 175px;
      align-self: center;
      padding-left: 10px;
      color: #20d167;
    }

    .practise-exam-card-md__score {
      width: 175px;
      padding-left: 10px;
      .practise-exam-card-md__score-title {
        color: #979797;
        font-size: 13px;
      }
    }

    .practise-exam-card-md__type {
      font-size: 13px;
      width: 65px;
      align-self: center;
      padding-left: 5px;
    }
  }
}

@media (min-width: 992px) {
  .practise-exam-card {
    .practise-exam-card-md__status {
      font-size: 15px;
      padding-left: 5px;
    }

    .practise-exam-card-md__score {
      font-size: 15px;
      padding-left: 5px;
    }

    .practise-exam-card-md__type {
      font-size: 15px;
    }

    .practise-exam-card__date-time {
      min-width: 205px;
      max-width: 210px;
    }
    .practise-exam-card__date {
      font-size: 12px;
    }
  }
}

.practise-exam-card__status-pending-mobile {
  width: 115px;
  font-weight: 400;
  & > div:nth-child(1) {
    color: grey;
  }

  & > div:nth-child(2) {
    color: rgb(243, 182, 25);
  }
}
