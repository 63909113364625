@import './../styles/variables';

.avatar {
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  .avatar-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    position: relative;
    margin: auto;

    .avatar-edit {
      width: 25px;
      height: 25px;
      background-color: map-get($colors, secondary-blue);
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, -50%);

      .avatar-edit-icon {
        position: absolute;
        left: 27%;
        top: 23%;
      }
    }
  }

  .avatar-username {
    font-size: 17.5px;
    color: map-get($colors, pure);
    margin-top: 13.5px;
  }
}
