@import './../styles/variables';

.stu-pr-cd {
  // width: 280px * $stu-paper-results-card;
  width: 350px * $stu-paper-results-card;
  padding: 30px * $stu-paper-results-card 35px * $stu-paper-results-card;
  background-color: #1f2130;
  font-size: 14px * $stu-paper-results-card;
  border-radius: 10px * $stu-paper-results-card;
  line-height: 1;

  &__header {
    display: flex;
  }

  &__title {
    color: #fff;
    font-size: 12px * $stu-paper-results-card;
  }

  &__tag {
    padding: 2px * $stu-paper-results-card;
    background-color: #404462;
    border-radius: 5px * $stu-paper-results-card;
    align-self: flex-start;
    padding: 5px * $stu-paper-results-card 10px * $stu-paper-results-card;
    margin-left: 10px * $stu-paper-results-card;
    color: #cdcdcd;
    font-size: 12px * $stu-paper-results-card;
  }

  &__main {
    display: flex;
    align-items: center;
    margin-top: 20px * $stu-paper-results-card;
  }

  &__qtns {
    &-time {
      width: 100px * $stu-paper-results-card;
      overflow: hidden;
    }

    &-number {
      display: inline-block;
      margin-right: 10px * $stu-paper-results-card;
      color: #fff;
    }

    &-text {
      color: #8b8b8b;
      font-size: 12px * $stu-paper-results-card;
    }
  }

  &__time {
    margin-top: 5px * $stu-paper-results-card;
    font-size: 21px * $stu-paper-results-card;

    &-hour,
    &-hour-text,
    &-minute,
    &-minute-text {
      display: inline-block;
    }

    &-hour {
      color: #fff;
    }

    &-minute {
      color: #fff;
      margin-left: 10px * $stu-paper-results-card;
    }

    &-hour-text,
    &-minute-text {
      color: #8b8b8b;
    }
  }

  &__grade {
    background-color: #292c3f;
    border-radius: 8px * $stu-paper-results-card;
    padding: 10px * $stu-paper-results-card 25px * $stu-paper-results-card;
    text-align: center;
    margin-left: auto;
    font-size: 20px * $stu-paper-results-card;

    &-value {
      color: #fff;
    }

    &-text {
      color: #246bfd;
      font-weight: 400;
    }
  }

  &__footer {
    text-align: center;
    margin-top: 30px * $stu-paper-results-card;
  }
}
