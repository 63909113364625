@import './../styles/variables';

.r-modal {
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  position: absolute;
  border-radius: 15px;
  overflow: auto;
  outline: none;
  padding: 20px 35px 40px 35px;
  width: 450px;
  background-color: map-get($colors, primary-blue-light);
  top: 10vh;
  transition: 0.3s;
}

.r-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
  transition: 0.3s;
}

.r-modal-header {
  color: map-get($colors, pure);
  font-size: 25px;
}

.modal-body-text {
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: map-get($colors, pure);
  line-height: 1.2;
}

.close-btn {
  background-color: map-get($colors, danger);
  color: map-get($colors, pure);
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 20%;
  font-weight: bolder;
  font-size: 26px;
  span {
    display: block;
    margin-top: -9px;
  }
}
.model-btn {
  color: map-get($colors, pure);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  height: 35px;
  width: 175px;
}
.modal-btn-primary {
  background-color: map-get($colors, secondary-blue);
}
.modal-btn-secondary {
  background-color: transparent;
  border: 1px solid map-get($colors, secondary-blue);
}

.bottom-btn-container {
  margin-top: 10px;
}
