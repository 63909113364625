@import './../styles/variables';

.live-exam-card {
  background-color: map-get($colors, primary-blue-light);
  //   background-color: red;
  font-size: 15px;
  border-radius: 8px;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;

  * {
    color: map-get($colors, pure);
    line-height: 1.1;
  }

  .live-exam-card__visible {
    display: flex;
    width: 100%;
  }

  .live-exam-card__date-time {
    min-width: 115px;
    max-width: 120px;
    align-self: center;
  }

  .live-exam-card__date {
    font-size: 12px;
  }

  .live-exam-card__time {
    font-size: 10px;
    margin-top: 2px;
    color: map-get($colors, grey);
  }

  .live-exam-card__title {
    flex: 1;
    align-self: center;
    min-width: 15px;
    display: none;
  }

  .live-exam-card__price {
    min-width: 77px;
    max-width: 100px;
    padding-right: 10px;
    padding-left: 10px;
    height: 23px;
    background-color: map-get($colors, blue-type-1);
    border-radius: 3px;
    color: map-get($colors, pure);
    text-align: center;
    overflow: hidden;
    align-self: center;

    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-weight: 300;
    }
  }

  .live-exam-card__icon {
    align-self: center;
    width: 20px;
    margin-left: 10px;
    text-align: right;
  }

  .live-exam-card__hidden {
    display: flex;
    margin-top: 15px;

    .live-exam-card__score {
      font-size: 15px;
      font-weight: 400;
      color: #979797;
      width: 115px;
      align-self: center;
    }

    .live-exam-card__status {
      font-size: 15px;
      font-weight: 400;
      color: #20d167;
      width: 115px;
      align-self: center;
    }

    .live-exam-card__type {
      font-size: 16px;
    }
  }

  .live-exam-card-md__score-title {
    text-align: center;
  }

  .live-exam-card-md__score-value {
    margin-top: 3px;
    text-align: center;
  }
}

@media (min-width: 576px) {
  .live-exam-card {
    .live-exam-card__price {
      margin-left: 5px !important;
    }
  }
}

@media (min-width: 768px) {
  .live-exam-card {
    padding: 15px;
  }

  .live-exam-card-md__status {
    width: 175px;
    align-self: center;
    padding-left: 10px;
    color: #20d167;
    font-size: 16px;
    font-weight: 400;
  }

  .live-exam-card-md__score {
    width: 175px;
    padding-left: 10px;
    .live-exam-card-md__score-title {
      color: #979797;
      font-size: 13px;
    }
  }

  .live-exam-card-md__type {
    font-size: 14px;
    width: 65px;
    align-self: center;
    padding-left: 5px;
  }
}

@media (min-width: 992px) {
  .live-exam-card-md__status {
    padding-left: 5px;
  }

  .live-exam-card-md__score {
    font-size: 15px;
    padding-left: 5px;
  }

  .live-exam-card-md__type {
    font-size: 15px;
  }
}
