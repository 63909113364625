@import './../styles/variables';

//paper creation styles

.create-structured-paper {
  margin-top: 105px;
  .create-structured-paper__main {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
}

//end of paper creation styles

.paper-creation-final-sec {
  height: calc(100vh - 280px);
  overflow: auto;
  padding-right: 10px;
  * {
    transition: none;
  }
}

.paper-card-list {
  height: calc(100vh - 310px) !important;
  overflow: auto;
}
.manual-paper-screen {
  * {
    transition: none;
  }

  .manual-paper-screen__top-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 95px;
    margin-bottom: 10px;
  }

  .manual-paper-screen__dnd {
    display: flex;
    width: 100%;
    padding-top: 2px;
    height: calc(100vh - 280px);
    overflow: auto;
    overflow-x: hidden;
  }

  .manual-paper-screen__buttons {
    margin-top: 40px;
  }

  .manual-paper-screen__selected-qtns {
    color: map-get($colors, secondary-grey);
    font-size: 15px;
  }
}

.paper-questions-complexity-input {
  width: 39px;
  outline: none;
  border: none;
  border-bottom: 1px solid map-get($colors, secondary-grey);
  background-color: transparent;
  color: map-get($colors, pure);
  font-size: 26px * $text-input-factor;
  text-align: center;
  margin-left: 3px;
}

.question-complexity-selection {
  color: map-get($colors, grey);
  margin-top: 105px;
}

.paper-creation-final-sec__button-wrapper {
  width: 620px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

//preview

.paper-preview__title {
  min-height: 70px;
  background-color: #1f2130;
  padding: 15px;
  border-radius: 8px;
  h6 {
    color: #73847f;
    margin: 0;
    font-size: 15px;
  }
  div {
    color: #fff;
    font-size: 20px;
    line-height: 1.2;
  }
}

.paper-preview__description {
  height: auto;
  background-color: #1f2130;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  overflow: hidden;

  h6 {
    color: #73847f;
    margin: 0;
    font-size: 15px;
  }
  div {
    color: #fff;
    font-size: 15px;
    line-height: 1.2;
    font-weight: 400;
  }
}
.paper-preview__main-content {
  height: calc(100vh - 200px);
  overflow: auto;
  overflow-x: hidden;
  padding-top: 5px;
}

.paper-preview__data {
  height: 70px;
  background-color: #1f2130;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .paper-preview__total-question,
  .paper-preview__allocated-time,
  .paper-preview__p-type {
    h6 {
      color: #73847f;
      margin: 0;
      font-size: 15px;
    }
    div {
      color: #fff;
      font-size: 15px;
      line-height: 1.2;
      font-weight: 400;
    }
  }
}

.paper-preview-question-headers {
  margin-top: 45px;
  div:nth-child(1) {
    padding-left: 30px;
    width: 116px;
    color: map-get($colors, grey);
  }

  div:nth-child(2) {
    padding-left: 40px;
    flex: 1;
    color: map-get($colors, grey);
  }

  div:nth-child(3) {
    width: 155px;
    text-align: center;
    color: map-get($colors, grey);
  }

  .q-card-list {
    height: calc(100vh - 360px) !important;
    overflow: auto;
  }
}

.characters {
  color: #fff;
  * {
    transition: none;
  }
}

.paper-creation-final-sec-wrapper {
  margin-top: 100px;
}

.question-card-in-paper {
  display: flex;
  justify-content: space-between;
  padding-left: 70px;
  padding-right: 30px;
  .question-card-in-paper__right {
    display: flex;
    width: 290px;
  }

  .question-card-in-paper__title,
  .question-card-in-paper__complexity,
  .question-card-in-paper__marks {
    color: map-get($colors, grey);
  }

  .question-card-in-paper__complexity {
    padding-left: 17px;
  }

  .question-card-in-paper__marks {
    margin-left: auto;
  }
}
