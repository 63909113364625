.taa-nav {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  
    &__tabs {
      display: flex;
      font-size: 12px;
    }
  
    &__assignment {
      padding: 7px 40px;
      color: #fff;
      cursor: pointer;
      border: 1px solid #3c4058;
      filter: drop-shadow(0px 0px 6.6667px #181924);
      border-radius: 6px 0px 0px 6px;
      width: 175px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &--active {
        border: 1px solid #246bfd;
        color: #246bfd;
      }
    }
    &__payment {
      padding: 7px 40px;
      border-radius: 0px 5px 5px 0px;
      color: #fff;
      cursor: pointer;
      border: 1px solid #3c4058;
      filter: drop-shadow(0px 0px 6.6667px #181924);
      border-radius: 0px 6px 6px 0px;
      width: 175px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &--active {
        border: 1px solid #246bfd;
        color: #246bfd;
      }
    }
  }
  
  .taa-keys {
    display: flex;
    font-size: 10px;
    font-weight: 400;
    color: #6a6878;
    align-items: center;
  
    &-one {
      display: flex;
      align-items: center;
  
      div {
        width: 10px;
        height: 10px;
        background-color: #246bfd;
        border-radius: 2px;
        margin-right: 3px;
        margin-bottom: 2.5px;
      }
    }
  
    &-two {
      display: flex;
      align-items: center;
      margin-left: 10px;
  
      div {
        width: 10px;
        height: 10px;
        background-color: #89f1ff;
        border-radius: 2px;
        margin-right: 3px;
        margin-bottom: 2.5px;
      }
    }
  
    &-three {
      display: flex;
      align-items: center;
      margin-left: 10px;
  
      div {
        color: red;
        font-size: 12px;
        margin-bottom: 2.5px;
      }
    }
  
    &-container {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
  
      button {
        border-radius: 4px;
        background-color: #246bfd;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        padding: 3px 40px;
      }
    }
  
    &-btn--disabled {
      background-color: grey !important;
      cursor: default !important;
    }
  }
  
  .taa-chart {
    &__cards {
      height: calc(100vh - 435px);
      overflow: auto;
    }
  
    &__tas {
      margin-top: 10px;
      display: flex;
      align-items: center;
      padding: 10px 20px;
  
      &-stu-name {
        min-width: 150px;
        color: #979797;
        font-size: 14px;
      }
  
      &-checkboxs {
        display: flex;
      }
  
      &-checkbox-item {
        display: flex;
        min-width: 120px;
        margin: 0px 10px;
      }
  
      &-name-container {
        margin-left: 5px;
        margin-right: 5px;
      }
  
      &-ta-name {
        font-size: 12px;
        font-weight: 400;
        color: #6a6878;
        max-width: 100px;
        overflow-x: hidden;
      }
  
      &-assigned {
        font-size: 10px;
        font-weight: 400;
        color: #6a6878;
      }
    }
  }
  