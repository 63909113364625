@import './../styles/variables';


.switch-btn-container {
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 20px;
    background: map-get($colors, primary-blue-dark);
    border: none;
    float: right;
    border-radius: 100px;
    position: relative;
  }
  .outline{
    background-color: #252633;
    width: 40px;
  }
  .outline::after{
    left: 0;
  }
  input:checked + .outline::after {
    left: 100% !important;
    transform: translateX(-100%);
  }

  .label-2 {
    background: #1f2130;
  }

  label:after {
    content: '';
    position: absolute;
    top: 2.5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  .disabled-switch:after {
    background: #f8f0e3 !important;
  }

  input:checked + label {
    @include theme() {
      background: map-get($colors, secondary-blue);
    }
  }

  .disabled-input + label {
    background: grey !important;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 35px;
  }
}
