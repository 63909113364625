.add-content-header {
  color: #fff;
  line-height: 1;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 3rem;

  &__title {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }

  &__btn {
    background-color: #246bfd;
    padding: 5px 15px;
    margin-bottom: 0.5rem;
  }

  &__eye {
    border: 1.5px solid #246bfd;
    line-height: 1;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 20px;
  }

  &__title-txt {
    margin-bottom: 0;
  }

  &__btn {
    color: #fff;
    background-color: #45485a;
    border-radius: 6px;
    padding: 10px 15px;
    margin-left: 5px;
    font-size: 12px;
    line-height: 1;
    display: flex;
    align-items: center;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .add-content-header {
    display: block;
  }

  .add-content-header__actions {
    margin-top: 2rem;
    margin-left: 1.5rem;
  }
  .add-content-header__btn {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 630px) {
  .create-content__save-btn {
    width: 100%;
    margin-bottom: 1rem;
  }
  .create-content__draft-btn {
    width: 100%;
    margin-bottom: 1rem;
  }
  .create-content__btns {
    display: block !important;
  }
  .create-content__cancel {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .add-content-header {
    display: block;

    &__title {
      margin-bottom: 1rem;
    }
  }
}
