.lesson-card {
  width: 197px;
  height: 216px;
  margin-right: 3%;
  margin-top: 25px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  position: relative;

  &__img {
    background-position: center;
    background-size: cover;
    object-fit: none;
    height: 139px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &__name {
    margin-top: 15px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 00;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 0px 10px;
  }

  &__overlay {
    width: 100%;
    height: 139px;
    border-radius: 8px 8px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    opacity: 0;

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__trash-icon {
    margin: 10px;
  }

  &__edit-icon {
    margin: 10px;
  }
}
