.unit-snav{
    

    &__header{
        display: flex;
        padding-left:40px;
        padding-top:10px;
        color:#fff;
        align-items: center;

        h6{
            padding-left:10px;
            margin: 0;
        }
    }

    &__items{
        margin-top: 20px;
    }

    &__item{
        color:#fff;
        text-align: left;
        cursor: pointer;
        padding-top:7px;
        padding-bottom:7px;
        padding-left:30px;
        margin: 0;
        

        &--active{
            background-color: #246bfd;
        }
    }
}