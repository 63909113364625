.tr-edit-page {
  .tr-dropdown-component {
    margin-top: 83px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .tf-answer-container {
    height: calc(100vh - 180px) !important;
    overflow: auto;
    padding-right: 20px;
    padding-bottom: 55px;
  }
}
.q-card-list {
  height: calc(100vh - 310px) !important;
  overflow: auto;
}
