@import './../styles/variables';


.mainlayout {
  @include theme() {
    background-color: theme-get('bg-color');
  }
  height: 100%;
  overflow: auto;
  .mainlayout__scrapbook-title {
    text-align: right;

    img {
      width: 340px;
    }
  }

  .mainlayout__left-section {
    padding-right: 35px !important;
  }

  .mainlayout__right-section {
    padding-left: 50px !important;
  }

  .mainlayout__scrabook-image {
    div {
      background-image: url('./../assets/computer-img.png');
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
      width: 320px;
      height: 300px;
      overflow: visible;
      margin-right: 13px;
      margin-left: auto;
      margin-top: 45px;
    }
  }
}

@media (max-width: 991.98px) {
  .mainlayout {
    .mainlayout__scrapbook-title {
      text-align: center;

      img {
        width: 300px;
      }
    }

    .mainlayout__left-section {
      padding-right: 0 !important;
      margin-top: 30px;
    }

    .mainlayout__right-section {
      padding-left: 0 !important;
      margin-top: 50px;
    }
  }
}

//mobile
@media (max-width: 575.98px) {
  .mainlayout {
    .mainlayout__scrapbook-title {
      margin-top: 35px;
      img {
        width: 180px;
      }
    }
    .mainlayout__left-section {
      padding-right: 0 !important;
    }

    .mainlayout__right-section {
      padding-left: 0 !important;
    }
  }
}
