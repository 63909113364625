.exam-header {
    .exam-header__exam-title {
      color: #fff;
      font-size: 24px;
      display: flex;
      align-items: center;
      overflow: hidden;
  
      span {
        margin-left: 14px;
        line-height: 1;
        font-size: 20px;
      }
    }
  
    .exam-header__progress-bar {
      .progress {
        height: 8px;
        background-color: #979797;
      }
    }
  
    .exam-header__completed-q-count {
      color: #fff;
      font-size: 14px;
    }
  
    .exam-header__total-q-count {
      color: #979797;
      font-size: 14px;
    }
  }
  
  .display-hamburger {
    @media (max-width: 991.98px) {
      display: block !important;
    }
  }
  