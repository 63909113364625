.student-content-header {
    padding-bottom:39px;
    background-color: #181924;
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    margin-bottom: 1.1rem;
    margin-top: 1rem;
  
    &__title {
      color: #979797;
      line-height: 1;
      margin: 0;
    }
  
    &__btn {
      color: #fff;
      background-color: #246bfd;
      display: flex;
      padding: 10px;
      border-radius: 8px;
      align-items: center;
      margin-left: auto;
    }
  }
  