.tabsAC {
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
  
    .tab {
      width: 200px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #1f2130;
      border-radius: 8px 8px 0 0;
  
      &_active {
        background-color: #246bfd;
      }
    }
  }
  