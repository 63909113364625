@import './../styles/variables';

.number-input {
  position: relative;
  height: 80px * $text-input-factor;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.number-input input {
  width: 100%;
  height: 100%;
  padding-top: 3px * $text-input-factor;
  padding-left: 0;
  border: none;
  outline: none;
  font-size: 26px * $text-input-factor;
  font-weight: 500;
  background: transparent;
  transition: none;
  @include theme() {
    color: theme-get('color-2');
  }
}

.number-input input[name='password'] {
  //This was used to align the asterisks with the bottom border og the input
  padding-top: 15px * $text-input-factor;
}

.number-input label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid map-get($colors, secondary-grey);
  // color: #73847f;
  font-size: 26px * $text-input-factor;
  @include theme() {
    color: map-get($colors, 'secondary-grey');
  }
}

.number-input-label-text {
  position: absolute;
  bottom: 5px * $auth-factor;
  left: 0;
  transition: all 0.5s ease;
  //   transauth-input: translateY(4.5px); //myprop
}

.number-input input:focus + .number-input-label .number-input-label-text,
.number-input input:valid + .number-input-label .number-input-label-text {
  transform: translateY(-120%);
  font-size: 20px * $auth-factor;

  // color: #73847f;
  @include theme() {
    color: map-get($colors, 'secondary-grey');
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.unit-type {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  margin-top: 12px;
  color: white;
}
