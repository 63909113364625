.set-option-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.option-card {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-right: 20px;
  margin-top: 4%;
  cursor: pointer;
  width: flex;
  height: flex;
  background: #1f2130;
  box-shadow: 0px 0px 30px #181924;
  border-radius: 10px;

  &__text {
    font-family: 'Roboto';
    padding: 15px 25px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.06px;
    color: #979797;

    &_delete {
      &:hover {
        border-radius: 0px 0px 10px 10px;
        color: #fff !important;
        background: #f05a5a;
      }
    }

    &_edit {
      &:hover {
        border-radius: 10px 10px 0px 0px;
        color: #fff !important;
        background: #246bfd;
      }
    }
  }

  &__line {
    border-bottom: 1px solid #272a39;
  }
}
