@import './../styles/variables';

.add-first-card {
  width: 16%;
  border-radius: 7px;

  margin-right: 4%;
  margin-bottom: 4%;
  cursor: pointer;
  background-color: map-get($colors, primary-blue-light);
  height: 230px;
  text-align: center;
  svg {
    margin-top: 40px;

    color: #6b719d;
    font-size: 50px;
  }
  div {
    line-height: 1.2;
  }
}

@media (max-width: 1149.98px) {
  .add-first-card {
    width: 30%;
    margin-right: 3.33%;
  }
}

@media (max-width: 899.98px) {
  .add-first-card {
    width: 45%;
    margin-right: 5%;
  }
}

@media (max-width: 575.98px) {
  .add-first-card {
    width: 100%;
    margin-right: 0;
  }
}
