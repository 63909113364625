.stu-taa-checkbox{

    width:15px;
    height:15px;
    border:1px solid #6A6878;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
   
    &--partial{

        background-color: #89F1FF;

        &--nopointer {
            cursor: no-drop;
        }

        &--disabled {

            background-color: #E5E5E5;
        }
    }
    &--full {
        background-color: #246BFD;

        &--nopointer {
            cursor: no-drop;
        }

        &--disabled {
            background-color: #6A6878;
            cursor: no-drop;
        }
    }

}