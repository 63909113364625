@import './../styles/variables';

.subject-section {
  width: auto;
  padding-left: 8px;
  height: 75px;
  color: map-get($colors, pure);
  background-color: map-get($colors, primary-blue-light);
  line-height: 1;
  border-radius: 12px;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 10px;
  font-weight: 400;
  * {
    transform: none;
  }
}
.undo-arrow {
  display: inline-block;
  background-color: #404462;
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 4px;
  color: #979797;
  margin: 6px;
  cursor: pointer;
}
.card-bin {
  margin: 6px;
  display: inline-block;
  background-color: #f05a5a;
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.action-container {
  width: 105px;
  text-align: right;
}
.type-container {
  width: 60px;
  margin-right: 10px;
  height: 60%;
  border-right: 1px solid #404462;
  span {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    display: block;
    text-align: center;
    font-weight: 400;
    background-color: #404462;
    height: 24px;
    width: 50px;
    padding-top: 6px;
    border-radius: 4px;
  }
}
.title-container {
  width: calc(100% - 105px - 70px);
  height: 60%;
}
.right-line {
  border-right: 1px solid #404462;
}
.paper-container {
  * {
    transform: none !important;
  }
}
