.reject-feedback-textarea {
  display: block;
  width: 100%;
  margin-top: 10px;
  background-color: transparent;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  height: 150px;
  border: 1px solid #979797;

  &:focus {
    outline: none;
  }
}

.reject-feedback__header {
  color: #979797;
}

.reject-feedback__footer {
  margin-top: 10px;
}

.confirm-btn {
  width: 150px;
  margin-right: 10px;
  background-color: #246bfd;
  border-radius: 5px;
  color: #fff;
}

.cancel-btn {
  width: 150px;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #246bfd;
}
