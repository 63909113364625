@import './../styles/variables';

.time-input {
  width: 100%;
  .time-input__text {
    color: map-get($colors, secondary-grey);
    font-size: 12px;
    line-height: 1.2;
  }

  .time-input__main {
    display: inline-block;
    text-align: center;

    & input:first-child {
      margin-right: 8px;
    }

    input {
      width: 35px;
      outline: none;
      border: none;
      border-bottom: 1px solid map-get($colors, secondary-grey);
      background-color: transparent;
      color: map-get($colors, pure);
      font-size: 26px * $text-input-factor;
      text-align: center;
      padding-bottom: 3px;
    }
  }
}
