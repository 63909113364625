@import './../styles/variables';

// .tab_active {
//   background-color: map-get($colors, secondary-blue);
// }
// .tab_deactivate {
//   background-color: map-get($colors, primary-blue-light);
// }

.tab-stu-extra-exam {
  margin-top: 40px;
  margin-bottom: 40px;
  height: calc(100vh - 120px);
  overflow-y: auto;

  .tab-stu-extra-exam__current-title,
  .tab-stu-extra-exam__upcoming-title,
  .tab-stu-extra-exam__completed-title {
    color: map-get($colors, secondary-grey);
    cursor: pointer;
    span {
      margin-right: 5px;
    }
    margin-bottom: 15px;
  }

  .tab-stu-extra-exam__upcoming-title,
  .tab-stu-extra-exam__completed-title {
    margin-top: 45px;
  }

  .tab-stu-extra-exam__current-cards,
  .tab-stu-extra-exam__upcoming-cards,
  .tab-stu-extra-exam__completed-cards {
    & > div {
      margin-bottom: 8px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 767.98px) {
  .tab-stu-extra-exam {
    height: calc(100vh - 190px) !important;
  }
}

.stdsearchwrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.stdsearchbar {
  width: 200px;
  margin-top: 2rem;
  margin-right: 1rem;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
  color: #fff;
}

.searchTerm {
  width: 100%;
  border: 1px solid #73847f;
  border-right: none;
  height: 35px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #fff;
  background-color: #1f2130;
  padding-left: .5rem;

  &:focus {
    color: #fff;

    &::placeholder {
      color: transparent;
    }
  }

  &::placeholder {
    color: #fff;
  }

}

.searchButton {
  width: 35px;
  height: 35px;
  border: 1px solid #246bfd;
  background: #246bfd;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.extracurrenttop {
  margin-top: 0px;
}

.margin-top-1rem {
  margin-top: 1rem;
}