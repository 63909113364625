.bAccessCodeUsage-container {
    display: flex;
    gap: 5px;
    flex-direction: row;
    flex-wrap: wrap;

    .code_users {
        width: calc(100% - 10px);
        height: 35px;
        background: #1f2130;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding-left: 15px;

        &__code {
            transition: none;
            width: 160px;
            color: #979797;
        }

        &__usedBy {
            flex: 1;
            color: #979797;
        }

        &__dateUsed {
            flex: 1;
            text-align: center;
            color: #979797;
        }

        &__active {
            flex: 2;
            text-align: center;
            color: #979797;
            justify-content: end;
            margin-right: 30px;
        }

        &__font {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
        }
    }
}

.bCode {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-flow: wrap;
    margin-top: 25px;
    column-gap: 10px;
    row-gap: 15px;
    height: calc(100vh - 400px) !important;
    overflow: auto;
    cursor: pointer;

    &__selected {
        align-items: center;
        height: 77px;
        background: #1f2130;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 2px;
        padding: 20px;

        &-code {
            font-size: 14px;
            width: max-content;
        }

        &-expire {
            font-size: 12px;
            color: #979797;
        }

        &-status {
            flex-direction: row;
            justify-content: end;
        }

        &-availability {
            font-size: 12px;
            text-align: end;
            color: #979797;

        }

        &-amount {
            font-size: 13px;
            text-align: end;
        }

        &-downicon {
            text-align: center;
            padding-left: 5px;
        }
    }
}

.s-box {
    padding-left: 25px;
    margin: auto;
}

.bCodeUsage-tbl-titles {
    color: #979797;
    margin-top: 45px;
    padding-left: 15px;
    margin-bottom: 10px;
    font-size: 14px;

    div:nth-child(1) {
        width: 160px;
    }

    div:nth-child(2) {
        flex: 1;
    }

    div:nth-child(3) {
        flex: 1;
        text-align: center;
    }

    div:nth-child(4) {
        text-align: center;
        flex: 2;
        margin-right: 30px;
    }
}

.CodeUsage-user-list {
    height: calc(100vh - 360px) !important;
    overflow: auto;
}