.content-topic-list-item {
    color: #fff;
    display: flex;
    padding: 14px 12px;
    background-color: #1F2130;
    border-radius: 11px;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    padding-right: 1.5rem;



    &__title {
        display: flex;
    }

    &__icon {
        // margin-right: 10px;
        transform: rotate(45deg) translateY(3px);
        color:#979797;
        height: 14px;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    &__actions {
        display: flex;
        margin-left: auto;
        width: 80px;
    }
}