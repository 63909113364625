.taa-nav {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  &__tabs {
    display: flex;
    font-size: 12px;
  }

  &__assignment {
    padding: 7px 40px;
    color: #fff;
    cursor: pointer;
    border: 1px solid #3c4058;
    filter: drop-shadow(0px 0px 6.6667px #181924);
    border-radius: 6px 0 0 6px;
    width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--active {
      border: 1px solid #246bfd;
      color: #246bfd;
    }
  }

  &__payment {
    padding: 7px 40px;
    color: #fff;
    cursor: pointer;
    border: 1px solid #3c4058;
    filter: drop-shadow(0px 0px 6.6667px #181924);
    border-radius: 0 6px 6px 0;
    width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--active {
      border: 1px solid #246bfd;
      color: #246bfd;
    }
  }
}

.taa-keys {
  display: flex;
  font-size: 10px;
  font-weight: 400;
  color: #6a6878;
  align-items: center;

  &-one {
    display: flex;
    align-items: center;

    div {
      width: 10px;
      height: 10px;
      background-color: #246bfd;
      border-radius: 2px;
      margin-right: 3px;
    }
  }

  &-two {
    display: flex;
    align-items: center;
    margin-left: 10px;

    div {
      width: 10px;
      height: 10px;
      background-color: #89f1ff;
      border-radius: 2px;
      margin-right: 3px;
    }
  }

  &-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    button {
      border-radius: 4px;
      background-color: #246bfd;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      padding: 3px 40px;
    }
  }

  &-btn--disabled {
    background-color: grey !important;
    cursor: default !important;
  }
}

.taa-payment {
  &__cards {
    height: calc(100vh - 368px);
    overflow: auto;
  }

  &__tas {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &-stu-name {
      min-width: 150px;
      color: #979797;
      font-size: 14px;
      text-align: center;
    }

    &-checkboxs {
      display: flex;
    }

    &-checkbox-item {
      display: flex;
      min-width: 120px;
      margin: 0 10px;
    }

    &-name-container {
      margin-left: 5px;
      margin-right: 5px;
      text-align: center;
    }

    &-ta-name {
      font-size: 12px;
      font-weight: 400;
      color: #6a6878;
      overflow-x: hidden;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    &-assigned {
      font-size: 10px;
      font-weight: 400;
      color: #6a6878;
    }
  }
}

.taa-payment-table {
  margin-top: 10px;
  padding: 10px 20px;
  width: 100%;

  tr {
    border-bottom: 1px solid #3c4058;
    padding-bottom: 10px;
    margin-bottom: 10px;

    td {
      padding: 10px 10px;

      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
      }
    }

  }

}
