.bcub{
    display: flex;
    align-items: center;
    cursor: pointer;
   

    &-item{

        max-width:200px;
        transition: none;

        &--not-current{
            color: rgb(115, 132, 127);
            font-size: 15px;
        }
    }
}