@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import './variables';
@import '~video-react/styles/scss/video-react';
html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
}

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

a,
a:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

button {
  outline: none;
  border: none;
}

.btn-inactive {
  background-color: grey !important;
}

.btn-inactive-text {
  color: grey !important;
}

.active {
  background-color: map-get($colors, secondary-blue);
}

/* width */
::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #404462;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #686d93;
  border-radius: 3px;
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #484c65;
//   border-radius: 3px;
// }

.textinput-margin {
  margin-top: 50px * $auth-factor;
}

.textinput-margin-signup {
  margin-top: 13.5px * $auth-factor;
}

.account-margin-dob {
  margin-top: 10px;
}

.account-margin-school {
  margin-top: 18px;
}

.reset-password-margin {
  margin-top: 40px * $auth-factor;
}

.textarea-account-nav {
  margin-top: 14px;
}

.textarea-account-margin {
  margin-top: 14px;
}

.eye {
  margin-right: 10px * $auth-factor;
  cursor: pointer;
}

.tox-tinymce {
  border-radius: 8px !important;
}

.complexity-label {
  color: map-get($colors, secondary-grey);
  font-size: 12px;
}

.modal-content {
  border: none;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0 !important;
  transform: translateY(-100px) !important;
  transition: all 300ms ease-in-out !important;
}

.video-react-video {
  width: 100% !important;
}

// .video-react{
//   padding-top:0 !important
// }

// iframe {
//   display: none;
// }

// .auth-input-margin {
//   margin-top: 50px * $auth-factor;
// }

// .auth-input-margin-signup {
//   margin-top: 13.5px * $auth-factor;
// }

// .account-margin {
//   margin-top: 12px;
// }

// .reset-password-margin {
//   margin-top: 40px * $auth-factor;
// }

// .textarea-account-nav {
//   margin-top: 14px;
// }

// .textarea-account-margin {
//   margin-top: 14px;
// }
