.list-layout {
  margin: auto;
  height: calc(100vh - 121px);

  .list-layout-div {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
  }
  // justify-content: space-between;
}

.list-layout--full-width {
  margin: auto;
  margin-top: 50px;
  height: calc(100vh - 260px);

  .list-layout-div {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
  }
}

@media (max-width: 767.98px) {
  .list-layout {
    padding-right: 3%;
  }
}

@media (max-width: 575.98px) {
  .list-layout {
    padding: 0 30px;
    height: auto;

    .list-layout-div {
      height: auto;
      overflow-y: visible;
    }
  }

  .list-layout--full-width {
    height: auto;
    padding-right: 0;
    margin-top: 25px;

    .list-layout-div {
      height: auto;
      overflow-y: visible;
    }
  }
}
