.stu-exam-stats-item {
  background: #1f2130;
  box-shadow: 0px 0px 8.16837px #181924;
  border-radius: 10px;
  width: 49%;
  border-radius: 5px;
  height: 54px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;

  &__number {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: #ffffff;
  }
  &__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #8b8b8b;
  }
}
