@import './../styles/variables';

.header {
  position: fixed;
  background: #181924;
  width: calc(100vw - 405px);
  height: 75px;
  z-index: 10;
  top: 0px;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: none;

  div {
    color: map-get($colors, pure);
  }

  button {
    background-color: map-get($colors, secondary-blue);
    color: map-get($colors, pure);
    border-radius: 6px;
    padding: 6px 7.5px 7px;
    border: none;
    color: #fff;
    width: 180px;
    font-size: 16px;
    line-height: 1 !important;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .disabled {
    background-color: map-get($colors, grey);
  }
}
