.add-content-item-shell {

    color: #73847F;
    display: flex;
    align-items: center;
    background-color: #1F2130;
    border-radius: 15px;

    padding: 20px;

    &__icon {
        transform: rotate(45deg);
    }

    &__move {
        width: 50px;
    }


    &__main {
        width: 100%;
    }

    &__top {
        display: flex;
        justify-content: space-between;
    }

    &__children {}

    &__remove {
        width: 50px;
    }

}