.settings {
  height: calc(100vh - 80px);
  overflow-y: hidden;

  &__top-tabs {
    display: flex;
    margin-top: 50px;
  }

  &__roaster-tab,
  &__assign-tutor-tab,
  &__assign-label-tab {
    padding: 5px 0;
    background-color: #1f2130;
    border-radius: 10px 10px 0 0;
    width: 200px;
    text-align: center;
    color: white;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  &__user-tabs {
    display: flex;
    margin-top: 33px;
  }

  &__student-tab,
  &__instructor-tab {
    padding: 5px 0;
    background-color: #1f2130;
    border-radius: 6px 0px 0px 6px;
    border: 1px solid #3c4058;
    filter: drop-shadow(0px 0px 6.6667px #181924);
    width: 166.67px;
    height: 30px;
    text-align: center;
    color: white;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 13.3334px;
    line-height: 16px;

    &__end-tab {
      border-radius: 0px 6px 6px 0px;
    }

    &__tab-active {
      border: 1px solid #246bfd;
      color: #246bfd;
    }
  }

  &__body {
    height: calc(100% - 120px);
  }

  &__roaster-body__content__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    padding-top: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #979797;

    &__username {
      flex: 1.5 !important;
    }

    &__role,
    &__packages,
    &__accessType {
      flex: 1 !important;
    }
    &__status {
      flex: 1.5 !important;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__roaster-body__content__users {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: 35px;
    background: #1f2130;
    box-shadow: 0px 0px 8.89855px #181924;
    border-radius: 4px;
    padding: 0 20px;
    margin-bottom: 5px;

    &__name {
      display: flex;
      flex: 1.5 !important;
      align-items: center;
      justify-content: flex-start;
      height: 18px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15.5725px;
      line-height: 18px;
      color: #ffffff;
    }

    &__role,
    &__packages,
    &__accessType {
      display: flex;
      flex: 1 !important;
      justify-content: flex-start;
      align-items: center;
      height: 16px;
      left: 743px;
      top: 273.79px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #979797;
    }

    &__status {
      display: flex;
      flex: 1.5 !important;
      justify-content: flex-end;
      align-items: center;
      height: 16px;
      left: 743px;
      top: 273.79px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #979797;
    }
  }
}

.assign-subjects {
  margin-top: 60px;
  width: 100%;
  height: 100%;

  &__instructor {
    width: 50%;
    padding-left: 10px;
  }

  &__encap {
    width: 90%;
    height: 90%;
  }

  &__admin {
    width: 50%;
    padding-left: 10px;
  }

  &__header {
    margin-bottom: 25px;
  }

  &__link {
    color: #246bfd;
    cursor: pointer;
  }
}

.assign-subjects__encap {
  overflow: auto;
  padding-right: 5px;
}

.label-section {
  background-color: transparent;
  border-radius: 10px;
  height: 100%;
  margin-top: 50px;

  &__list {
    list-style: none;
    padding-left: 10px;
    height: calc(100% - 36px);
    overflow: auto;
    margin-top: 20px;
  }

  &__list-item {
    padding: 5px 10px;
    background-color: #1f2130;
    border-radius: 6px;
    max-width: 95%;
    color: white;
    margin-bottom: 10px;
    display: flex;
  }

  &__name {
    width: 80%;
    overflow: hidden;
  }

  &__actions {
    margin-left: auto;
    display: flex;
  }

  &__add-btn-wrapper {
    text-align: right;
    max-width: 95%;
  }

  &__add-btn {
    border-radius: 50%;
    background-color: #246bfd;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.settings-tab-active {
  background-color: #246bfd !important;
}

.settings-roster-body-remove-icon {
  &:hover {
    color: #f05a5a;
  }
}
