.link_text {
  color: #246BFD;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  font-family: Roboto, sans-serif;
  cursor: pointer;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}