.ta-exams{
    display:flex;
    margin-top:25px;
  
    &__notmarked-rejected{
  
      width:50%;
  
      &-title{
        color:#979797;
        margin-bottom:10px;
      }
  
    }
  
  
    &__approved{
      width:50%;
  
      &-title{
  
        color:#979797;
        margin-bottom:10px;
      }
    }
  }