@import './../styles/variables';

.layout {
  min-height: 100vh;
  overflow: auto;
  background-color: map-get($colors, primary-blue-dark);

  & > div {
    // max-width: 1366px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .side {
    margin-top: 20px;
    width: 350px;
    //hide scroll
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
   //hide scroll 
  .side::-webkit-scrollbar{
    display: none;
  }
    
  .side--no-sidenav {
    margin-top: 20px;
    width: 110px;
  }

  .secondary-nav {
    width: 240px;
    text-align: center;
    background: #1f2130;
    padding-top: 20px;
    border-radius: 15px;
    height: calc(100vh - 55px);
    overflow: auto;
    position: fixed;
    margin-left: 110px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }
  }

  .main {
    overflow-y: scroll;
    flex: 1;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 30px;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .layout-content-main{
    flex: 1;
    height: 100vh;
    padding-top: 30px;
    // margin-left: 10px;
    margin-left:1.5rem;
    margin-right:1.5rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .main::-webkit-scrollbar {
    display: none;
  }

  .main--no-sidenav {
    padding-left: 45px;
  }

  .right {
    width: 225px;
    margin-left: auto;

    & > div {
      height: 100vh;
      background-color: #1f2130;
      border-radius: 15px 0px 0px 15px;
      padding: 45px 18px 0;
      margin-left: auto;
      position: fixed;
    }
  }
}

@media (max-width: 767.98px) {
  .layout {
    .side {
      width: 0;
    }

    .main {
      width: 100%;
      height: 100%;
      max-height: none;
    }
  }
}

@media (max-width: 767.98px) {
  .layout {
    .side--no-sidenav {
      margin-top: 20px;
      width: 0;
    }

    .main--no-sidenav {
      width: 100%;
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

//common
.layout {
  min-height: 100vh;
  overflow: auto;
  background-color: map-get($colors, primary-blue-dark);

  & > div {
    // max-width: 1366px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }
}

//SCORE_SCREEN - start

.layout {
  .score-screen__side {
    margin-top: 20px;
    width: 110px;
  }

  .score-screen__main {
    overflow-y: auto;
    flex: 1;
    padding-left: 45px;
    padding-right: 35px;
    padding-top: 30px;
    height: 100vh;
  }
}

//SCORE_SCREEN - end

//ANSWER_SCREEN - start

.layout {
  .answer-screen__side {
    margin-top: 20px;
    width: 110px;
  }

  .answer-screen__main {
    overflow-y: auto;
    flex: 1;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 30px;
    height: 100vh;
  }

  .answer-screen__right {
    width: 300px;
    margin-left: auto;

    & > div {
      width: 300px;
      height: 100vh;
      background-color: #1f2130;
      border-radius: 15px 0px 0px 15px;
      padding: 45px 18px 0;
      margin-left: auto;
      position: fixed;
    }
  }

  .mobile-view-q-navigator {
    height: auto;
    background-color: #1f2130;
    border-radius: 15px 15px 0 0;
    padding: 45px 18px 0;
    margin-top: 60px;
    width: 100%;
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .answer-screen__main {
      height: auto;
    }
  }
}

//ANSWER_SCREEN - end

//DARSHBOARD_SCREEN - start

.layout {
  .dash-screen__side {
    margin-top: 20px;
    width: 110px;
  }

  .dash-screen__main {
    overflow-y: auto;
    flex: 1;
    padding-left: 20px;
    padding-right: 35px;
    padding-top: 30px;
    height: calc(100vh - 20px);
  }
}

//DARSHBOARD_SCREEN - end

//EXAM_DETAILS_SCREEN - start

.layout {
  .exam-details-screen__side {
    margin-top: 20px;
    width: 110px;
  }

  .exam-details-screen__main {
    overflow-y: auto;
    padding-left: 45px;
    padding-right: 35px;
    padding-top: 30px;
    height: 100vh;
    width: calc(100vw - 350px);
    margin-left: auto;
  }
}

@media (max-width: 767.98px) {
  .layout {
    .exam-details-screen__main {
      width: 100%;
    }
  }
}

//EXAM_DETAILS_SCREEN - end

//EXAM_INFO_SCREEN - start

.layout {
  .exam-info-screen__side {
    margin-top: 20px;
    width: 110px;
  }

  .exam-info-screen__main {
    overflow-y: auto;
    padding-left: 45px;
    padding-right: 35px;
    padding-top: 30px;
    height: 100vh;
    width: calc(100vw - 110px);
    margin-left: auto;
  }
}

@media (max-width: 767.98px) {
  .layout {
    .exam-info-screen__main {
      width: 100%;
    }
  }
}

//EXAM_INFO_SCREEN - end
