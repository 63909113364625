@import './../styles/variables';

.user-roster-packages-modal {
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  position: absolute;
  border-radius: 15px;
  overflow: auto;
  outline: none;
  padding: 30px 35px 40px 35px;
  background-color: map-get($colors, primary-blue-light);
  top: 10vh;
  transition: 0.3s;
  width: 45%;
  height: auto;
  color: map-get($colors, pure);
  font-family: 'Roboto';
  font-style: normal;

  .packages-roster-header {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
  }

  .packages-roster-modal-user-type {
    display: flex;
    flex-direction: column;
  }

  .packages-roster-modal-close {
    display: flex;
    align-self: center;
  }

  .packages-roster-modal-sub-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #979797;

    &__packages-main-text {
      margin-top: 7%;
      margin-bottom: 2.5%;
    }
  }
}
