:root {
    --square-size: 15px;
    --square-gap: 5px;
    --week-width: calc(var(--square-size) + var(--square-gap));
    --completed:#20D167;
    --incomplete:#404040;
  }

.stu-subject-home{

    &_title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        color:#fff;
    }

    &_progress{
        &-title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color:#fff;
        }
        &-text{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #979797;
        }
    }

    &_Card{

        &-context{
            margin-top: .5rem;
            padding:0;
        }

        &-image{
            width: 88px; 
            height: 80px;
            border-radius: 15px 0px 0px 8px;
        }

        &-title{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            color:#fff;
        }

        &-text{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: .8rem;
            line-height: 14px;
            color: #979797;
        }

        &-btn{
            // position: absolute;
            // right:5px;
            width: 150px;
            height: 35px;
            margin-top:1rem;

        }
    }    

    &_exam-card{
        background: #1F2130;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 12px;
        width: auto;
        height: 8vh !important;
        color:#fff;
        margin-right:5% !important;


        &_start-date{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #979797;

            &>span{
                color:#fff
            }
        }

        &_end-date{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #979797;
            &>span{
                color:#fff
            }
        }

        &_mobile-date{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #fff;
            display: none;
        }

        &_mobile-time{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 10.3779px;
            line-height: 12px;
            letter-spacing: 0.05em;
            color: #979797;
            display: none;
        }

        &_icon{
            display:none;
            padding-left:2rem;
        }
    }

    &-restrict{
        margin:auto;
        min-width: 1000px;
        border:2px dashed #1f2130;
        border-radius:11px;
        

        &_title{
            font-size: 25px;
            text-align:center;
            margin-bottom:1.5rem;
            margin-top:1.5rem;
        }
        &_text{
            text-align: center;
            width:480px;
            margin:auto;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #A4A4A4;
            
        }
        &_btn{
            display: block;
            background: #246BFD;
            border-radius: 8px;
            color: #FFF;
            width: 200px;
            height: 40px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            margin:0 auto;
            margin-top:1rem;
            margin-bottom:3rem;
        }
    }
}


@media only screen and (max-width: 1500px) {
    .stu-subject-home_Card{
    
        &-context{
            padding-left: 2rem !important;
            margin-top:0;
        }
    }

    .stu-subject-home-restrict{
        min-width: 800px;
    }
}



@media only screen and (max-width: 1200px) and (min-width: 768px) {
 .initialview-box{
    min-width: 0;
 }
}



@media only screen and (max-width: 767.98px) {
    .stu-subject-home_Card{
    
        &-image{
            width: 96px;
            height: auto;
            object-fit: cover;
         }
        &-context{
            padding-left: 1rem !important;
            margin-top:0;
            
        }
        &-btn{
            
            position: relative;
        }

    }

    .stu-subject-home-restrict{
        min-width: 0;
    }

    .stu-subject-home_exam-card{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        margin-right:0 !important;

        &_start-date{
            display:none;
        }

        &_end-date{
            display:none;
        }

        &_mobile-time{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 7.78345px;
            line-height: 9px;           
            display:block;
        }
        &_mobile-date{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 10.3779px;
            line-height: 12px;
            letter-spacing: 0.05em;
            display:block;
        }

        &_icon{
            display:inline-block;
            position: absolute;
            left:25%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .stu-subject-home_Card{
    
        &-image{
            width: 96px;
            height: auto;
            object-fit: cover;
         }

         &-btn{
margin-bottom: 10px;
        }

        &-context{
            padding-left: 3rem !important;
            margin-top:0;
        }
    }
}





.progress{

    &-container{
        display:flex;
        flex-direction: row;
        gap:6rem;
        padding-left:3rem;
        margin-top:.5rem;
        height: 60px;
        overflow: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }


    &-circle { 
        position: relative;
        background: var(--completed);
        border-radius: 50%;
        width: 20px;
        height: 20px;
    }
  
    &-checkmark {
        position: absolute;
        transform: rotate(45deg) translate(-50%, -50%);
        left: 30%;
        top: 50%;
        height: 10px;
        width: 5px;
        border-bottom: 2px solid #212529;;
        border-right: 2px solid #212529;;
    }

    &-content{
        position:relative;
        width:80px;
        height:50px;
       

        &:not(:last-child):before{
            content: '';
            display: block;
            position: absolute;
            left: 32%;
            top: 8px;
            background-color: #979797;
            height: 1px;
            width: 180%;
          }
    }



    &-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color:#979797;
        margin-top:1rem;
        margin-left:-1rem;
        width:100px;
    }
}

.incomplete{
    background-color:var(--incomplete);
}



.months { grid-area: months; }
.squares { grid-area: squares; }

.graph {
    position: relative;
  display: inline-grid;
  grid-template-areas: "empty months"
                       "empty squares";
  grid-template-columns: auto 1fr;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar{
      display: none;
  }
}

.months {
  display: grid;
  grid-template-columns: calc(var(--week-width) * 4) /* Jan */
                         calc(var(--week-width) * 4) /* Feb */
                         calc(var(--week-width) * 4) /* Mar */
                         calc(var(--week-width) * 5) /* Apr */
                         calc(var(--week-width) * 4) /* May */
                         calc(var(--week-width) * 4) /* Jun */
                         calc(var(--week-width) * 5) /* Jul */
                         calc(var(--week-width) * 4) /* Aug */
                         calc(var(--week-width) * 4) /* Sep */
                         calc(var(--week-width) * 5) /* Oct */
                         calc(var(--week-width) * 4) /* Nov */
                         calc(var(--week-width) * 5) /* Dec */;
  color: #979797;
  list-style: none;
}

.squares {
  display: grid;
  grid-gap: var(--square-gap);
  grid-template-rows: repeat(7, var(--square-size));
}

.squares {
  grid-auto-flow: column;
  grid-auto-columns: var(--square-size);
  margin-left:-1rem;
}



.graph {
  padding: 10px;
  padding-bottom: 20px;
  border: none;
  background-color: #1F2130;
  box-shadow: 16px 16px 24px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin:0;
}



.squares li {
  background-color: #31354D;;
  list-style-type: none;
}

.squares li[data-level="1"] {
  background-color: #6194FF;;
}

.squares li[data-level="2"] {
  background-color: #246BFD;;
}

.squares li[data-level="3"] {
  background-color: #11347D;;
}

.graph-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    color:#979797;
    bottom:-2%;
    width: 10rem;

} 