@import './../styles/variables';

.grading-papers-container {
  top: 50px;
  height: auto;
  width: 320px;
  margin-right: -10px;
  background: #1f2130;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  padding-top: 1px;
  overflow-y: auto;
  max-height: calc(100vh - 500px);

  .paper-content {
    cursor: pointer;
    margin: 15px 0;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .paper-type-tag {
      width: 54px;
      height: 25px;
      background: #404462;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      .paper-type-name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #cdcdcd;
      }
    }

    .grading-paper-name {
      width: 190px;
      height: 28px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      display: flex;
      cursor: pointer;
      align-items: center;
    }
  }
}
.grading-papers-disable-container {
  display: none;
  p {
    display: none;
  }
}

.grading-papers-disable-container,
.grading-papers-container {
  hr {
    color: #cdcdcd;
    background-color: #cdcdcd;
    margin: 0;
  }
}

.modal-grading {
  inset: 50% auto auto 50%;

  transform: translate(-50%, 0);
  position: absolute;
  border-radius: 15px;
  overflow: hidden;
  outline: none;
  margin-left: 50px;
  padding-right: 10px !important;
  max-width: 2200px;
  background-color: map-get($colors, primary-blue-light);
  top: 2vh;
  transition: 0.3s;
  height: 97vh;
  width: calc(100vw - 6%);
}

.grading-and-review-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: 0.3s;
}



.roaster-slider {
  width: 350px;
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 15px 0px 0px 15px;

  .roaster-slider-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    // height: 100%;
    overflow: hidden;

    .roaster-slider-header-title {
      width: 200px;
      text-align: left;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      margin-top: 50px;
    }

    .roaster-slider-header-close {
      width: 20px;
      height: 20px;
      right: 0px;
      //   top: 50px;
      margin-top: 47px;
      cursor: pointer;
    }
  }

  .roaster-slider-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 60px;

    .roaster-slider-body-item-1 {
      display: flex;
      flex-direction: column;

      .total-student-count {
        width: 63px;
        height: 42px;
        left: 1124px;
        top: 121px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 42px;
        text-align: center;

        color: #20d167;
      }
      .relavant-name {
        margin-top: 10px;
        width: 56px;
        height: 32px;
        left: 1124px;
        top: 163px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #20d167;
      }
    }
    .roaster-slider-body-item-2 {
      display: flex;
      flex-direction: column;

      .total-student-count {
        width: 63px;
        height: 42px;
        left: 1124px;
        top: 121px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #f05a5a;
      }
      .relavant-name {
        margin-top: 10px;
        width: 56px;
        height: 32px;
        left: 1124px;
        top: 163px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #f05a5a;
      }
    }
    .roaster-slider-body-item-3 {
      display: flex;
      flex-direction: column;

      .total-student-count {
        width: 63px;
        height: 42px;
        left: 1124px;
        top: 121px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 42px;
        text-align: center;

        color: #f3b619;
      }
      .relavant-name {
        margin-top: 10px;
        width: 56px;
        height: 32px;
        left: 1124px;
        top: 163px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #f3b619;
      }
    }

    .roaster-slider-body-item-4 {
      display: flex;
      flex-direction: column;

      .total-student-count {
        width: 63px;
        height: 42px;
        left: 1124px;
        top: 121px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 42px;
        text-align: center;

        color: #8b8b8b;
      }
      .relavant-name {
        margin-top: 10px;
        width: 56px;
        height: 32px;
        left: 1124px;
        top: 163px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #8b8b8b;
      }
    }

    .roaster-slider-body-item-5 {
      display: flex;
      flex-direction: column;

      .total-student-count {
        width: 63px;
        height: 42px;
        left: 1124px;
        top: 121px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 42px;
        text-align: center;

        color: #8b8b8b;
      }
      .relavant-name {
        margin-top: 10px;
        width: 56px;
        height: 32px;
        left: 1124px;
        top: 163px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;

        color: #8b8b8b;
      }
    }
  }
  .publish-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    button {
      background: #246bfd;
      border-radius: 8px;
      color: white;
      width: 276px;
      height: 35px;
    }

    .visibility-container {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 10px;
      color: #73847f;
    }
  }

  .submission-toggle-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    .submission-toggle-switch-item-left {
      .left-button {
        width: 141px;
        height: 44px;
        background: #246bfd;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 8px 8px 0px 0px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;
      }
    }
    .submission-toggle-switch-item-right {
      .right-button {
        width: 152px;
        height: 44px;
        background: #181924;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 8px 8px 0px 0px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;

        text-align: center;

        color: #ffffff;
      }
    }
  }

  .student-submission-content {
    margin-top: 36px;
    .content-header {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .left-topic-student {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #73847f;
      }
      .right-topic-marks {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #73847f;
        margin-right: -25px;
      }
    }

    .marks-details {
      margin: 10px 30px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 506px);
      background: #1f2130;
      box-shadow: 0px 0px 8px #181924;
      border-radius: 8px;

      .row-details {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        // justify-content: space-between;
        min-height: 30px;
        background: #1f2130;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 8px;
        padding: 0px 10px;

        .student-name {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          display: block !important;
          margin-top: 5px;
          margin-bottom: 5px;
          padding-right: 10px;
          max-width: 130px;
          min-width: 130px;
          overflow: hidden;
        }
        .student-marks {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          width: 40%;
          padding-left: 14px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
      }
    }
  }
  .active-btn-submissions {
    width: 141px;
    height: 44px;
    background: #246bfd;
    box-shadow: 0px 0px 8px #181924;
    border-radius: 8px 8px 0px 0px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
  }
  .inactive-btn-submissions {
    width: 152px;
    height: 44px;
    background: #181924;
    box-shadow: 0px 0px 8px #181924;
    border-radius: 8px 8px 0px 0px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    text-align: center;

    color: #ffffff;
  }
  .student-not-submitted-content {
    margin-top: 36px;
    .content-header {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .left-topic-student {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #73847f;
      }
      .right-topic-marks {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #73847f;
      }
    }

    .marks-details {
      margin: 10px 30px;
      overflow-y: auto;
      max-height: calc(100vh - 600px);
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar{
        display: none;
      }
      
      .row-details {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        background: #1f2130;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 8px;
        .student-name {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-left: 20px;
          color: #ffffff;
          word-break: break-all;
          margin-top: 5px;
          margin-bottom: 5px;
          max-width: 250px;
        }
        .student-marks {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-right: 20px;
          color: #ffffff;
        }
      }
    }
  }
}

.nav-menu-test {
  width: 350px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu-test.active {
  background-color: transparent;
  z-index: 1000;
  right: 0;
  transition: 350ms;
}
