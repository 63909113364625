@import './../styles/variables';

.layout-btn {
  background-color: map-get($colors, secondary-blue);
  color: map-get($colors, pure);
  border-radius: 6px;
  padding: 6px 7.5px 7px;
  line-height: 1 !important;
  border: none;
  width: 180px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  margin-bottom: 20px;

  &--mobile {
    width: 100%;
  }
}
