.TA-payments-tab {
  margin-top: 30px;

  &__header {
    width: auto;
    height: auto;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    &-row-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 54px;
      background: #1f2130;
      box-shadow: 0px 0px 8.16837px #181924;
      border-radius: 6px;

      &-number {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #ffffff;
      }

      &-content {
        margin-top: 2px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #8b8b8b;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    gap: calc(100% / 16);

    &-column {
      display: flex;
      flex-direction: column;
      flex: 2;
      margin-bottom: 30px;
      max-height: calc(100vh - 200px) !important;

      &-header {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #979797;
        margin-bottom: 7px;
      }

      &-item {
        margin-top: 10px;
        width: 100%;
        min-height: 74.5px;
        background: #1f2130;
        box-shadow: 0px 0px 8px #181924;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &--active {
          background: #2f313f;
          margin-top: 10px;
          width: 100%;
          min-height: 74.5px;
          box-shadow: 0px 0px 8px #181924;
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
        }
      }

      &-item-type {
        width: 54px;
        height: 25px;
        background: #404462;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30px;
      }

      &-item-type-card {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #cdcdcd;

        &-vertical {
          margin-left: 30px;
          width: 0px;
          height: 47.5px;
          border: 1px solid #404462;
        }
      }

      &-item-paperName {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #ffffff;
        margin: 0px 20px;
      }
    }

    &-column2 {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-height: calc(100vh - 200px) !important;

      &-header {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #979797;
        margin-bottom: 17px;
        display: flex;
        justify-content: space-between;
      }

      &-body {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        gap: 10px;
        margin-top: 30px;

        &-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 10px;

          &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 130px;
            background: #1f2130;
            box-shadow: 0px 0px 9.14258px #181924;
            border-radius: 10px;
            margin-right: 20px;
            gap: 5px;

            &-number {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 500;
              font-size: 32px;
              line-height: 38px;
              color: #ffffff;
              text-align: center;
            }

            &-content {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: #8b8b8b;
              text-align: center;
              margin: 0px 10px;
            }
          }
        }
      }
    }
  }
}
