@import './../styles/variables';

.email-verification {
  height: 424px;
  width: 700px;
  left: auto;
  font-weight: bold;
  color: white;
  font-size: 23px;
  text-align: center;
  padding-top: 110px;

  .verfiy-email-txt {
    color: map-get($colors, secondary-blue);
  }

  .resend__btn {
    margin: auto;
    margin-top: 65px * $auth-factor;
    border-radius: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get($colors, secondary-blue);
    padding: (12px * $auth-factor) 0;
    font-size: 20px * $auth-factor;
    color: map-get($colors, pure);
    line-height: 1.5;
    transition: none;
  }

  .resend__btn-disable {
    margin-top: 35px * $auth-factor;
    background-color: map-get($colors, secondary-grey);
    cursor: default;
  }

  .btn__txt {
    color: white;
  }
  .email-verification__description {
    width: 456px;
    height: 28px;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    margin: auto;
    margin-top: 20px;
    font-weight: 400;

    // span {
    //   color: map-get($colors, secondary-blue);
    // }

    .email-verification__form {
      margin-top: 40px;
      padding-bottom: 20px;
      .input-wrapper {
        width: 65%;
        margin: auto;
      }
    }
  }
}

@media (max-width: 1399.98px) {
  .email-verification {
    width: 405px;
    .email-verification__description {
      width: 405px;

      .email-verification__form {
        .input-wrapper {
          width: 80%;
          margin: auto;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .email-verification {
    // margin-top: 100px; //here
    padding-top: 0px;
    width: 300px;
    .email-verification__description {
      width: 300px;
      margin-top: 100px; //here
    }
  }
}

//mobile
@media (max-width: 575.98px) {
  .email-verification {
    width: 280px;

    .email-verification__description {
      width: 280px;
      // margin-top: 290px;
    }
  }
}

@media (max-width: 419.98px) {
  .email-verification {
    width: 200px;
    .email-verification__description {
      width: 200px;
      // margin-top: 250px;
    }
  }
}

.time-wrapper {
  position: relative;
  width: 80px;
  height: 60px;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.time-wrapper {
  .time {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($colors, pure);
  }
}

.email-resend-counter {
  align-items: center;
  justify-content: center;
  display: flex;
  color: map-get($colors, pure);
}
