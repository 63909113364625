.mcq-qtn-paper-card {
  background-color: #1f2130;
  border-radius: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px !important;
  padding-right: 10px !important;
  cursor: pointer;
  margin-bottom: 10px;
  color: #fff;

  .mcq-qtn-paper-card__main {
    height: 45px;
    display: flex;
    align-items: center;
  }

  .mcq-qtn-paper-card__complexity {
    padding-right: 15px;
    border-right: 1px solid #404462;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .mcq-qtn-paper-card__text {
    padding-left: 15px;
    font-size: 15px;
    flex: 1;
    overflow: hidden;
    height: 100%;
  }
}
