@import './../styles/variables';

.user-card {
  height: 50px;
  border-radius: 8px;
  background-color: map-get($colors, primary-blue-light);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  margin-right: 10px;

  div {
    transition: none;
  }

  div {
    color: map-get($colors, pure);
  }

  .user-card__firstname,
  .user-card__lastname {
    font-size: 15px;
    color: map-get($colors, pure);
    font-weight: 400;
    line-height: 1;
    width: 34%;
    overflow: hidden;
  }

  .user-card__margin {
    width: 2%;
  }

  .user-card__instructor {
    width: 29%;
    padding-left: 24px;
  }

  .user-card__registered-date {
    color: map-get($colors, grey);
    font-size: 12.5px;
    font-weight: 300;
    line-height: 1;
    width: 30%;
    padding-left: 30px;
  }

  & > div:nth-child(1) {
    padding-left: 30px;
    color: map-get($colors, grey);
    display: flex;
    width: 550px;
    justify-content: space-between;
  }

  & > div:nth-child(2) {
    width: 80px;
    color: map-get($colors, grey);
    margin-left: auto;
  }
}
