@import './../styles/variables';

.passwordinput {
  position: relative;
  height: 76.95px * $auth-factor;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.passwordinput input {
  width: 100%;
  height: 100%;
  padding-top: 3px * $auth-factor;
  padding-left: 0;
  border: none;
  outline: none;
  font-size: 26px * $auth-factor;
  background: transparent;
  transition: none;
  @include theme() {
    color: theme-get('color-2');
  }
}

.passwordinput input[name='password'] {
  //This was used to align the asterisks with the bottom border og the input
  padding-top: 15px * $auth-factor;
}

.passwordinput label {
  position: absolute;
  bottom: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid map-get($colors, secondary-grey);
  // color: #73847f;
  font-size: 26px * $auth-factor;
  @include theme() {
    color: map-get($colors, 'secondary-grey');
  }
}

.passwordinput-label-text {
  position: absolute;
  bottom: 5px * $auth-factor;
  left: 0px;
  transition: all 0.5s ease;
  //   transauth-input: translateY(4.5px); //myprop
}

.passwordinput input:focus + .passwordinput-label .passwordinput-label-text,
.passwordinput input:valid + .passwordinput-label .passwordinput-label-text {
  transform: translateY(-110%);
  font-size: 18px * $auth-factor;
  // color: #73847f;
  @include theme() {
    color: map-get($colors, 'secondary-grey');
  }
}

.input-padding {
  padding-top: 3px * $auth-factor !important;
}

.input-padding-password {
  padding-top: 15px * $auth-factor !important;
  color: transparent !important;
}
