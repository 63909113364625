@import './../styles/variables';

.u-label {
  -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 65%, 50% 90%, 0 65%);
  clip-path: polygon(0 10%, 100% 10%, 100% 65%, 50% 90%, 0 65%);
  background-color: #40498a;
  width: 35px * $distribution-graph;
  height: 40px * $distribution-graph;
  position: relative;
  border-radius: 8px * $distribution-graph;
  margin-bottom: 5px * $distribution-graph;

  &__text {
    color: #fff;
    font-size: 12px * $distribution-graph;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
}
