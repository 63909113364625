@media (min-width: 768px) {
  .acc-header-wrapper {
    position: fixed;
    background: #181924;
    max-width: calc(1366px - 405px);
    width: calc(100vw - 50vmin);
    height: 80px;
    z-index: 10;
    top: 0px;
  }
}

.acc-header {
  margin-left: 28px;
  margin-top: 25px;
}
