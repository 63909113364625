@import './../styles/variables';

.stu-leader-card {
  padding-right: 10px * $stu-leaderboard-card;
  padding-top: 7px * $stu-leaderboard-card;
  padding-bottom: 7px * $stu-leaderboard-card;
  background: #1f2130;
  box-shadow: 0px 0px 8.16837px #181924;
  border-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 58px * $stu-leaderboard-card;

  &--mine {
    background-color: #40498a;
  }

  &__position {
    min-width: 30px * $stu-leaderboard-card;
    min-height: 30px * $stu-leaderboard-card;
    margin: 0 10px * $stu-leaderboard-card;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    min-width: 35px * $stu-leaderboard-card;
    min-height: 35px * $stu-leaderboard-card;
    background-color: #fff;
    border-radius: 8px * $stu-leaderboard-card;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 400.98px) {
      display: none;
    }
  }

  &__img-two {
    min-width: 35px * $stu-leaderboard-card;
    min-height: 35px * $stu-leaderboard-card;
    max-width: 35px * $stu-leaderboard-card;
    max-height: 35px * $stu-leaderboard-card;
    border-radius: 8px * $stu-leaderboard-card;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 400.98px) {
      display: none;
    }
  }

  &__name-marks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    width: 100%;
  }

  &__name {
    overflow: hidden;
    padding: 0 17px * $stu-leaderboard-card;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    max-width: calc(100vw - 280px);
    word-wrap: break-word;
  }

  &__marks {
    min-width: 40px * $stu-leaderboard-card;
    min-height: 30px * $stu-leaderboard-card;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
