.stu-taa-radio-btn{
    width: 10px;
    height: 10px;
    border: 1px solid #6A6878;
    border-radius: 50%;
    cursor: pointer;

    &--active {
        background-color: #246BFD;

        &--nopointer {
            cursor: no-drop;
        }

        &--disabled {
            background-color: #246BFD;
            cursor: no-drop;
        }
    }

}