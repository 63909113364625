.exam-body {
    .exam-body__question {
      color: #fff;
      line-height: 1;
      margin-top: 20px;
    }
  
    .exam-body__answers {
      margin-top: 35px;
    }
  
    .exam-body__question-no {
      margin-top: 60px;
      color: #fff;
      line-height: 1;
      display: flex;
      h3 {
        margin-bottom: 0;
        line-height: 1;
      }
      & > h3:nth-child(1) {
        margin-right: 8px;
        color: #878789;
      }
    }
    .exam-body__buttons {
      display: flex;
      justify-content: space-between;
      line-height: 1;
      margin-top: 50px;
      button {
        background-color: #246bfd;
        border-radius: 5px;
        color: #fff;
        width: 175px;
        padding: 5px 0;
      }
    }
  
    .exam-body__pre-btn--diabled {
      background-color: #979797 !important;
    }
  
    .exam-body__next-btn--diabled {
      background-color: #979797 !important;
    }
  }
  
  @media (max-width: 575.98px) {
    .exam-body {
      .exam-body__question {
        font-size: 15px;
      }
  
      .exam-body__question-no {
        h3 {
          font-size: 20px;
        }
      }
  
      .exam-body__buttons {
        flex-wrap: wrap;
  
        button {
          font-size: 12px;
        }
      }
    }
  }
  
  @media (max-width: 449.98px) {
    .exam-body {
      .exam-body__buttons {
        button {
          width: 100%;
        }
  
        & > button:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }
  
  .time-large {
    span {
      font-weight: 300;
      color: #73847f;
    }
  }
  
  .btn-struct-essay {
    background-color: #246bfd;
    border-radius: 5px;
    color: #fff;
    width: 175px;
    padding: 5px 0;
  
    margin-top: 140px;
  }
  
  // Medium devices (tablets, less than 992px)
  @media (max-width: 855.98px) {
    .btn-struct-essay {
      width: 130px;
      padding: 5px 0;
      font-size: 12px;
    }
  }
  
  .btn-struct-essay--disabled {
    background-color: #979797 !important;
  }
  
  .stuctured-essay-body {
    * {
      transition: none;
    }
  
    &__timeblock {
      font-size: 120px;
      margin-top: 70px;
    }
  }
  
  .pdf-uploader-comp-wrapper {
    width: 50%;
  }
  
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    .stuctured-essay-body {
      &__timeblock {
        font-size: 50px;
      }
    }
    .pdf-uploader-comp-wrapper {
      width: 90% !important;
    }
  }
  