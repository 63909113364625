@import './../styles/variables';

.custom-dropdown {
  height: auto;
  position: relative;
  padding-top: 23px * $auth-factor;
  padding-bottom: 12.3px * $auth-factor;
  cursor: pointer;

  .custom-dropdown__line {
    border-bottom: 1px solid map-get($colors, secondary-grey);
    height: 100%;
    color: #fff;
    font-size: 26px * $auth-factor;
    position: relative;
    overflow: hidden;
    padding-top: 0.5px;
  }

  .custom-dropdown__name {
    position: absolute;
    left: 0;
    color: map-get($colors, secondary-grey);
    top: -2px;
    font-size: 20px * $auth-factor;
  }
}

.custom-dropdown-list {
  color: map-get($colors, pure);
  background-color: map-get($colors, primary-blue-three);
  width: 100%;
  border-radius: 4.7px * $auth-factor;
  top: 74px * $auth-factor;
  z-index: 10;
  transition: all 0.3s ease;
  font-weight: 400;
  overflow: auto;
  max-height: 161px;

  .dropdown-item-first {
    font-size: 18.6px * $auth-factor;
    padding-left: 15.4px * $auth-factor;
    padding-right: 15.4px * $auth-factor;
    padding-top: 7.7px * $auth-factor;
    padding-bottom: 7.7px * $auth-factor;
    cursor: pointer;
  }

  .dropdown-item-rest {
    border-top: 1px solid map-get($colors, secondary-grey);
    font-size: 18.6px * $auth-factor;
    padding-left: 15.4px * $auth-factor;
    padding-right: 15.4px * $auth-factor;
    padding-top: 7.7px * $auth-factor;
    padding-bottom: 7.7px * $auth-factor;
    cursor: pointer;
  }
}

.lesson-list-dp{
  max-height: 300px;
}

.selected-item {
  display: inline-block;
  background-color: #1f2130;
  border: 1px solid #001eff;
  border-radius: 6px;
  padding: 2px;
  padding-left: 5px;

  margin: 5px 0 0 5px;
  height: 20px;
  font-size: 14px;
  line-height: 10px;
}

.close-icon-mini {
  color: #f05a5a;
  font-size: 14px;
  margin-left: 5px;
  border-radius: 4px;

  &:hover {
    color: #fff !important;
    background: #f05a5a;
  }
}

