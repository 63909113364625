@import './../styles/variables';

.add-lesson-modal {
  z-index: 100;
  // position: fixed;
  position: absolute;
  top: 10vh;
  left: 10%;
  width: 80%;
  color: map-get($colors, pure);
  background-color: map-get($colors, primary-blue-light);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  padding: 25px 40px 30px;
  margin-bottom: 20px;

  .add-lesson-modal__header {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }

  .add-lesson-modal__lsu{
    color:#73847f;
    margin-right: 10px;
  }

  .add-lesson-modal__warning{
    color:rgb(255, 76, 108);
    font-size: 12px;
  }
  
  .add-lesson-copy__btn {
    font-size: 12px;
    background-color: map-get($colors, secondary-blue);
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
    margin-top: 15px;
  }

  .add-lesson-copy__btn--disabled {
    background-color: map-get($colors, secondary-grey);
  }

  .add-lesson-modal__main {
    margin-top: 20px;
    display: flex;

    .add-lesson-modal-inputs {
      width: 60%;

      .add-lesson-modal__btn {
        background-color: map-get($colors, secondary-blue);
        margin-top: 30px;
        color: map-get($colors, pure);
        height: 36px;
        font-weight: 500;
        border-radius: 8px;
        font-size: 14px;
        width: 100px;
        padding: 4px 0;
        text-align: center;
        cursor: pointer;
        outline: none;
        border: none;
      }

      .add-lesson-modal__btn-disabled {
        background-color: map-get($colors, grey);
        cursor: default;
      }
    }
    .add-lesson-modal-image {
      margin-top: 10px;
      width: 185px;
      height: 185px;
      background-color: map-get($colors, primary-blue-three);
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      margin-left: auto;
    }

    .add-lesson-modal__btn-mobile {
      background-color: map-get($colors, secondary-blue);
      margin-top: 50px;
      color: map-get($colors, pure);
      font-weight: 500;
      border-radius: 5px;
      font-size: 13px;
      width: 100px;
      padding: 2px 0;
      text-align: center;
      cursor: pointer;
      outline: none;
      border: none;
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .add-lesson-modal {
    left: calc(50% - 320px);
    width: 640px;
  }
}

@media (min-width: 768px) {
  .add-lesson-modal {
    left: calc(50% - 320px);
    width: 640px;

    .add-lesson-modal__main {
      display: flex;
    }
  }
}

@media (max-width: 739.98px) {
  .add-lesson-modal {
    .add-lesson-modal__main {
      display: block;
      .add-lesson-modal-inputs {
        width: 100%;

        .add-lesson-modal__btn {
          display: none;
        }
      }

      .add-lesson-modal__btn-mobile {
        margin-top: 20px;
        display: block;
      }
      .add-lesson-modal-image {
        // width: 100%;
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 370.98px) {
  .add-lesson-modal {
    .add-lesson-modal__main {
      .add-lesson-modal-image {
        width: 100%;
      }
    }
  }
}

.add-lesson-modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.add-lesson-modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.add-lesson-modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.add-lesson-modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 300ms;
}
