
  .row_card {
    color: white;
    margin-top: 10px !important;
    background: #1f2130;
    border-radius: 10px;
  }
  .time {
    color: #979797;
    font-family: Roboto, serif;
    font-weight: 400;
    font-size: 14px;
  }

  .bg-progress_first {
    background-color: #1fa35a !important;
  }