.stu-exam-preview-papercard {
  display: flex;
  background-color: #1f2130;
  padding: 15px;
  border-radius: 8px;
  width: 49.5%;
  margin-bottom: 10px;
  cursor: pointer;
  height: 75px;

  &__tag-con {
    width: 55px;
    padding-right: 10px;
    padding-top: 2px;
    padding-left: 2px;
    padding-bottom: 2px;
    border-right: 2px solid #404462;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tag {
    padding: 5px 10px;
    background-color: #404462;
    border-radius: 3px;
    font-size: 10px;
    color: #cdcdcd;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__papername {
    color: #fff;
    padding-left: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &__payment {
    color: #fff;
    padding-left: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;

    input {
      width: 100px;
    }
  }

  &__payment-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #73847f;
  }

  &__isFree {
    color: #fff;
    padding-left: 15px;
    overflow: hidden;
    align-items: center;
    font-size: 12px;
    width: 100px;
    margin-top: 12px;
  }

  &__isfree_tag {
    color: #fff;
    padding-left: 7px;
    margin-top: 3px;
    overflow: hidden;
    align-items: center;
    font-size: 12px;
  }
}
