@import './../styles/variables';

.add-subject-modal {
  z-index: 100;
  // position: fixed;
  position: absolute;
  top: 10vh;
  left: 10%;
  width: 80%;
  color: map-get($colors, pure);
  background-color: map-get($colors, primary-blue-light);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  padding: 25px 40px 30px;

  .add-subject-modal__header {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .add-subject-modal__main {
    margin-top: 30px;
    display: flex;

    .add-subject-modal-inputs {
      width: 55%;
    }

    .add-subject-modal-image {
      margin-top: 10px;
      width: 208px;
      height: 208px;
      background-color: map-get($colors, primary-blue-three);
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      margin-left: auto;
    }
  }

  .add-subject-modal__btn {
    background-color: map-get($colors, secondary-blue);
    margin-top: 25px;
    color: map-get($colors, pure);
    border-radius: 8px;
    width: 55%;
    height: 36px;
    padding: 2px 0;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
  }

  .btn-disable-add-sub {
    background-color: map-get($colors, grey);
    cursor: default;
  }
}

@media (min-width: 768px) {
  .add-subject-modal {
    left: calc(50% - 320px);
    width: 640px;

    .add-subject-modal__main {
      margin-top: 30px;
      display: flex;
    }
  }
}

.add-subject-modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.add-subject-modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.add-subject-modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.add-subject-modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 300ms;
}

@media (max-width: 739.98px) {
  .add-subject-modal {
    .add-subject-modal__main {
      display: block;
      .add-subject-modal-inputs {
        width: 100%;
      }
      .add-subject-modal-image {
        // width: 100%;
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 370.98px) {
  .add-subject-modal {
    .add-subject-modal__main {
      .add-subject-modal-image {
        width: 100%;
      }
    }
  }
}
