.notice-layout-wrapper {
  background-color: #181924;
  height: 100%;
  padding-bottom: 30px;
  display: flex;

  .notice-content-wrapper {
    flex: 1;
    padding-left: 50px;
  }
}

@media (max-width: 767px) {
  .notice-layout-wrapper {
    .notice-content-wrapper {
      padding-left: 0px;
    }
  }
}
