@import './../styles/variables';

.tick-mark{
  height: 22px;
  width: 22px;
  border: 1px solid #6A6878;
  border-radius: 8px;
  cursor: pointer;
}
.checked{
  background-color: #246BFD;
}
.checkbox-text {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 10px;
  margin-right: 8px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: default !important;
}

.read-only {
  cursor: default !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
}