.Toastify__close-button > svg {
  color: #fff;
}

.Toastify__toast-icon {
  width: auto !important;
  margin-inline-end: 10px;
  margin-inline-start: 10px;
}

.noti-error-title {
  font-size: 12.5px;
  line-height: 1.2;
  color: white;
}

.noti-error-msg {
  font-size: 12.5px;
  line-height: 1.2;
  color: #73847f;
  margin-top: 3px;
}

.noti-success-msg {
  font-size: 13px;
  line-height: 1.2;
  color: white;
}
