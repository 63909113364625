.exam-body{
    &__filters{
        display:flex;
        align-items: flex-end;
        height:35px;
        margin-top:50px;
        padding-right: 10px;
    }


    &__column-titles{
        display: flex;
        color:#979797;
        margin-top:25px;
       
    }

    &__date{
        transition: none;
        width:25%;
        &--extra{
            width:50%;
        }
    }

    &__title{
        transition: none;
        width: 25%;
    }
     
    &__type{
        transition: none;
        width:50%;
        &--extra{
            width:50%;
        }
    }
    
    &__lists{
        height: calc(100vh - 304px);
        overflow: auto;
        margin-top:10px;
        padding-right:10px;
    }

    &__published-header{
        display:flex;
        margin-top:15px;
    }

    &__p-text,&__switch{
        color:#979797;
    }

    &__switch{
        font-size: 10px;
        display: flex;
        align-items: center;
        margin-left: 30px;
    }

    &__metrics{
        color:#979797;
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:220px;
        margin-left: auto;

    }

    &__unpublished{
        margin-top: 10px;
    }

    &__graded,&__to-review, &__not-submitted{
        font-size: 10px;
    }

}