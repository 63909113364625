@import './../styles/variables';

.pretty .state label:before {
  border-color: map-get($colors, primary-grey) !important;
}

//large one
// .signin-auth {
//   width: 528px;
//   height: auto;
//   background-color: map-get($colors, primary-blue-light);
//   padding: 45px 45px 0;
//   border-radius: 15px;
//   margin-top: 60px;
// }

// .signin-auth__header {
//   font-size: 30px;
//   color: map-get($colors, pure);
//   text-align: center;
//   line-height: 1;
// }

// .signin-auth__remme-forgetpass {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 50px;
//   label {
//     color: map-get($colors, primary-grey);
//     font-weight: 500;
//     line-height: 1px;
//     margin-left: 5px !important;
//   }
//   a {
//     font-size: 18px;
//   }
// }

// .signin-auth__buttons {
//   margin-top: 50px;
//   .google-btn,
//   .login-btn {
//     border-radius: 30px;
//     outline: none;
//     border: none;
//     cursor: pointer;
//     display: block;
//     width: 100%;
//     color: map-get($colors, pure);
//   }
//   .google-btn {
//     border: 1px solid map-get($colors, primary-grey);
//     background-color: transparent;
//     padding: 12px 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     div {
//       margin-left: 5px;
//     }
//   }
//   .login-btn {
//     margin-top: 25px;
//     background-color: map-get($colors, secondary-blue);
//     padding: 12px 0;
//     font-size: 20px;
//   }
// }

// .signin-auth__create-atn {
//   color: map-get($colors, secondary-blue);
//   text-align: center;
//   margin-top: 35px;
//   padding-bottom: 35px;
//   line-height: 1;
//   font-size: 18px;
//   display: block;
// }

.pretty {
  font-size: 18px * $auth-factor;
}

.signin-auth {
  width: 570px * $auth-factor;
  height: auto;
  @include theme() {
    background-color: theme-get('authComp-bg');
  }

  padding: (47px * $auth-factor) (47px * $auth-factor) 0;
  border-radius: 15px * $auth-factor;
}

.signin-auth__header {
  font-size: 30px * $auth-factor;
  @include theme() {
    color: theme-get('authComp-header');
  }
  text-align: center;
}

.signin-auth__remme-forgetpass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px * $auth-factor;
  label {
    color: map-get($colors, primary-grey);
    font-weight: 500 !important;
    line-height: 1px;
    margin-left: 5px !important;
  }
  a {
    font-size: 18px * $auth-factor;
  }
}

.signin-auth__buttons {
  margin-top: 50px * $auth-factor + 6px;
  .google-btn,
  .login-btn {
    border-radius: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
  }
  .google-btn {

    margin-top: 25px * $auth-factor;
    border: 1px solid map-get($colors, primary-grey);
    background-color: transparent;
    padding: (8px * $auth-factor) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px * $auth-factor;
    @include theme() {
      color: theme-get('authComp-google-btn');
    }
    div {
      margin-left: 5px * $auth-factor;
    }
  }
  .login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get($colors, secondary-blue);
    padding: (11px * $auth-factor) 0;
    font-size: 20px * $auth-factor;
    color: map-get($colors, pure);
  }

  .login-btn-dis {
    background-color: map-get($colors, grey);
    cursor: default;
  }
}

.signin-auth__create-atn {
  color: map-get($colors, secondary-blue);
  text-align: center;
  margin-top: 35px * $auth-factor;
  padding-bottom: 40px * $auth-factor;
  line-height: 1;
  font-size: 18px * $auth-factor;
  display: block;
}

//mobile
@media (max-width: 575.98px) {
  .signin-auth {
    padding: 26px 26px 0;
    width: 90%;
    max-width: 570px * $auth-factor;
  }

  .signin-auth__remme-forgetpass {
    display: block;
    text-align: center;
    margin-top: 25px;
    a {
      margin-top: 15px;
      display: block;
    }
  }

  .signin-auth__create-atn {
    margin-top: 35px;
    padding-bottom: 40px;
  }

  .pretty {
    margin-right: 0 !important;
  }
}
