.exam-details {
    margin-top: 70px;
    padding-left: 70px !important;
    .exam-details__title {
      font-weight: 600;
      color: #fff;
      font-size: 40px;
      font-weight: 500;
      line-height: 1;
    }
  
    .exam-details__grade {
      background-color: #f3b619;
      width: 65px;
      text-align: center;
      color: #fff;
    }
  
    .exam-details__subjec-name {
      color: #979797;
    }
  
    .exam-details__year {
      color: #4f98c0;
    }
  
    .exam-details__description {
      color: #dedede;
      font-weight: 400;
      p {
        margin-bottom: 0;
      }
    }
  
    .exam-details__author-name {
      font-size: 13px;
      margin-top: 10px;
      color: #979797;
    }
  
    .exam-details__ex-info {
      flex-wrap: wrap;
      display: flex;
      margin-top: 10px;
    }
  
    .exam-details__buy-btn {
      background-color: #246bfd;
      height: 90px;
      width: 210px;
      margin-top: 40px;
      border-radius: 7px;
      color: #fff;
      & > div:first-child {
        font-size: 25px;
        font-weight: 500;
      }
  
      & > div:last-child {
        font-size: 25px;
        span {
          font-weight: 300;
        }
      }
    }
  }
  
  @media (max-width: 1147.98px) {
    .exam-details {
      padding-left: 0 !important;
    }
  }
  
  @media (max-width: 767.98px) {
    .exam-details {
      .exam-details__buy-btn {
        width: 100%;
      }
    }
  }
  