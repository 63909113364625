@import './../styles/variables';

.pretty .state label:before {
  border-color: map-get($colors, primary-grey) !important;
}

.pretty .state .checkbox-highlighted::before {
  border: 2px solid #dc3545 !important;
  font-weight: 500 !important;
}

//large one
// .signup-auth {
//   width: 528px;

//   padding: 33px 45px 0;
//   border-radius: 15px;
//   margin-top: 60px;
//   @include theme() {
//     background-color: theme-get('auth-component');
//   }
// }

// .signup-auth__header {
//   font-size: 30px;
//   color: map-get($colors, pure);
//   text-align: center;
//   line-height: 1;
// }

// .signup-auth__remme-forgetpass {
//   margin-top: 20px;
//   text-align: center;
//   label {
//     color: map-get($colors, primary-grey);
//     font-weight: 500;
//     line-height: 1px;
//     margin-left: 5px !important;
//   }
//   a {
//     font-size: 18px;
//   }
// }

// .signup-auth__buttons {
//   margin-top: 21px;
//   .google-btn,
//   .signup-btn {
//     border-radius: 30px;
//     outline: none;
//     border: none;
//     cursor: pointer;
//     display: block;
//     width: 100%;
//     color: map-get($colors, pure);
//   }
//   .google-btn {
//     border: 1px solid map-get($colors, primary-grey);
//     background-color: transparent;
//     padding: 12px 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     div {
//       margin-left: 5px;
//     }
//   }
//   .signup-btn {
//     margin-top: 20px;
//     background-color: map-get($colors, secondary-blue);
//     padding: 12px 0;
//     font-size: 20px;
//   }
// }

// .signup-auth__create-atn {
//   color: map-get($colors, secondary-blue);
//   text-align: center;
//   margin-top: 20px;
//   padding-bottom: 35px;
//   line-height: 1;
//   font-size: 18px;
//   display: block;
// }

.pretty {
  font-size: 18px * $auth-factor;
}

.signup-auth {
  width: 570px * $auth-factor;
  height: auto;
  background-color: map-get($colors, primary-blue-light);
  padding: (20px * $auth-factor) (45px * $auth-factor) 0;
  border-radius: 15px * $auth-factor;

  // box-shadow: 0px 0px 8px rgba(198, 198, 198, 0.25);
}

.signup-auth__header {
  font-size: 30px * $auth-factor;
  color: map-get($colors, pure);
  text-align: center;
}

.signup-auth__remme-forgetpass {
  margin-top: 15px * $auth-factor;
  text-align: center;
  position: relative;
  label {
    color: map-get($colors, primary-grey);
    font-weight: 500;
    line-height: 1px;
    margin-left: 5px !important;
  }
  a {
    font-size: 18px * $auth-factor;
  }

  .terms {
    font-size: 10.8px;
    font-weight: 300;
    left: 102px;
    top: 6px;
  }

  .and {
    font-size: 10.8px;
    font-weight: 300;
    left: 102px;
    top: 6px;
    color: map-get($colors, secondary-grey) !important;
    cursor: pointer;
  }

  .conditions {
    font-size: 10.8px;
    font-weight: 300;
    left: 155px;
    top: 6px;
  }
}

.signup-auth__buttons {
  margin-top: 21px * $auth-factor;
  .google-btn,
  .signup-btn {
    border-radius: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
    color: map-get($colors, pure);
  }
  .google-btn {

    margin-top: 25px * $auth-factor;
    border: 1px solid map-get($colors, primary-grey);
    background-color: transparent;
    padding: (8px * $auth-factor) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 17px * $auth-factor;

    div {
      margin-left: 5px * $auth-factor;
    }
  }
  .signup-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get($colors, secondary-blue);
    padding: (12px * $auth-factor) 0;
    font-size: 20px * $auth-factor;
    color: map-get($colors, pure);
  }
  .signup-btn-dis {
    background-color: map-get($colors, grey);
    cursor: default;
  }
}

.signup-auth__create-atn {
  color: map-get($colors, secondary-blue);
  text-align: center;
  margin-top: 25px * $auth-factor;
  padding-bottom: 30px * $auth-factor;
  line-height: 1;
  font-size: 18px * $auth-factor;
  display: block;
}

//mobile
@media (max-width: 575.98px) {
  .signup-auth {
    padding: 26px 26px 0;
    width: 90%;
    max-width: 570px * $auth-factor;
  }

  .signup-auth__create-atn {
    margin-top: 35px;
    padding-bottom: 40px;
  }

  .signup-auth__buttons {
    margin-top: 25px;
  }

  .pretty {
    margin-right: 0 !important;
  }
}
