.sed-header{
    display:flex;
    color:#fff;
    align-items: center;
    line-height: 1.2;

    &__title{
        width:25%;
        margin-left:50px;
        &-header{
            font-size: 12px;
            color:#979797;
        }
        &-val{
            font-size: 15px;
        }
    }

    &__subject{
        width:25%;
        &-header{
            font-size: 12px;
            color:#979797;
        }
        &-val{
            font-size: 15px;
        }
    }

    &__grade{
        width:25%;
        &-header{
            font-size: 12px;
            color:#979797;
        }
        &-val{
            font-size: 15px;
        }
    }

    &__actions{
        display:flex;
        align-items: center;
        width:25%;
        
    }

}