.packages-roster-modal-package-card {
  width: 100%;
  height: 90px;
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: default;

  &__img {
    background-position: center;
    background-size: cover;
    height: 90px;
    width: 90px;
    border-radius: 8px 0px 0px 8px;
  }

  &__align-text-field {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: 2%;
  }
  &__package-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
  &__expired-date {
    align-self: center;
    font-family: 'Roboto';
    font-style: normal;
    color: #ff9a9a;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-right: 2%;
  }
}

@media (max-width: 773px) {
  .packages-roster-modal-package-card__package-text {
    font-size: 12px;
    line-height: 16px;
  }

  .packages-roster-modal-package-card__expired-date {
    font-size: 10px;
    line-height: 10px;
  }
}

@media (max-width: 675px) {
  .packages-roster-modal-package-card__package-text {
    font-size: 10px;
    line-height: 14px;
  }

  .packages-roster-modal-package-card__expired-date {
    font-size: 8px;
    line-height: 10px;
  }
}
