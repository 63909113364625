@import './../styles/variables';


.StructuredPapers-Uploader-step-2 {
  .add-subject-modal {
    z-index: 100;
    // position: fixed;
    position: absolute;

    left: 10%;
    width: 80%;
    color: map-get($colors, pure);
    background-color: map-get($colors, primary-blue-light);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    padding: 25px 40px 30px;

    .add-subject-modal__header {
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      align-items: center;
    }

    .add-subject-modal__main {
      margin-top: 30px;
      display: flex;

      .add-subject-modal-inputs {
        width: 50%;
      }

      .add-subject-modal-image {
        width: 74px !important;
        height: 74px !important;
        background-color: map-get($colors, primary-blue-three);
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        margin-left: auto;

        .pdf-title {
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .add-subject-modal__btn {
      background-color: map-get($colors, secondary-blue);
      margin-top: 25px;
      color: map-get($colors, pure);
      font-weight: 500;
      border-radius: 5px;
      font-size: 14px;
      width: 100px;
      padding: 5px 0;
      text-align: center;
      cursor: pointer;
      outline: none;
      border: none;
    }

    .btn-disable-add-sub {
      background-color: map-get($colors, grey);
      cursor: default;
    }
  }

  @media (min-width: 768px) {
    .add-subject-modal {
      left: calc(50% - 320px);
      width: 640px;

      .add-subject-modal__main {
        margin-top: 30px;
        display: flex;
      }
    }
  }

  .add-subject-modal-enter {
    transform: translateY(-10rem);
    opacity: 0;
  }

  .add-subject-modal-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: all 300ms;
  }

  .add-subject-modal-exit {
    transform: translateY(0);
    opacity: 1;
  }

  .add-subject-modal-exit-active {
    transform: translateY(-10rem);
    opacity: 0;
    transition: all 300ms;
  }

  @media (max-width: 739.98px) {
    .add-subject-modal {
      .add-subject-modal__main {
        display: block;
        .add-subject-modal-inputs {
          width: 100%;
        }
        .add-subject-modal-image {
          // width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 370.98px) {
    .add-subject-modal {
      .add-subject-modal__main {
        .add-subject-modal-image {
          width: 100%;
        }
      }
    }
  }
}
