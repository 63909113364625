@import './../styles/variables';

.structured-p-card {
  background-color: #1f2130;
  border-radius: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 30px !important;
  padding-right: 30px !important;
  cursor: pointer;
  margin-top: 10px;

  .structured-p-card_main {
    display: flex;
    height: 45px;
    align-items: center;
  }

  .structured-p-card__text {
    font-size: 15px;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    color: map-get($colors, pure);
  }

  .structured-p-card__status {
    margin-left: auto;
    font-size: 12px;
    padding-right: 20px;
    padding-left: 30px;
    border-left: 1px solid #404462;
    height: 100%;
    display: flex;
    align-items: center;
    color: map-get($colors, grey);
    width: 113px;
    span {
      width: 100%;
    }
  }

  .structured-p-card__actions {
    display: flex;
    height: 100%;
    border-left: 1px solid #404462;
    align-items: center;
    padding-left: 25px;
  }
}
