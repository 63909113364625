.content1 {
    grid-area: content1;
    height: 234px;
    position: relative;

    &__header {
        font-size: 26px;
        line-height: 30.47px;
        font-weight: 600;
        color: #FFFFFF;
        padding-left: 5%;
        padding-right: 10%;
        padding-bottom: 10px;
        margin-top: 3px;
    }

    &__text {
        font-size: 16px;
        color: #A4A4A4;
        font-weight: 400;
        line-height: 18px;
        padding-left: 5%;
        padding-right: 10%;
        height: 70px;
        overflow: auto;

    }

    &__grade-text {
        background-color: #E7A600;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding: .2rem .8rem;

    }

    &__year-text {
        font-weight: 700;
        font-size: 20px;
        line-height: 16px;
        color: #4F98C0;
        margin-top: .3rem;
    }

    &__language-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    &__countdown-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #A4A4A4;
    }
}


.gap div:first-of-type {
    padding-left: 5%;
}

.gap {
    gap: 5%;
}

.gap div:last-of-type {
    margin-left: auto;
    padding-right: 10%;
}


.flex-container {
    display: flex;
}

.flex-child {
    flex: 1;
}

.flex-child-2 {
    flex: auto;
    flex-grow: 0;
}

.margin-top-sm {
    margin-top: 4.6rem;
}

@media only screen and (max-width:767.98px) {
    .content1 {
        &__text {
            padding-right: 5%;
        }

        &__header {
            padding-right: 5%;
        }
    }

    .gap div:last-of-type {
        padding-left: 5%;
        position: absolute;
        top: 85%;
    }

    .margin-top-sm {
        margin-top: 1rem;
    }
}

@media only screen and (max-width:590px) {
    .gap div:last-of-type {
        top: 93%;
    }

    .gap {
        gap: 8%;
    }
}

.package-info-back_btn{
    margin-left:-1rem;
    margin-bottom:2rem;

    &>svg{
        margin-left: 7px;
        margin-top: 4px;
    }
}