@import './../styles/variables';
.correct-answer label:before,
.correct-answer label:after {
  border-radius: 20% !important;
  font-size: 15px !important;
  margin-top: 9px !important;
  border: 1.5px solid map-get($colors, primary-grey) !important;
}

.correct-answer label {
  text-indent: 1.5em !important;
  color: map-get($colors, secondary-grey);
  font-weight: 500 !important;
}

.mcq-edit-page {
  .mcq-dropdown-component {
    margin-top: 83px;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mcq-answer-container {
    height: calc(100vh - 180px) !important;
    overflow: auto;
    padding-right: 20px;
    padding-bottom: 55px;
  }
}

.q-card-table-header {
  margin-top: 45px;
  div:nth-child(1) {
    padding-left: 30px;
    width: 116px;
    color: map-get($colors, grey);
  }

  div:nth-child(2) {
    padding-left: 40px;
    flex: 1;
    color: map-get($colors, grey);
  }

  div:nth-child(3) {
    width: 160px;
    text-align: center;
    color: map-get($colors, grey);
  }

  .q-card-list {
    height: calc(100vh - 360px) !important;
    overflow: auto;
  }
}

.q-card-tbl-titles {
  margin-top: 45px;
  div:nth-child(1) {
    padding-left: 30px;
    width: 116px;
    color: map-get($colors, grey);
  }

  div:nth-child(2) {
    padding-left: 40px;
    flex: 1;
    color: map-get($colors, grey);
  }

  div:nth-child(3) {
    width: 203px;
    text-align: center;
    color: map-get($colors, grey);
  }

  .q-card-list {
    height: calc(100vh - 360px) !important;
    overflow: auto;
  }
}

.filter_button {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  height: 35px;
  width: 115px;
  border-radius: 8px;
  color: map-get($colors, pure);
  background-color: map-get($colors, secondary-blue);
}
@media (max-width: 575.98px) {
  .filter_button {
    width: 80px;
  }
}

.create-question-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  color: map-get($colors, pure);
  background-color: map-get($colors, secondary-blue);
  height: 35px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
}

.preview-content {
  height: calc(100vh - 144px);
  overflow: auto;
  padding-right: 10px;
}

.preview-bottom {
  margin-top: 45px;

  & > div:nth-child(1) {
    padding-left: 30px !important;
  }

  & > div:nth-child(2) {
    padding-left: 30px !important;
  }

  .source-of-question,
  .source {
    font-size: 12px;
    color: map-get($colors, grey);
    line-height: 1;
    margin-bottom: 3px;
  }

  .source-of-question-val,
  .source-val {
    color: map-get($colors, pure);
    font-size: 14px;
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }
}
