@import './../styles/variables';

.add-subject-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-radius: 6px;
  padding: 10px 25px 10px 25px;
  line-height: 1;
  border: none;
  color: map-get($colors, pure);
  font-size: 16px;
  background-color: map-get($colors, secondary-blue);
  span {
    margin-left: 5px;
  }
}

@media (max-width: 767.98px) {
  .add-subject-btn {
    width: 100%;
    margin-top: 35px;
  }
}
