.link-content {
  color: #fff;

  &__links {
    display: flex;
    align-items: center;
  }

  &__btn {
    margin-top: 20px;
    width: 103px;
    height: 31px;
    background: #494c67;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
  }
}
