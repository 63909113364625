@import './../styles/variables';

.button {
  background: #246bfd;
  border: 1px solid #246bfd;
  border-radius: 8px;
  width: 200px;
  height: 33px;
  outline: none;
  cursor: pointer;

  .button__text {
    color: #fff;
    line-height: 1;
  }

  &:focus {
    outline: none;
  }
}

.button-outline {
  background: transparent;
}

.button-disable {
  cursor: default !important;
  background-color: map-get($colors, grey);
  border: 1px solid map-get($colors, grey);
}

.button-disable_with-cursor {
  cursor:not-allowed !important;
  background-color: map-get($colors, grey);
  border: 1px solid map-get($colors, grey);
}