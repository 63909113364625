.sdb{
    color: #fff;
    position: absolute;
    z-index: 1;
    width: 515px;
    height: 340px;
    border-radius: 8px;
    background-color: #1f2130;
    padding: 20px;
    top:220px;
    left: 640px;

    &__grade{
        display: flex;
        
        &-label{
            width: 30%;
        }

        &-radios{
            width:70%;
            display:flex;
            flex-wrap: wrap;
        }

    }

    &__dropdowns{

        display:flex;
        justify-content: space-between;
        margin-top: 30px;

        &>div{
            width:48%
        }

    }


    &__buttons{
        display: flex;
        align-items: center;
        margin-top:30px;
    }

    &__clear{
        color:#246bfd;
        cursor:pointer;
    }

    &__cancel-apply{
        display:flex;
        margin-left:auto;
        align-items: center;

    }

    &__cancel{
        color:#246bfd;
        cursor:pointer;
    }

}