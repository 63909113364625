.top-students {
  background: #1b1c28;
  box-shadow: 0px 0px 9.46941px #181924;
  border-radius: 14.2041px;
  padding: 20px;
  min-height: 390px;
  height: fit-content;

  overflow-y: hidden;

  &__header-title {
    font-size: 12px;
    color: #fff;
    padding-bottom: 10px;
  }

  &__total-points {
    font-size: 12px;
    color: #fff;
    padding-bottom: 10px;
  }

  &__list {
    height: 320px;
    margin-bottom: 30px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    background: #1f2130;
    box-shadow: 0px 0px 8.89855px #181924;
    border-radius: 4px;
    padding: 5px 15px;
    color: #fff;
    font-size: 12px;
    display: flex;
    margin-bottom: 2px;
    height: 33px;
  }

  &__student-score {
    margin-left: auto;
  }

  &__student-name {
    width: 80%;
    overflow: hidden;
  }
}
