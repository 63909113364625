.subject-snav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100% !important;

  &__100{
    height: 100% !important;
  }

  &__header {
    display: flex;
    padding-left: 40px;
    color: #fff;
    align-items: center;
    padding-left: 15px;

    h6 {
      padding-left: 10px;
      margin: 0;
    }
  }

  &__items {
    margin-top: 10px;
  }

  &__item {
    color: #fff;
    text-align: left;
    cursor: pointer;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 30px;
    margin: 0;
    font-weight: 400;

    &--active {
      background-color: #246bfd;
    }
  }

  &__button {
    margin-bottom: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #246bfd;
    border-radius: 5px;
    font-size: 15px;
    width: 80%;
    height: 35px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
  }

  &__student-pckg-button {
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #246bfd;
    border-radius: 5px;
    font-size: 15px;
    width: 80%;
    height: 35px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-weight: 400;

   
   

  }

  &__btn-parent {
    display: flex;
    justify-content: center;
    width: 100%;
    align-self: end;

 

    &-wrapper {
      display: flex;
      height: calc(100% - 300px);
      justify-content: center;
      z-index: -1;
      width: 100%;
        position: absolute;
        bottom: 5%
    }

    @media (max-width: 430px) { 
      &-wrapper{
        justify-content: normal;
      } 
    }
  }

  @media (max-width: 767.98px) { 

    &__btn-parent {
      width:auto;
    }

  }  


  @media (max-width: 767.98px) { 

    &__student-pckg-button {
      width:auto;
      padding:15px;
    }

  }  


}
