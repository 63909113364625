.view-notices-wraper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .topbar-wrapper {
    margin-top: 34px;
    margin-bottom: 34px;
    display: flex;
    justify-content: space-between;
    .controller-wrapper {
      display: flex;
      .search-filed {
        display: flex;
      }
      .action-filter {
        margin-left: 14px;
      }
    }
  }

  .f-button {
    height: 35px;
    background-color: #246bfd;
    color: white;
    padding: 0 22px;
    border-radius: 8px;
    &:hover {
      background-color: #2e74ff;
    }
  }
}

@media (max-width: 502px) {
  .view-notices-wraper {
    .topbar-wrapper {
      .controller-wrapper {
        display: block;
        .action-filter {
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
  }
}

@import './../styles/variables';

.search_text {
  font-family: Roboto;
  font-weight: 700;
  font-size: 15px;
  background-color: unset;
  outline: none;
  border: 1px solid map-get($colors, secondary-grey);
  border-right: none;
  color: map-get($colors, pure);
  border-radius: 8px 0 0 8px;
  width: 223px;
  height: 35px;
  padding-left: 10px;
}
.search_button {
  background-color: map-get($colors, secondary-blue);
  color: map-get($colors, pure);
  height: 35px;
  width: 44px;
  border-radius: 0px 8px 8px 0;
  border: 1px solid map-get($colors, secondary-blue);
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_text::placeholder {
  color: #fff;
  opacity: 1;
}

.search-width {
  width: 200px;
}

@media (max-width: 575.98px) {
  .search-width {
    width: 130px !important;
  }
}

@media (max-width: 349.98px) {
  .search-width {
    width: 90px !important;
  }
  .search_text {
    font-size: 10px !important;
  }
}
