.package-card {
  margin-right: 3%;
  margin-top: 23px;
  cursor: pointer;
  width: 455px;
  height: 144px;
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 15px;
  display: flex;

  &--unsbscribe {
    background: #252842;
  }

  &__img {
    background-position: center;
    background-size: cover;
    object-fit: cover;
    width: 220px;
    height: flex;
    border-radius: 15px 0px 0px 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  &__title {
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16.1918px;
    line-height: 19px;
    color: #ffffff;
  }

  &__desc {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #979797;
  }

  &__remain-time {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ff9a9a;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__space {
    margin-top: 10%;
  }

  &__date {
    align-self: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #979797;
  }
  &__price-box-group {
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
  }

  &__price {
    align-items: center;
    margin-top: auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    color: #ffffff;

    &__box-padding {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__price-box {
    align-items: center;
    margin-top: auto;
    width: auto;
    height: auto;
    background: #191b29;
    border-radius: 6.90085px;
  }

  &__subscribed {
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    color: #20d167;
    margin-right: 0.5%;
  }
}

@media (max-width: 1149.98px) {
  .package-card {
    width: 45%;
    margin-right: 5%;
  }
}

@media (max-width: 899.98px) {
  .package-card {
    width: 100%;
    margin-right: 5%;
  }
}

@media (max-width: 575.98px) {
  .package-card {
    width: 100%;
    margin-right: 0;
  }
}
