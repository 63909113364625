@import './../styles/variables';

.mrk-dist {
  background-color: #1b1c28;
  width: 500px * $distribution-graph;
  padding: 15px * $distribution-graph 25px * $distribution-graph;
  position: relative;
  border-radius: 8px * $distribution-graph;
  border-radius: 15px;

  @media (max-width: 590.98px) {
    width: auto;
  }

  &__heading {
    color: #fff;
    font-size: 15px * $distribution-graph;
    margin-bottom: 15px * $distribution-graph;
  }

  &__marks {
    display: flex;
    justify-content: space-between;
  }

  &__mean,
  &__lowest,
  &__highest {
    width: 31%;
    background-color: #1f2130;
    color: #fff;
    border-radius: 8px * $distribution-graph;
    padding: 10px * $distribution-graph 10px * $distribution-graph;
    text-align: center;
    & > div:first-child {
      font-size: 15px * $distribution-graph;
      line-height: 1;
    }

    & > div:last-child {
      color: #8b8b8b;
      line-height: 1;
      font-size: 10px * $distribution-graph;
      margin-top: 4px * $distribution-graph;
    }
  }

  &__x-axis {
    color: #88ba9f;
    font-size: 11px * $distribution-graph;
    text-align: center;
    margin-top: 5px;
  }

  &__y-axis {
    position: absolute;
    top: 57%;
    left: -3px;
    color: #88ba9f;
    font-size: 11px * $distribution-graph;
    transform: rotate(-90deg);
  }
}

.two {
  .mrk-dist {
    background-color: #1b1c28;
    width: 500px * $distribution-graph-two;
    padding: 15px * $distribution-graph-two 25px * $distribution-graph-two;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 0px 9.46941px #181924;

    @media (max-width: 590.98px) {
      width: auto;
    }

    &__heading {
      color: #fff;
      font-size: 15px * $distribution-graph-two;
      margin-bottom: 15px * $distribution-graph-two;
      margin-top: 10px;
    }

    &__marks {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }

    &__mean,
    &__lowest,
    &__highest {
      width: 150px;
      height: 55px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #1f2130;
      color: #fff;
      border-radius: 8px * $distribution-graph-two;
      padding: 10px * $distribution-graph-two 10px * $distribution-graph-two;
      text-align: center;
      box-shadow: 0px 0px 8.16837px #181924;
      & > div:first-child {
        font-size: 15px * $distribution-graph-two;
        line-height: 1;
      }

      & > div:last-child {
        color: #8b8b8b;
        line-height: 1;
        font-size: 10px * $distribution-graph-two;
        margin-top: 4px * $distribution-graph-two;
      }
    }

    &__x-axis {
      color: #88ba9f;
      font-size: 11px * $distribution-graph-two;
      text-align: center;
    }

    &__y-axis {
      position: absolute;
      top: 57%;
      left: -3px;
      color: #88ba9f;
      font-size: 11px * $distribution-graph-two;
      transform: rotate(-90deg);
    }
  }
}
