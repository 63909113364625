.uploaded-paper {
  display: inline-block;
  width: 30%;

  &__icon-wrapper {
    height: 90px;
    width: 70px;
    background-color: #2c2e41;
    border-radius: 6px;
    position: relative;
    text-align: right;
    padding: 5px;
    width: 100%;
  }

  &__pdf-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__no-upload-tag {
    position: absolute;
    margin: 15px 0px;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: #fff;
  }
}
