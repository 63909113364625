.question-result {
  display: flex;
  line-height: 1.2;
  .question-result__question-no {
    color: #fff;
  }

  .question-result__content {
    color: #fff;
    margin-left: 10px;
    width: 100%;
  }

  .question-result__answer-cards {
    margin-top: 15px;
  }

  .question-result__resolve-answer {
    display: flex;
    padding: 12px 23px 20px 23px;
    background-color: #1f2130;
    border-radius: 10px;
    box-shadow: 0px 0px 6.4176px #181924;

    .question-result__icon {
      font-size: 20px;
    }
  }

  .question-result__resolve-content {
    font-family: Montserrat;
    font-weight: 400;
    padding-left: 20px;
    line-height: 1.2;
    margin-top: 8px;

    & > div:first-child {
      color: #e3646c;
      font-size: 13px;
    }

    & > div:last-child {
      margin-top: 7px;
      color: #9b9b9b;
      font-size: 13px;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .question-result__question {
    font-size: 14px;
    font-weight: 400;
  }
}
