.question-preview-modal {
  z-index: 100;
  position: absolute;
  top: 10vh;
  left: 10%;
  width: 80%;
  max-width: 650px;
  max-height: 494px;
  color: #fff;
  background-color: #181924;
  border-radius: 8px;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: auto;

  .question-preview-modal__question {
    overflow: auto;
  }
}
