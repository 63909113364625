@import './../styles/variables';

.tf-answer {
  padding: 25px;
  background-color: map-get($colors, primary-blue-light);
  border-radius: 5px;
  width: 48.2%;
}

.tf-answer-checkbox label:before,
.tf-answer-checkbox label:after {
  font-size: 20px !important;
  margin-top: 8px !important;
  border: 1.5px solid map-get($colors, primary-grey) !important;
}

.tf-answer-checkbox label {
  text-indent: 1.8em !important;
  color: map-get($colors, secondary-grey);
  font-weight: 500 !important;
}
