@import './../styles/variables';

.muf-pop-up {
  width: 550px;
  height: auto;
  z-index: 10;
  top: 40px;
  left: 350px;
  background-color: map-get($colors, primary-blue-light);
  padding: 30px;
  border-radius: 10px;

  .muf-pop-up__user-type {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #33364e;
    padding-bottom: 20px;

    .user-type {
      color: map-get($colors, grey) !important;
    }

    div:nth-child(1) {
      color: map-get($colors, pure);
      width: 100px;
      margin-bottom: 10px;
    }

    div:nth-child(2) {
      flex-wrap: wrap;
      div {
        width: 100px;
      }
    }
  }

  .muf-pop-up__status {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    margin-top: 25px;

    .pop-up-status {
      color: map-get($colors, grey) !important;
    }

    div:nth-child(1) {
      color: map-get($colors, pure);
      width: 98px;
      margin-bottom: 10px;
    }

    div:nth-child(2) {
      flex-wrap: wrap;
      div {
        width: 100px;
      }
    }
  }

  .muf-pop-up__sub-less {
    margin-top: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #33364e;
    display: flex;
    & > div:nth-child(1) {
      width: 50%;
      padding-right: 20px;
    }
    & > div:nth-child(2) {
      width: 50%;
      padding-left: 20px;
    }
  }

  .muf-pop-up__cmp-cby {
    color: #73847f;
    position: relative;
    display: flex;
    margin-top: 12px;
    & > div:nth-child(1) {
      & > div:nth-child(1) {
        font-size: 15px;
        line-height: 1;
      }

      & > div:nth-child(2) {
        position: relative;
        top: -5px;
      }

      width: 50%;
      padding-right: 20px;
    }
    & > div:nth-child(2) {
      width: 50%;
      padding-left: 20px;
    }
  }

  .muf-pop-up__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    & > div:nth-child(1) {
      color: map-get($colors, secondary-blue);
      cursor: pointer;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      color: map-get($colors, secondary-blue);

      & > div:nth-child(1) {
        margin-right: 20px;
      }
    }

    .apply {
      padding-left: 22px;
      padding-right: 22px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 8px;
      background-color: map-get($colors, secondary-blue);
      color: map-get($colors, pure);
      font-weight: 500;
    }
  }
}
