.navigator-paper-card {
  background: #1f2130;
  height: 50px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  cursor: pointer;

  .navigator-paper-card__tag {
    min-width: 40px;
    height: 75%;
    margin-top: auto;
    margin-bottom: auto;
    background: #404462;
    border-radius: 5px;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navigator-paper-card__paper-name {
    color: #fff;
    line-height: 1;
    font-size: 11px;
    margin-left: 10px;
    width: 82px;
    overflow: hidden;
  }

  .navigator-paper-card__exam-button {
    background: #246bfd;
    min-width: 65px;
    height: 95%;
    border-radius: 7px;
    color: #fff;
    margin-left: 10px;
    font-size: 14px;
  }
}

.navigator-paper-card--selected {
  border: 1px solid #fff;
}
