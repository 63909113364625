.chapter-preview-header {
  padding: 10px;
  padding-bottom:16px;
  background-color: #181924;
  display: flex;
  align-items: center;
  border-radius: 3px;

  &__title {
    color: #979797;
    line-height: 1;
    margin: 0;
  }

  &__btn {
    color: #fff;
    background-color: #246bfd;
    display: flex;
    padding: 10px;
    border-radius: 8px;
    align-items: center;
    margin-left: auto;
  }
}
