// .overall_grade_container {
//   min-height: calc(100vh - 120px);
//   align-items: center;
// }

// .overall_grade {
//   text-align: center;
//   background-color: #1f2130;
//   border-radius: 14px;
//   height: 105px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   .marks_value {
//     font-weight: 900;
//     font-size: 36px;
//     color: #ffffff;
//     width: 100%;
//     overflow: hidden;
//   }

//   .marks_text {
//     width: 100%;
//     color: #246bfd;
//     font-weight: 400;
//     font-size: 28px;
//     padding: 0;
//     margin-top: -15px;
//   }
// }

// .mark_card {
//   background-color: #1f2130;
//   padding: 28px 28px 36px 28px;
//   width: 344px;
//   border-radius: 14px;

//   .gray {
//     color: #8b8b8b;
//   }

//   .white {
//     color: #ffffff;
//   }

//   .thin {
//     font-weight: 300;
//   }

//   .mark_header {
//     display: flex;
//     justify-content: space-between;
//     // align-items: center;

//     .title {
//       width: calc(100% - 80px);
//       font-size: 16px;
//     }

//     .type {
//       background-color: #404462;
//       color: #cdcdcd;
//       width: 54px;
//       height: 25px;
//       border-radius: 4px;
//       font-size: 14px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//   }

//   .mark_section {
//     margin-top: 46px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     .time_col {
//       text-align: center;
//       overflow: hidden;

//       .no_of_questions {
//         font-size: 16px;
//       }

//       .time_comp {
//         font-weight: 700;
//         font-size: 28px;
//       }
//     }

//     .grade_col {
//       background-color: #292c3f;
//       border-radius: 14px;
//       width: 133px;
//       height: 78px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       font-size: 24px;
//       line-height: 25px;

//       .grade_value {
//         font-weight: 900;
//       }

//       .grade_text {
//         color: #246bfd;
//         font-weight: 400;
//       }
//     }
//   }

//   .view_btn {
//     margin-top: 50px;
//   }
// }

// @media (max-width: 430.98px) {
//   .mark_card {
//     width: 90%;
//   }
// }

// // Small devices (landscape phones, less than 768px)
// @media (max-width: 420.98px) {
//   .mark_card {
//     .mark_section {
//       display: block;

//       .grade_col {
//         margin: auto;
//         margin-top: 20px;
//       }
//     }
//   }
// }

//NEW STYLES

.student-marks {
  display: flex;
  margin-top: 30px;

  @media (max-width: 1040.98px) {
    flex-direction: column;
  }
  &__pending {

    width: 80%;
    @media (max-width: 1040.98px) {
      width: 100%;
    }
  }

  &__papers {
    margin-right: 50px;
    width: 650px;

    @media (max-width: 855.98px) {
      margin-right: 0;
      width: auto;
    }

    &-card-mb {
      margin-bottom: 16px;
    }
  }

  // &__stats {
  //   width: 45%;
  //   margin-left: auto;
  // }

  &__grade {
    padding: 20px 0;
    background-color: #1f2130;
    color: #fff;
    border-radius: 8px;

    &-value {
      text-align: center;
      margin-bottom: 0;
    }

    &-text {
      text-align: center;
      color: #246bfd;
      font-weight: 400;
      margin-bottom: 0;
    }

    &-retake-txt {
      font-size: 12px;
      text-align: center;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
        color: #246bfd;
        cursor: pointer;
      }
    }
  }

  &__time-taken {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    &-text {
      color: #fff;
      font-size: 15px;
      margin-right: 10px;
    }

    &-value {
      color: #88888a;
      font-size: 13px;
    }
  }

  &__papers-section {
    height: calc(100vh - 277px);
    overflow: auto;

    @media (max-width: 1040.98px) {
      height: auto;
    }
  }

  &__papers-scroll {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 718.98px) {
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
    }
  }
}
