@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.list {
    grid-area: list;
    padding-left: 3%;
}

.menu {
    overflow: auto;
    background: #1B1C28;
    box-shadow: 0px 0px 8px #181924;
    border-radius: 8px;
    width: 100%;

    &-item {
        list-style: none;
        border: 1px solid #1F2130;
        overflow: hidden;

        &__sub {
            background: #1F2130;
            overflow: hidden;

            & a {
                display: block;
                padding: 1rem 1.6rem;
                color: #fff;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                border-top: 1px solid #262844;
                border-bottom: 1px solid #262844;
                padding-left: 5rem;
            }

            &-menu {
                padding-left: 3rem !important;
            }
        }
    }
}

.btn-list {
    display: block;
    padding: 1rem 1.2rem;
    color: #fff;
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}


.menu-item:target .menu-item__sub {
    max-height: 50rem;
}

a {
    text-decoration: none;
}

i {
    text-decoration: none;
    margin-right: 1rem;
}

ol {
    margin: 0;
    padding: 0;
}


ol>li>div {
    background: #24273A !important;
    padding-left: 3rem !important;

}

ol>li>div>a {
    border: none !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
}

ol>li {
    border: none !important;

}

.menu-item__sub > a > label {
    background: #404462;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: .3rem .5rem;
    margin-right: .5rem;
    color: #CDCDCD;
        width: 50px;
    text-align: center;
}

.rotate {
    transform: rotate(90deg);
}

.height {
    height: 0;
}


@media only screen and (max-width:767.98px) {
    .list {
        min-width: 86vw;
    }
}

.submenu-globalexams{
    margin-bottom: 0px;
    background: #24273A;

    &-label{
        background: #404462;
        border-radius: 4px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: .3rem .5rem;
        margin-right: .5rem;
        color: #CDCDCD;
        text-align: center;
    }

    &>li>a{
        border: none !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 16px !important;

    }
}

.m-btm_0{
    margin-bottom: 0;
}

