@import './../styles/variables';

.disabled {
  input[type='range'] {
    height: 25px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    background-color: transparent;
  }
  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;

    background: map-get($colors, gray);
    background: map-get($colors, gray);
    border-radius: 1px;
    border: 0px solid #000000;
    border-radius: 50px !important;
  }
  input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    height: 15px;
    width: 15px;
    border-radius: 25px;
    background: map-get($colors, gray);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: map-get($colors, gray);
    background: map-get($colors, gray);
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: map-get($colors, gray);
    background: map-get($colors, gray);
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497e3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: map-get($colors, gray);
    background: map-get($colors, gray);
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: map-get($colors, gray);
    background: map-get($colors, gray);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type='range']::-ms-fill-upper {
    background: map-get($colors, gray);
    background: map-get($colors, gray);
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497e3;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: map-get($colors, gray);
    background: map-get($colors, gray);
  }
  input[type='range']:focus::-ms-fill-upper {
    background: map-get($colors, gray);
    background: map-get($colors, gray);
  }

  .cut-container {
    top: 18px;
    pointer-events: none;
  }

  .cut {
    width: 2px;
    height: 10px;
    background-color: grey;
    transition: none;
  }

  .complexity-easy {
    color: map-get($colors, gray);
  }

  .complexity-hard {
    color: map-get($colors, gray);
  }
}

input[type='range'] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background-color: transparent;
}
input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;

  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
  border-radius: 1px;
  border: 0px solid #000000;
  border-radius: 50px !important;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  height: 15px;
  width: 15px;
  border-radius: 25px;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-fill-upper {
  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #2497e3;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #a1d0ff;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
}
input[type='range']:focus::-ms-fill-upper {
  background: rgb(47, 228, 14);
  background: linear-gradient(
    90deg,
    rgba(47, 228, 14, 1) 0%,
    rgba(210, 202, 19, 1) 50%,
    rgba(170, 8, 8, 1) 100%
  );
}

.cut-container {
  top: 18px;
  pointer-events: none;
}

.cut {
  width: 2px;
  height: 10px;
  background-color: grey;
  transition: none;
}

.complexity-easy {
  color: #2fe40e;
}

.complexity-hard {
  color: #aa0808;
}
