.student-content-body {
    background-color: #fff;
    padding-top: 50px;
    border-radius: 0;
    height: calc(100vh - 135px);
    overflow: auto;
    padding-bottom: 1.3rem;
 

    & p {
        color: #2E3D49;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 141.69%;
    }

    &__main{
       min-height: 90%
    }

    &__main>*{
        margin-left:12vw;
        margin-right:12vw;
        transition: none !important;
    }

    &__next-btn{
        padding:5px 10px;
        background-color: #246bfd;
        border-radius: 5px;
        color:#fff;
        margin-bottom: 10px;
        margin-left:auto;

       
    }
}

.student-content-body__main{
    &>div{
        background: #181924;
        padding-bottom: 1.2rem;
        padding-top: 1.2rem;
        padding-right:14px;
        margin-top: 2rem;
        }
    
       &>div:first-child{
        background: #181924;
        padding-bottom: 1.2rem;
        margin-top:-3.125rem !important;
        padding-right:14px;
       }  
    
}


@media only screen and (max-width: 768px) {

    .student-content-body__main{
        &>div{
        padding-bottom: 0;
        padding-top: 0;
        padding-right:0;
        margin-top: 2rem;
        }

       &>div:first-child{
        background: #181924;
        padding-bottom: 0;
        margin-top:-3.125rem !important;
        padding-right:0;
       }  

   }
}



@media (min-width:768px) and (max-width:940px) {
    
    .student-content-body{
        &__main>*{
            margin-left:2vw;
            margin-right:2vw;
        }
        &__main{
            &>div{
                padding-bottom: 0  !important;
                padding-top: 0 !important;
                padding-right:0 !important;
                margin-top: 2rem !important;
                }
        
               &>div:first-child{
                background: #181924 !important;
                padding-bottom: 0 !important;
                margin-top:-3.125rem !important;
                padding-right:0 !important;
               }  
        }
    }   

    .content-main-preview__m-0{
        margin-left:0 !important;
        margin-right:0 !important;
    }
    
}

@media (min-width:831px) and (max-width:1100px) {
    .student-content-body{
        & p{
            font-size: 0.875rem;
        }
    }
    }



.content-text>p:has(strong){
        margin-bottom: 0rem;
        margin-top: 2rem;
}

.content-main-preview__next-btn--disabled{
    background-color: grey !important;
    cursor: not-allowed !important;
}
