.content3 {
    grid-area: content3;
    padding-left: 5%;

    &__header{
        font-weight: 600;
        font-size: 20px;
    }

    &__units{
        font-weight: 400;
        font-size: 16px;
        color: #A4A4A4;
        margin-top: -1%;
    }
}
