.unit-dropdown-card {
  height: 77px;
  width: 100%;
  border-bottom: 1px solid #252141;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 14px;

  &__img {
    width: 60px;
    height: 49px;

    img {
      width: 60px;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &__right {
    margin-left: 10px;

    &-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }

    &-instructor {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #a4a4a4;
      margin-top: 2px;
    }
  }
}
