.custom-progress-bar {
  height: 7px;
  width: 70%;
  border-radius: 4px;

  &__line {
    background-color: #246bfd;
    height: inherit;
    border-radius: inherit;
  }
}
