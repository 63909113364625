.unit1-card {
  margin-right: 2%;
  margin-top: 25px;
  cursor: pointer;
  width: 100%;
  height: 137px;
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 15px;

  &__img {
    background-position: center;
    background-size: cover;
    object-fit: cover;
    width: 156px;
    height: 137px;
    border-radius: 15px 0px 0px 15px;
  }

  &__set-space {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  &__name {
    margin-top: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16.1918px;
    line-height: 19px;
    color: #ffffff;
  }

  &__desc {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #979797;
    max-height: 55px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #555555;
    margin-right: 1%;
  }
}

@media (max-width: 1029px) {
  .unit1-card__desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
