.ta-payments-card {
  background-color: #1f2130;
  border-spacing: 10px !important;
  padding: 10px 20px;

  &__name {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 7px;
    padding-left: 20px !important;
  }

  &__payment-amount {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #979797;

    &-value {
      font-family: 'Roboto', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    &-label {
      font-family: 'Roboto', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #979797;
    }
  }

  &--btn-pay {
    background-color: #246bfd;
    border-radius: 4px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-label {
      font-family: 'Roboto', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    &:disabled {
      background-color: #404462;
      cursor: no-drop;
    }
  }
}
