.struct-essay-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .struct-essay-right__title {
    margin-top: 67px;
    color: #73847f;
    text-align: center;
  }

  .struct-essay-right__top {
    height: calc(100% - 299px);
  }

  .struct-essay-right__pdf {
    margin-top: 50px;
    font-size: 12px;
  }

  .struct-essay-right__icon-wrapper {
    background-color: #2c2e41;
    width: 70px;
    height: 70px;
    border-radius: 5px;
    margin-left: 20px;
  }

  .struct-essay-right__paper-name {
    width: 35%;
    overflow: hidden;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .struct-essay-right {
    .struct-essay-right__top {
      height: auto;
    }
  }
}
