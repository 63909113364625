.search-widget{
    display:flex;

    margin-top:20px;

    &__input{
        border-top: 1px solid #73847f;
        border-bottom: 1px solid #73847f;
        border-left: 1px solid #73847f;
        border-right:none;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        background-color: transparent;
        padding:8px 15px;
        font-size: 14px;
        color:#73847f;

        &:focus{
            outline:none;
        }
    }

    &__icon{
        padding:5px 10px;
        background-color: #246bfd;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;

    }
}