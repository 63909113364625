@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.section_main {
    margin-top:-4rem;
    font-family: 'Roboto', sans-serif;
    display: grid;
    background: #181924;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr 0.2fr 2fr;
    grid-template-areas:
        "content1 content2"
        "content3 content4"
        "list list";
    grid-gap: 1rem;
    color: #fff;
    overflow: auto;
    height: 100%;
    margin-right: 5rem;
    /*Hide scrollbar for*/
    /*IE and Edge */
    -ms-overflow-style: none;
    /* Firefox*/
    scrollbar-width: none;

    /*Chrome, Safari and Opera*/
    &::-webkit-scrollbar {
        display: none;
    }
}

@media only screen and (max-width:767.98px) {
    .section_main {

        overflow-x: hidden;
        margin-right: 0rem;
        grid-template-columns: 1fr .5fr;
        grid-template-rows: 1fr .5fr .3fr 2fr;
        grid-template-areas:
            "content1 content1"
            "content2 content2"
            "content3 content4"
            "list list";
    }
}

@media only screen and (max-width:1500px) {
    .section_main {
        grid-template-columns: 1.5fr 1fr;
        grid-template-rows: 1fr 1fr 0.2fr 2fr;
        grid-template-areas:
            "content1 content1"
            "content2 content2"
            "content3 content4"
            "list list";
    }
}