.btn-back {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #246bfd;
  border-radius: 7px;
  background-color: #2c2e41;
  cursor: pointer;
}
.view-notice-wrapper {
  color: white;
  display: flex;
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 8px;
  padding: 22px 35px;

  .left-wrapper {
    flex: 1;
    p {
      max-width: 800px;
      font-weight: 300;
      font-size: 18px;
      color: #b0b0b0;
      padding-right: 50px;
    }
    h2 {
      font-weight: 900;
      font-size: 24px;
    }
    .lw-attachment {
      margin-top: 40px;
      label {
        font-weight: 300;
        font-size: 13px;
        color: #b0b0b0;
      }
    }
    .lw-sendto {
      margin-top: 40px;
      .st-title {
        font-weight: 500;
        font-size: 14px;
        color: #73847f;
      }
      .st-subtitle {
        margin-top: 22px;
        font-weight: 500;
        font-size: 14px;
        color: #73847f;
      }
      .st-value {
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
      }
      
    }
  }
  .rigth-wrapper {
    width: 300px;
    flex-shrink: 0;
    .rw-label {
      .title {
        font-weight: 300;
        font-size: 13px;

        color: #b0b0b0;
      }
      .value {
        font-weight: 300;
        font-size: 13px;
        color: #ffffff;
        margin-left: 10px;
      }
    }
    .rw-attachment {
      margin-top: 40px;
      label {
        font-weight: 300;
        font-size: 13px;
        color: #b0b0b0;
      }
    }
    .rw-annto {
      margin-top: 85px;
      label {
        font-weight: 300;
        font-size: 13px;
        color: #b0b0b0;
      }
      .rw-users {
        font-weight: 300;
        font-size: 13px;
        color: #ffffff;
      }
    }
  }
}

.fi-wrapper {
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .fifn {
    font-weight: 400;
    font-size: 14px;
  }
  .file-icon {
    div {
      width: 59px;
      height: 59px;
      background: #2c2e41;
      border-radius: 6.25455px;
      margin-right: 10px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .close {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #f05a5a;
        font-size: 16px;
        cursor: pointer;
      }
      .micon {
        font-size: 30px;
        color: #474a66;
      }
    }
  }
}

@media (max-width: 767px) {
  .view-notice-wrapper{
    display: block;
  }
}
