.content-card-container {
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;

  .c-icon {
    color: #8c8c8c;
    font-size: 22px;
  }

  .c-right {
    flex: 1;
    padding-left: 20px;

    .c-title-contaner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      position: relative;

      .c-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #73847f;
      }

      .c-delete {
        width: 28px;
        height: 28px;
        background: #f05a5a;
        border-radius: 4px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: 2rem;
        right: 0rem;
      }
    }

    .c-materials {
      .file-list {
        margin-top: 14px;
        display: flex;
        gap: 10px;

        .file-w {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-right: 5px;

          .file-name {
            max-width: 60px;
            text-align: center;
            word-wrap: break-word;
            font-weight: 400;
            font-size: 10px;
            color: white;
          }

          .file-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            div {
              width: 59px;
              height: 59px;
              background: #2c2e41;
              border-radius: 6.25455px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              .close {
                position: absolute;
                top: 5px;
                right: 5px;
                color: #f05a5a;
                font-size: 16px;
                cursor: pointer;
              }

              .micon {
                font-size: 30px;
                color: #474a66;
              }
            }
          }
        }

        .add-new-m {
          padding-left: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: white;
          font-size: 10px;

          &:hover {
            .an-icon {
              background-color: #246bfd;
            }
          }

          .an-icon {
            background-color: #474a66;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #181924;
          }

          .an-text {
            padding-left: 10px;
          }
        }

        .btn-upload {
          display: flex;
          align-items: center;
          padding-left: 10px;

          button {
            background: #494c67;
            border-radius: 6px;
            font-weight: 500;
            font-size: 18px;
            padding: 7px 17px;
            color: white;
          }
        }
      }
    }
  }
}

.content-material-card-container-preview {
  background-color: white;
  padding: 10px;
  padding-top: 0;

  .cm-attach {
    display: flex;

    .file-w {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 5px;
      cursor: pointer;
      padding-right: 10px;

      .file-name {
        text-align: left;
        word-wrap: break-word;
        font-weight: 400;
        font-size: 16px;
        padding-left: 10px;
      }

      .file-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          width: 59px;
          height: 59px;
          background: #2c2e41;
          border-radius: 6.25455px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .close {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #f05a5a;
            font-size: 16px;
            cursor: pointer;
          }

          .micon {
            font-size: 30px;
            color: #474a66;
          }
        }
      }
    }
  }
}
