@import './../styles/variables';

.add-new-user-modal {
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  position: absolute;
  border-radius: 15px;
  outline: none;
  padding: 30px 35px 40px 35px;
  background-color: map-get($colors, primary-blue-light);
  top: 10vh;
  transition: 0.3s;
  width: 40%;
  height: auto;
  color: map-get($colors, pure);
  font-family: 'Roboto';
  font-style: normal;

  .add-new-user-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
  }

  .add-new-user-modal_user-role-input-field {
    margin-top: 8%;
    margin-bottom: 6%;
  }

  .add-new-user-modal_users-input-field {
    margin-bottom: 12%;
  }

  .add-new-user-modal-confirm-button {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
  }

  .add-new-user-modal-button-disabled {
    background-color: map-get($colors, secondary-grey);
  }
}
