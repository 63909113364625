.stuct-essay-result {
    display: flex;
    justify-content: space-between;
  
    &__pdf-view {
      width: 72.5%;
    }
  
    &__pdf-icon {
      display: none;
    }
  
    &__feedback {
      width: 25%;
  
      border-radius: 8px;
      color: #979797;
  
      &-title {
        margin-bottom: 10px;
        margin-top: 40px;
      }
  
      &-body {
        background-color: #1f2130;
        height: 100%;
        padding: 15px 20px;
      }
    }
  }
  
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1116.98px) {
    .stuct-essay-result {
      display: block;
      justify-content: space-between;
  
      &__pdf-view {
        display: none;
      }
  
      &__pdf-icon {
        display: block;
        height: 200px;
        margin: auto;
        background-color: #1f2130;
        position: relative;
        border-radius: 8px;
      }
  
      &__fontawesome-icon-wrapper {
        position: absolute;
        color: grey;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
  
      &__fontawesome-text {
        color: #fff;
      }
  
      &__feedback {
        width: 100%;
      }
    }
  }
  
  .cursor-pointer{
    cursor: pointer;
  }