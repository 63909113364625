.update-content {
    margin-top: 30px;
    height: calc(100vh - 100px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; 
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    &::-webkit-scrollbar{
        display: none;
    }

    &::after{
        content:"\00a0"
    }

    &__btns {
        display: flex;
        &>button{
            height:2.3rem;
        }
    }

    &__save-btn {
        padding: 0 3rem;
        border-radius: 8px;
        color: #fff;
        background-color: #246BFD;
        margin-right: 20px;
        cursor: pointer;
        &--disabled{
            background-color: grey; 
        }
    }

    &__draft-btn {
        padding: 0 4rem;
        border-radius: 8px;
        color: #fff;
        border: 1px solid #246BFD;
        background-color: transparent;
        margin-right: 20px;
        cursor: pointer;
        &--disabled{
            background-color: grey; 
        }
    }

    &__cancel {
        padding-top: 8px;
        margin-left:1rem;
        padding-bottom: 10px;
        border-radius: 8px;
        color: #246BFD;
        cursor: pointer;
        &--disabled{
            background-color: grey; 
        }
    }
}

@media (min-width:768px) and (max-width:990px) {
    .update-content {
        margin-left: 0;
        margin-right: 0;
    }
}
@media (min-width:768px) and (max-width:930px) {
    .update-content{
        &__btns {
            display: block;
            &>*{
                margin-bottom:.5rem;
                text-align: center;
            }
        }
    }

    .update-content__save-btn{
        width:100%;
        margin-bottom:1rem;
    }
    .update-content__draft-btn{
        width:100%;
        margin-bottom:1rem;
    }
    .update-content__btns{
        display: block !important;
        
    }
    .update-content__cancel{
        text-align: center;
    }
}


@media only screen and (max-width: 630px){
    .update-content__save-btn{
        width:100%;
        margin-bottom:1rem;
    }
    .update-content__draft-btn{
        width:100%;
        margin-bottom:1rem;
    }
    .update-content__btns{
        display: block !important;
        
    }
    .update-content__cancel{
        text-align: center;
    }
    }