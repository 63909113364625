.ex-papers-info-card {
  height: 330px;
  background: #1f2130;
  border-radius: 15px;
  padding-right: 40px !important;
  padding-left: 40px !important;
  padding-bottom: 35px;
  padding-top: 35px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 315px !important;
  margin-bottom: 10px;

  .ex-papers-info-card__title {
    width: 88%;
    line-height: 1;
  }

  .ex-papers-info-card__start-btn {
    background: #246bfd;
    width: 100%;
    border-radius: 8px;
    color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 22px;
  }

  .ex-papers-info-card__qustion-nos-time {
    div:first-child {
      text-align: center;
      font-size: 18px;
    }
    div:last-child {
      font-size: 50px;
      line-height: 1;
      text-align: center;
    }
  }

  .ex-papers-info-card__paper-time {
    font-weight: 300;
    color: #8b8b8b;
  }

  .ex-papers-info-card__qtn-label {
    color: #8b8b8b;
  }

  .ex-papers-info-card__tags {
    background: #404462;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 3px;
    font-weight: 400;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 30px;
    min-height: 30px;
    width: 60px;
  }
}

@media (max-width: 767.98px) {
  .ex-papers-info-card {
    min-width: auto !important;
  }
}
