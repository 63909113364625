.common_accessCodePackages-container {
  display: flex;
  gap: 5px;
  flex-direction: row;
  flex-wrap: wrap;

  .accessCodes {
    width: calc(100% - 10px);
    height: 35px;
    background: #1f2130;
    box-shadow: 0px 0px 8px #181924;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 15px;

    &__code {
      transition: none;
      width: 116px;
    }

    &__status {
      flex: 1;
      color: #979797;
    }

    &__expiredate {
      flex: 1;
      text-align: center;
      color: #979797;
    }

    &__availability {
      flex: 1.5;
      text-align: center;
    }

    &__package {
      flex: 2;
      color: #979797;
    }

    &__discount {
      text-align: center;
      width: 200px;
    }

    &__font {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.code-tbl-titles {
  color: #979797;
  margin-top: 45px;
  padding-left: 15px;
  margin-bottom: 10px;
  font-size: 14px;

  div:nth-child(1) {
    width: 116px;
  }

  div:nth-child(2) {
    flex: 1;
  }

  div:nth-child(3) {
    flex: 1;
    text-align: center;
  }

  div:nth-child(4) {
    text-align: center;
    flex: 1.5;
  }

  div:nth-child(5) {
    flex: 2;
  }

  div:nth-child(6) {
    width: 203px;
    text-align: center;
  }
}

.cAccess-code-list{
  height: calc(100vh - 350px) !important;
  overflow: auto;
}

