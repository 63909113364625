@import './../styles/variables';

.cover-imageST {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;

  .add-cover-image-btn-wrap {
    padding-bottom: 7px;
  }

  .add-cover-image-btn {
    background-color: map-get($colors, tertiary-grey);
    color: map-get($colors, pure);
    font-weight: 500;
    border-radius: 5px;
    font-size: 13px;

    padding: 2px 0;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    width: 122px;
    height: 36px;
  }
}
.add-cover-image-btn-disabled {
  background-color: map-get($colors, grey);
}

.uploaded-file{
  height: 60px;
    background: #2c2e41;
    border-radius: 8px;
    padding: 5px;
    width: 50px;
    display: flex;
    justify-content: space-between;
}

.close-icon{
  color:#fff;
  margin-top: -40px;
  margin-left: -5px;
  transform: translate(30px, 5px);
  cursor: pointer;
}

.pdf-icon{
  transform: translate(30px, 5px);
  width: 60px;
  height: 40px;
}
.CircularProgressbar{
  transform: translate(30px, 5px);
&-text {
  transform: translate(-20px, 5px);
}
}

@media (min-width: 768px) {
  .cover-image {
    display: flex;

    .add-cover-image-btn-wrap {
      padding-bottom: 7px;
      padding-left: 3px;
    }
  }
}
