@import './../styles/variables';

.subject-modal-filter {
  width: 550px;
  height: auto;
  z-index: 10;
  top: 40px;
  left: 300px;
  background-color: map-get($colors, primary-blue-light);
  padding: 30px;
  border-radius: 10px;
  color: #fff;

  .fs-popup__grade {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #33364e;
    padding-bottom: 10px;

    .grade {
      color: map-get($colors, grey);
    }

    div:nth-child(1) {
      width: 150px;
    }

    div:nth-child(2) {
      flex-wrap: wrap;
      & > div {
        width: 90px;
        margin-bottom: 10px;
      }
    }
  }

  .fs-popup__year {
    display: flex;
    width: 100%;
    padding-top: 20px;

    .year {
      color: map-get($colors, grey);
    }

    div:nth-child(1) {
      width: 107px;
    }

    div:nth-child(2) {
      flex-wrap: wrap;
      div {
        width: 90px;
      }
    }
  }

  .fq-popup__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    & > div:nth-child(1) {
      color: map-get($colors, secondary-blue);
      cursor: pointer;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      color: map-get($colors, secondary-blue);

      & > div:nth-child(1) {
        margin-right: 20px;
      }
    }

    .apply {
      padding-left: 22px;
      padding-right: 22px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 8px;
      background-color: map-get($colors, secondary-blue);
      color: map-get($colors, pure);
      font-weight: 500;
    }
  }
}
