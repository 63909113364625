.backarrow-button {
  background-color: #2c2e41;
  border: none;
  border-radius: 6.83px;
  width: 30px;
  height: 30px;
  flex-direction: column;
  position: relative;

  .icon {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-55%, -50%);
  }
}
