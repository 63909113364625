@import './../styles/variables';

.datepicker-wrapper {
  background: transparent;
  border-bottom: 1px solid map-get($colors, secondary-grey);
  padding-left: 0;
  input:focus {
    outline: none;
  }

  .datepicker-comp {
    padding-top: 3px;
    padding-bottom: 2px;
    border: none;
    background: transparent;
    color: white;
    font-weight: 500;
    padding-left: 0;
  }

  .datepicker-name {
    font-size: 12px;
    color: map-get($colors, secondary-grey);
    font-weight: 450;
    padding-left: 0;
    line-height: 1;
  }
}

::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}
