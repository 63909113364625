@import './../styles/variables';

.enroll-subject-modal {
  z-index: 100;
  position: absolute;
  top: 10vh;
  left: 10%;
  width: 80%;
  max-width: 1100px;
  height: calc(100% - 20vh);
  color: #fff;
  background-color: #181924;
  border-radius: 8px;
  padding: 55px 45px 65px;
  left: 50%;
  transform: translate(-50%, 0);

  .enroll-subject-modal__header {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      width: 89%;
    }
    & > div:nth-child(2) {
      text-align: right;
      width: 11%;
    }
  }

  .enroll-subject-modal__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
    overflow: auto;
    height: calc(100% - 75px);
  }
}
