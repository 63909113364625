.accessCodePackages-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  .accessCodePackages {
    width: calc(100% / 2 - 10px);
    height: 110px;
    background: #1f2130;
    box-shadow: 0px 0px 8px #181924;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &__image {
      width: 133px;
      min-width: 133px;
      height: 110px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px 0px 0px 8px;
      }
    }
    &__grade {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
      margin-left: 15px;
      width: 67px;
      height: 23px;
      background: #f3b619;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: left;
      margin-left: 22px;
      margin-right: 22px;

      &-title {
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        max-width: calc(100% - 0px);
      }
      &-createdBy {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #a4a4a4;
        margin-top: 2px;
      }

      &-year {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #4f98c0;
        margin-top: 5px;
      }
    }
  }
}
