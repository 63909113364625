@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.content2 {
    background: #1F2130;
    grid-area: content2;
    border-radius: 8px;
    box-shadow: 0px 0px 8px #181924;
    position: relative;
    width: 418px;
    height: 234px;
    margin-left: auto;

    &__image {
        object-fit: cover;
        background-color: #C4C4C4;
        width: 171px;
        height: 160px;
        border-radius: 8px;
        margin: 1rem;
    }

    &__info {
        margin-top: 10%;
        position: absolute;
        top: 0%;
        left: 50%;
    }

    &__pricetag {
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
        transform: translateX(10%);
    }

    &__text {
        font-size: 12px;
        font-weight: 400;
        color: #A4A4A4;
    }

    &__btn-view {
        font-family: 'Roboto', sans-serif;
        background: #246BFD;
        border-radius: 6.90085px;
        width: 185px;
        height: 50px;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: #fff;
        margin-bottom: .5rem;
    }

    &__btn-codes {
        font-family: 'Roboto', sans-serif;
        width: 112px;
        height: 30px;
        background: #404462;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        cursor: not-allowed !important;
    }

    &__btn-stdcodes {
        font-family: 'Roboto', sans-serif;
        width: 180px;
        height: 16px;
        background: #1F2130;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: #3A7BFF;

    }

    &__btn-pop {
        width: 30px;
        height: 30px;
        background: #404462;
        border-radius: 4px;
        color: #fff;
        margin-left: .3rem;
    }

    &__btn-del {
        width: 30px;
        height: 30px;
        background: #F05A5A;
        border-radius: 4px;
        color: #fff;
        margin-left: .3rem;
    }

    &__icon-1 {
        position: relative;
    }

    &__icon-1 svg {
        position: absolute;
        left: 14%;
        top: 13%;
    }

    &__icon-2 svg {
        position: absolute;
        left: 60%;
    }
}


.svg__text {

    &-1 {
        display: inline;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #A4A4A4;
        padding-left: 3rem;
    }

    &-2 {
        display: inline;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #A4A4A4;
        padding-left: 4rem;
    }
}


.flex-container {
    display: flex;
}

.flex-child {
    flex: 1 1 content;
}

.flex-child-2 {
    flex: auto;
    flex-grow: 0;
}


@media only screen and (max-width:767.98px) {
    .content2 {

        margin-left: 3%;
        min-width: 86vw;

        &__info {
            margin-top: 6%;
        }
    }

    .svg__text {

        &-1 {
            padding-left: 4.8rem;
        }

        &-2 {
            padding-left: 6.2rem;
        }
    }
}

@media only screen and (max-width:590px) {
    .svg__text {
        &-1 {
            padding-left: 3rem;
        }

        &-2 {
            padding-left: 4.2rem;
        }
    }
}



@media only screen and (max-width:1500px) {
    .content2 {

        margin-left: 3%;

    }
}