@import './../styles/variables';

.box-icon {
  width: 25px;
  height: 25px;
  background-color: map-get($colors, primary-blue-light);
  position: relative;
  border-radius: 5px;
  cursor: pointer !important;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}
