@import './../styles/variables';

.verify-phone-modal {
  z-index: 100;
  position: fixed;
  top: 40%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  width: 528px * $auth-factor;
  color: map-get($colors, pure);
  background-color: map-get($colors, primary-blue-light);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  justify-content: center;

  .verify-phone-container {
    width: 100%;
  }

  .verify-phone {
    width: 528px * $auth-factor;

    @include theme() {
      background-color: theme-get('authComp-bg');
    }
    padding: (60px * $auth-factor) (45px * $auth-factor) (70px * $auth-factor);

    border-radius: 15px * $auth-factor;

    .verify-phone__title {
      font-size: 40px * $auth-factor;
      color: #fff;
      text-align: center;
    }

    .verify-phone__subtitle {
      color: #fff;
      font-size: 18px * $auth-factor;
      text-align: center;
    }

    .verify-phone__btn {
      margin-top: 65px * $auth-factor !important;
      border-radius: 30px;
      outline: none;
      border: none;
      cursor: pointer;
      display: block;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 25px * $auth-factor;
      background-color: map-get($colors, secondary-blue);
      padding: (12px * $auth-factor) 0;
      font-size: 25px * $auth-factor;
      color: map-get($colors, pure);

    }

    .disabled {
      background-color: map-get($colors, secondary-grey) !important;
      cursor: not-allowed !important;
    }
  }

  .resend-otp {
    color: map-get($colors, secondary-blue);
    cursor: pointer;

  }

  .error-message {
    color: map-get($colors, danger);
    font-size: 20px * $auth-factor;
    text-align: center;
  }

  .otp-input-wrapper {
    & > div {
      width: 100%;
      justify-content: space-between;

      .otp-input {
        input {
          border: none;
          border-bottom: 1px solid map-get($colors, secondary-grey);
          outline: none;
          color: map-get($colors, pure);
          width: 50px !important;
          height: 50px;
          background-color: transparent;
          font-size: 25px;
          padding-bottom: 5px;
        }
      }
    }
  }

  @media (max-width: 1399.98px) {
    .verify-phone {
      .verify-phone-title {
        width: 405px;
      }

      .verify-phone-sub-title {
        width: 405px;
      }
    }
  }

  @media (max-width: 991.98px) {
    .verify-phone {
      .verify-phone-title {
        width: 300px;
      }

      .verify-phone-sub-title {
        width: 300px;
      }
    }
  }

  //mobile
  @media (max-width: 575.98px) {
    .verify-phone {
      .verify-phone-title {
        width: 280px;
      }

      .verify-phone-sub-title {
        width: 280px;
      }
    }
  }

  @media (max-width: 419.98px) {
    .verify-phone {
      .verify-phone-title {
        width: 200px;
      }

      .verify-phone-sub-title {
        width: 200px;
      }
    }
  }
}