@import './../styles/variables';

.q-p-card {
  background-color: #1f2130;
  border-radius: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px !important;
  padding-right: 30px !important;
  cursor: pointer;
  color: #fff;
  margin-top: 10px;

  .q-p-card__main {
    display: flex;
    align-items: center;
    height: 45px;
  }

  .q-p-card__index {
    height: 100%;
    padding-right: 20px;
    border-right: 1px solid #404462;
    display: flex;
    align-items: center;
    align-self: flex-start;
  }

  .q-p-card__question-text {
    padding-left: 20px;
    overflow: hidden;
    height: 100%;
    line-height: 1.2;
  }

  .q-p-card__left-side {
    display: flex;
    margin-left: auto;
    height: 100%;
    align-self: flex-start;
  }

  .q-p-card__complexity {
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid #404462;
    display: flex;
    align-items: center;
  }

  .q-p-card__actions {
    display: flex;
    padding: 0 25px;
    border-left: 1px solid #404462;
    align-items: center;
  }

  .q-p-card__marks {
    border-left: 1px solid #404462;
  }

  .q-p-card__marks-input {
    width: 35px;
    outline: none;
    border: none;
    border-bottom: 1px solid #404462;
    background-color: transparent;
    color: map-get($colors, pure);
    font-size: 26px * $text-input-factor;
    text-align: center;
    padding-bottom: 3px;
    margin-left: 25px;
    padding-top: 15px;
  }

  .q-p-card-open-collapse-answer-no {
    font-weight: 400;
    margin-right: 5px;
    color: map-get($colors, grey);
  }

  .q-p-card-open-collapse-answer-text {
    p {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 400;
    }
  }
}
