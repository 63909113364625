.packageTypes-tabs {
  display: flex;
  margin-top: 25px;

  &__item {
    color: #ffffff;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #1f2130;
    width: 200px;
    text-align: center;
    cursor: pointer;

    &--active {
      background-color: #246bfd;
    }
  }
}
