.tf-answer-card {
  border-radius: 6px;
  background-color: #1f2130;
  padding: 8px 8px 8px 25px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;

  .tf-answer-card__answer {
    margin-left: 25px;
  }
}

.tf-answer-card--selected {
  background-color: #1d2564;
}

@media (max-width: 575.98px) {
  .tf-answer-card {
    padding: 4px 4px 4px 25px;
    .tf-answer-card__not-selected-circle {
      font-size: 14px;
    }

    .tf-answer-card__selected {
      font-size: 14px;
    }

    .tf-answer-card__answer {
      font-size: 13px;
    }
  }
}
