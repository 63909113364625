@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.content4 {
    grid-area: content4;
    position: relative;

}

.btn-expand {
    background: #181924;
    color: #3A7BFF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: none;
    position: absolute;
    top: 30%;
    left: 80%;
}

@media only screen and (max-width:1500px) {
    .btn-expand {
        left: 60%;
    }
}

@media only screen and (max-width:767.98px) {
    .btn-expand {
        left: 50%;
    }
}

@media only screen and (max-width:590px) {
    .btn-expand {
        left: 30%;
    }
}