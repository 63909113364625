@import './../styles/variables';


.paper-exam-breadcrum {
  display: flex;
}

.paper-exam-breadcrum__general,
.paper-exam-breadcrum__creation,
.paper-exam-breadcrum__paper-exam {
  display: flex;
  align-items: center;
}

.paper-exam-breadcrum__general-text,
.paper-exam-breadcrum__creation-text,
.paper-exam-breadcrum__paper-exam-text {
  font-size: 15px;
  font-weight: 400;
  color: map-get($colors, gray);
}

.paper-exam-breadcrum__general-text,
.paper-exam-breadcrum__creation-text {
  padding-left: 8px;
  padding-right: 12px;
}

.paper-exam-breadcrum__paper-exam-text {
  padding-left: 8px;
}
