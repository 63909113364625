@import './../styles/variables';

.user-infor-modal-wrapper {
  inset: 50% auto auto 50%;
  margin-right: -50%;
  transform: translate(-50%, 0);
  position: absolute;
  border-radius: 15px;
  overflow: auto;
  outline: none;
  padding: 27px 35px 40px 35px;
  background-color: map-get($colors, primary-blue-light);
  top: 2vh;
  transition: 0.3s;
}

.user-infor-modal {
  width: 500px;
  min-height: 520px;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 0px;
  color: #fff;

  .user-infor-modal__header {
    display: flex;
  }

  .user-infor-modal__image {
    width: 25%;

    div {
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
  }

  .user-infor-modal__name {
    width: 65%;
    margin-top: 28px;
    padding-left: 20px;
    overflow: hidden;
  }

  .user-infor-modal__firstname {
    font-weight: 300;
    line-height: 1;
    font-size: 20px;
  }

  .user-infor-modal__lastname {
    font-weight: 500;
    font-size: 32px;
    line-height: 1;
  }

  .user-infor-modal__role {
    padding-top: 7px;
  }

  .user-infor-modal__close {
    width: 10%;
    text-align: right;
  }

  //body

  .user-infor-modal__body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    max-height: 264px;
    overflow: auto;
  }

  .user-infor-modal__username,
  .user-infor-modal__email,
  .user-infor-modal__address,
  .user-infor-modal__phone {
    width: 49%;
    overflow: hidden;
  }

  .user-infor-modal__username {
    & > div:nth-child(1) {
      font-size: 13px;
      font-weight: 500;
      color: map-get($colors, grey);
    }

    & > div:nth-child(2) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .user-infor-modal__email {
    & > div:nth-child(1) {
      font-size: 13px;
      font-weight: 500;
      color: map-get($colors, grey);
    }

    & > div:nth-child(2) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .user-infor-modal__address {
    margin-top: 30px;

    & > div:nth-child(1) {
      font-size: 13px;
      font-weight: 500;
      color: map-get($colors, grey);
    }
    & > div:nth-child(2) {
      font-size: 16px;
      font-weight: 500;
      max-height: 96px;
      overflow: hidden;
    }
  }

  .user-infor-modal__phone {
    margin-top: 30px;
    & > div:nth-child(1) {
      font-size: 13px;
      font-weight: 500;
      color: map-get($colors, grey);
    }
    & > div:nth-child(2) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .user-infor-modal__whatsapp-no {
    margin-top: 30px;
    & > div:nth-child(1) {
      font-size: 13px;
      font-weight: 500;
      color: map-get($colors, grey);
    }
    & > div:nth-child(2) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .user-infor-modal__dob {
    margin-top: 30px;
    & > div:nth-child(1) {
      font-size: 13px;
      font-weight: 500;
      color: map-get($colors, grey);
    }
    & > div:nth-child(2) {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .user-infor-modal__margin {
    width: 2%;
  }

  .user-infor-modal__whatsapp-no {
    width: 100%;
  }

  .user-infor-modal__dob {
    width: 100%;
  }

  //footer
  .user-infor-modal__footer {
    margin-top: 43px;
  }
}
