.enroll-subject-card {
  background: #1f2130;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 8px;
  width: 305px;
  height: 320px;

  &__image {
    width: 305px;
    height: 152px;
    position: relative;
    background-position: center;
    background-size: cover;
    border-radius: 8px 8px 0px 0px;

    img {
      width: 100%;
      border-radius: 8px 8px 0px 0px;
      height: 100%;
      object-fit: cover;
    }

    &-grade {
      position: absolute;
      width: 67px;
      height: 23px;
      background: #f3b619;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 120px;
      left: 15px;

      &-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #ffffff;
      }
    }
  }

  &__content {
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 110px;
    min-height: 110px;
    max-height: 110px;

    &-name {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      word-wrap: break-word;
      color: #ffffff;
    }

    &-year {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #4f98c0;
      margin-top: 5px;
    }

    &-author {
      display: flex;
      align-items: center;
      margin-top: 10px;
      width: 100%;

      &-image {
        display: flex;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      &-name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #979797;
      }
    }
  }

  &__enroll-btn {
    width: 259px;
    height: 30px;
    transform: translate(22.5px, 7px);

    button {
      width: 100%;
      height: 100%;
      background: #246bfd;
      border-radius: 6px;
      border: none;
      color: #ffffff;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
}

@media (max-width: 520px) {
  .enroll-subject-card {
    width: calc(100% - 20px);
    height: 320px;

    &__image {
      width: 100%;
      height: 152px;
    }

    &__content {
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 110px;
      min-height: 110px;
      max-height: 110px;

      &-name {
        font-size: 14px;
      }
    }

    &__enroll-btn {
      width: calc(100% - 45px);
      height: 30px;
      transform: translate(22.5px, 7px);
    }
  }
}
