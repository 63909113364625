@import './../styles/variables';

.side-nav-item {
  position: relative;

  .side-nav-item__icon {
    width: 45px;
    height: 45px;
    background-color: map-get($colors, primary-blue-light);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    cursor: pointer;
    .count-label {
      color: white;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 7px;
      right: 40px;
      min-width: 15px;
      height: 15px;
      background-color: #e25a5a;
      border-radius: 50%;
      font-size: 8px;
      text-align: center;
      line-height: 15px;
      font-weight: 400;
    }
  }

  .side-nav-item__name {
    color: #fff;
    line-height: 1;
    font-size: 13px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;
  }

  .active {
    background-color: map-get($colors, secondary-blue);
  }
}
