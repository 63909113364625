.column-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-radius: 5px;
  transition: none;

  .column-container__list {
    // background-color: #fff;
    overflow-y: auto;
    flex-grow: 1;
    height: 400px;
  }

  .column-container__list--empty {
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #ccc;
  }
}

.dashed-bg{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
