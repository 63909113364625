.instructor-dashboard {
    display: flex;
    padding: 15px 0;
    margin-top: 10px;
  
    overflow: auto;
    height: calc(100vh - 165px);
  
    &__graph {
      width: auto !important;
    }
  
    &__right {
      width: 58%;
    }
  
    &__left {
      width: 40%;
      margin-left: auto;
      padding-right: 25px;
    }
  
    &__papers {
      background: #1f2130;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
      border-radius: 6px;
    }
  }