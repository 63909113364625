.content-snav {


    &__header {
        display: flex;
        padding-left: 40px;
        padding-top: 10px;
        color: #fff;
        align-items: center;

        h6 {
            padding-left: 10px;
            margin: 0;
        }
    }

    &__items {
        margin-top: 20px;
    }

    &__item {
        color: #fff;
        text-align: left;
        cursor: pointer;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 30px;
        margin: 0;


        &--active {
            background-color: #246bfd;
        }
    }

    &__sublist {
        background-color: transparent;
        text-align: left;
        padding-left: 20px;



        &-item {
            color: #979797;
            font-size: 14px;
            background-color: transparent;
            padding: 5px;
            padding-left: 10px;
            list-style: none;

            &--active {
                color: #246bfd
            }

            &:first-child {
                margin-top: 15px;
            }
        }

    }
}