
.stu-lessons-title{
    color: #979797;
    font-family: 'Roboto';
    font-size: 18px;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}
.stu-lessons-content{
    padding-right:.2rem !important;

 
    
}
.stu-lessons-card{

    height:200px;
    width: 180px;
    background-color: #1f2130;
    border-radius: 8px;
    margin-bottom: 1rem;
    &-img{
        width:180px;
        height:140px;
        object-fit:cover;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        
    }

    &-title{
        color:#fff;
        font-size: 14px;
        margin-left:1rem;
        font-size: 14px;
        margin-top:1rem;
    }

}

.stu-units{

&-card{
    display: flex;
    background: #1F2130;
    max-width: auto;
    height: 137px;
    background: #1F2130;
    box-shadow: 0px 0px 8px #181924;
    border-radius: 15px;
    margin:auto;
    margin-right:5%;

&-title{
    color:#fff;
    font-size: 14px;

}

&-img{
    width: 156px;
height: 137px;
border-radius: 15px 0px 0px 15px;
object-fit:cover;
}
}

&-content{
    margin-left: 2rem;
    margin-top: 1.5rem;

    &-title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color:#fff
    }

    &-text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        color: #979797;
        line-height: 14px;
    }

    &-progressbar{
        height: 4px !important;
        width: 150px !important;
        background-color: #545454 !important;
        margin-top: 7px;
        &-text{
        font-family: 'Roboto';
        font-size: 12px;
        color: #C6C6C6;
        display: inline-block;
        margin-left:1rem
        }

        &-container{
            display: flex;
            margin-bottom: .5rem;
        }
    }
}
}



@media (max-width: 600px) {
.stu-units-content{
margin-left: 1rem;
margin-top: 1rem;
}
}

@media (max-width: 1366px) {
    .stu-lessons-content{
        padding-right:1rem !important;
    }

    .stu-lessons-card{
        margin-bottom: 1.7rem !important;
    }
}

@media (max-width: 768px) {
    .stu-lessons-content{
        padding-right:.2rem !important;
        // width:100%;
    }

    .stu-lessons-card{
        margin-bottom: 1rem !important;
        width:100%;
        

        &-img{
            width:100%;
            
        }
    }


}