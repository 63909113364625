.column-container-mcq-paper-questions-search {
  border-radius: 2px;
  width: 100%;

  display: flex;
  flex-direction: column;

  .column-container__list-mcq-paper-questions-search {
    // background-color: #fff;
    flex-grow: 1;
    min-height: calc(100vh - 310px);
  }
}
