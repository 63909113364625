@import './../styles/variables';
.paper-header {
  position: fixed;
  background: #181924;
  width: calc(100vw - 405px);
  height: 75px;
  z-index: 10;
  top: 0px;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: none;

  & > div:nth-child(1) {
    color: map-get($colors, pure);
  }
}

#blackarrow {
  background-color: map-get($colors, primary-blue-three);
  width: 30px;
  height: 30px;
  border-radius: 6.82894px;
  align-items: center;
  display: block;
  border: none;
  margin-left: -15%;
}

.newpackage {
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}