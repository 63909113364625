.reaport-wrapper {
  color: rgb(255, 255, 255);
  margin-top: 65px;

  .s2 {
    margin-top: 40px;

    .cn-label {
      color: #979797;
    }

    .cn-input {
      color: white;
      font-weight: 500;
      font-size: 20px;
      width: 100%;
      background-color: #181924;
      border: none;
      border-bottom: 1px solid #6a6878;
      outline: none;
    }
    .input-error {
      border-bottom: 1px solid #ff5e00;
    }
  }
  .grade-subject {
    display: flex;

    .s3 {
      margin-top: 40px;
      width: 50%;
      .cn-input {
        color: white;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        background-color: #181924;
        border: none;
        border-bottom: 1px solid #6a6878;
        outline: none;
      }
      .input-error {
        border-bottom: 1px solid #ff5e00;
      }
    }
  }
  .timeframes {
    display: flex;
    .s4 {
      margin-top: 40px;
      width: 33.33%;
      .cn-input {
        color: white;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        background-color: #181924;
        border: none;
        border-bottom: 1px solid #6a6878;
        outline: none;
      }
      .input-error {
        border-bottom: 1px solid #ff5e00;
      }
    }
  }
  .f-button {
    height: 40px;
    margin-top: 20px;
    background-color: #246bfd;
    color: white;
    width: 190px;
    text-align: center;
    padding: 0 22px;
    border-radius: 8px;
    &:hover {
      background-color: #2e74ff;
    }
  }
  .f-button-d {
    height: 40px;
    margin-top: 20px;
    width: 190px;
    text-align: center;
    background-color: #535f77;
    color: rgb(255, 255, 255);
    padding: 0 22px;
    border-radius: 8px;
  
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

