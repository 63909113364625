.score-title {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #fff;
  background-color: #292c3f;
  border-radius: 8px;

  & > div:last-child {
    font-weight: 300;
    color: #246bfd;
    margin-left: 5px;
  }
}
