@import './../styles/variables';

.q-card {
  background-color: #1f2130;
  border-radius: 10px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 30px !important;
  cursor: pointer;
  width: flex;
  margin-right: 10px;
}

.question-type {
  height: 45px;
  border-right: #404462 1px solid;
  padding-right: 35px;

  span {
    font-family: Roboto;
    font-weight: 300;
    font-size: 16px;
    background-color: #404462;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 3px;
    line-height: 1;
    align-self: center;
    height: 27px;
    display: flex;
    align-items: center;
  }
}

.question-complexity {
  border-left: #404462 1px solid;
  min-width: 155px;
  height: 45px;

  & > div {
    display: flex;
    width: 85px;
    justify-content: space-between;
  }
}

.question-text {
  font-weight: 500;
  font-size: 15px;
  flex: 1;
  padding-left: 40px;
  line-height: 1.2;
  padding-top: 3px;
  overflow: hidden;
}

.question-text-highlight {
  background-color: yellow;
}

//collapse styles

.open-collapse-answer-text {
  p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
  }
}

.open-collapse-answer-no {
  font-weight: 400;
  margin-right: 5px;
  color: map-get($colors, grey);
}
