.stu-exam-prev {
    margin-top: 40px;
    font-size: 14px;
    // height: 100%;
  
    &__title,
    &__description {
      border-radius: 8px;
      padding: 15px 20px;
      background-color: #1f2130;
      &-text {
        color: #738474;
      }
      &-value {
        color: #fff;
      }
    }
  
    &__description {
      margin-top: 10px;
    }
  
    &__time {
      border-radius: 8px;
      margin-top: 10px;
      padding: 15px 20px;
      background-color: #1f2130;
      display: flex;
  
      &-allocated-time {
        width: 20%;
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
  
      &-start-date {
        width: 20%;
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
  
      &-start-time {
        width: 20%;
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
      &-end-date {
        width: 20%;
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
  
      &-end-time {
        width: 20%;
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
    }
  
    &__papers-title {
      margin-top: 10px;
      color: #738474;
    }
  
    &__papers-add-payment {
      color: #246bfd;
      cursor: pointer;
    }
  
    &__papers {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  
    &__sub-info {
      padding: 15px 20px;
      background-color: #1f2130;
      border-radius: 8px;
      display: flex;
      margin-top: 10px;
  
      &-upfiles {
        width: 33.33%;
  
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
  
      &-subfiles {
        width: 33.33%;
  
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
  
      &-filetype {
        width: 33.33%;
  
        &-title {
          color: #738474;
        }
  
        &-value {
          color: #fff;
        }
      }
    }
  
    .stu-exam-prev__other-info {
      padding: 15px 20px;
      background-color: #1f2130;
      margin-top: 10px;
      border-radius: 8px;
  
      &-top,
      &-bottom {
        display: flex;
        width: 100%;
      }
  
      &-bottom {
        margin-top: 20px;
      }
  
      &-gradetype {
        width: 33.33%;
        &-title {
          color: #738474;
        }
        &-value {
          color: #fff;
        }
      }
  
      &-feedback {
        width: 33.33%;
        &-title {
          color: #738474;
        }
        &-value {
          color: #fff;
        }
      }
  
      &-price {
        width: 33.33%;
        &-title {
          color: #738474;
        }
        &-value {
          color: #fff;
        }
      }
  
      &-bottom-fullmarks {
        width: 30%;
        &-title {
          color: #738474;
        }
        &-value {
          color: #fff;
        }
      }
  
      &-bottom-fullmarks-content {
        display: flex;
        & > div {
          width: 50%;
          color: #fff;
        }
      }
  
      &-bottom-notifications {
        width: 70%;
      }
    }
  
    &__marks-author {
      display: flex;
      padding: 15px 20px;
      background-color: #1f2130;
      border-radius: 8px;
      margin-top: 10px;
    }
  
    &__marks {
      width: 50%;
      &-title {
        color: #738474;
      }
      &-value {
        color: #fff;
      }
    }
  
    &__author {
      width: 50%;
      &-title {
        color: #738474;
      }
      &-value {
        color: #fff;
      }
    }
  }
  