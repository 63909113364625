@import './../styles/variables';

.ModelExamGradingPreview-Container {
  height: 100%;
  background: #181924;
  box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: none;
  padding-right: 20px;

  height: calc(100vh - 50px);
  max-width: calc(100vw - 40px);

  // -----------Starting Header ----------

  .ModelExamGradingPreview-Header {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;

    .ModelExamGradingPreview-Header-Title {
      width: 400px;
      height: 19px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;

      .ModelExamGradingPreview-Header-Title-Text {
        margin-top: 5px;
        width: 200px;
        height: 19px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #73847f;
      }
    }
    .ModelExamGradingPreview-StudentName-Section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .ModelExamGradingPreview-StudentName-Section-Previous-Icon {
        margin-top: -10px;
        width: 16px;
        height: 16px;
        color: #246bfd;
        border-radius: 1px;
        cursor: pointer;
      }
      .ModelExamGradingPreview-StudentName-Section-Title {
        text-align: center;
        margin: 0px 20px;
        width: 200px;
        height: fit-content;
        max-height: 70px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        span {
          margin-top: 5px;
          width: 184px;
          height: 19px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #73847f;
        }
      }
      .ModelExamGradingPreview-StudentName-Section-Next-Icon {
        margin-top: -10px;
        width: 16px;
        height: 16px;
        color: #246bfd;
        border-radius: 1px;
        cursor: pointer;
      }
    }
    .Students-Grade-preview-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: fit-content;

      height: 54px;
      background: #292c3f;
      box-shadow: 0px 0px 9.46941px #181924;
      border-radius: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 23px;

      color: #ffffff;
    }
    .ModelExamGradingPreview-Header-Close {
      margin-top: -10px;
    }
  }

  // ---------End Of Header-----------

  .ModelExamGradingPreview-Body {
    display: flex;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    height: 90%;
    padding-bottom: calc(100% - 98%);

    // --------For MCQ and TF--------------
    .MCQ-TF-Grading-Body {
      height: 90%;
      width: 100%;
      border: 1px solid #6a6878;
      box-sizing: border-box;
      border-radius: 12px;

      .MCQ-TF-Grading-Body-content {
        padding: 35px;
        height: 98%;
        overflow-y: auto !important;
      }
    }
  }
}

.stru-ess-marking {
  width: 98%;
  height: 85%;
  margin-top: 3rem;
  margin-left: 50px;

  &__progress-bar {
    width: 70%;
  }

  &__body {
    height: 89%;
    display: flex;
  }

  &__upload-area {
    height: 100%;
    position: relative;
    width: 70%;
    border-radius: 8px;
    border: 1px solid grey;
  }

  &__marks-and-comments {
    height: 100%;
    width: 30%;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__delete-btn {
    background-color: red;
    color: #fff;
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
  }

  &__marks-label {
    color: grey;
    font-size: 18px;
    text-align: left;
    margin-top: 20px;
  }

  &__marks-input {
    width: 100%;
    border: none;
    background-color: transparent;
    color: red;
  }

  &__score {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    display: inline-block;
    width: 50%;
    font-size: 25px;
    color: white;
    // padding-left: 40%;
    text-align: right;

    &--disabled {
      color: #73847f;
    }

    &:focus {
      outline: none;
    }
  }

  &__marks {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    display: inline-block;
    width: 50%;
    font-size: 25px;
    color: gray;
    padding-left: 5px;

    &:focus {
      outline: none;
    }
  }

  &__download-label {
    color: grey;
    font-size: 20px;
    text-align: left;
    margin-bottom: 20px;
    line-height: 1;
  }

  &__comments-label {
    color: grey;
    font-size: 20px;
    text-align: left;
    margin-top: 40px;
  }

  &__tinymce {
    background-color: transparent;
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 323px);
    color: white;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid grey;

    &:focus {
      outline: none;
    }
  }

  &__btn {
    background-color: #246bfd;

    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &-text {
      font-size: 15px;
      color: #fff;
      cursor: pointer;
    }
  }

  &__papers {
    display: flex;
    justify-content: space-between;
  }

  &__footer {
    margin-top: 20px;
  }

  &__button-wrapper {
    display: flex;
  }

  &__up-btn-wrapper {
    height: 100%;
  }

  &__up-btn-core {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 150px;
    width: 150px;
  }

  &__up-input {
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: transparent;
    z-index: 2;
    position: absolute;
    border-radius: 50%;
  }

  &__up-btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    border-radius: 8px;
    padding: 10px 10px;
    background-color: #246bfd;
    color: #fff;
    border-radius: 50%;
    height: 100%;
    font-size: 13px;
  }

  &__save-btn {
    background-color: #246bfd;
    padding: 10px 50px;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 20px;

    &--disabled {
      background-color: grey;
      cursor: default;
    }
  }

  &__release-btn {
    background-color: transparent;
    border: 2px solid #246bfd;
    padding: 10px 50px;
    color: #fff;
    border-radius: 10px;
    margin-right: 40px;

    &--disabled {
      background-color: grey;
      cursor: default;
    }
  }

  &__reset-btn {
    padding-top: 10px;
    padding-right: 10px;
    color: #246bfd;
    border-radius: 10px;
    cursor: pointer;
    display: inline;

    &--disabled {
      color: grey;
      cursor: default;
    }
  }
}

@keyframes moveToRight {
  0% {
    transform: translate(-50%, -50%);
  }

  50% {
    transform: translate(-50%, -60%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.model-exam-grading-preivew__footer {
  &--taapproved {
    display: flex;
    justify-content: right;
    margin-top: 10px;

    .approve {
      padding: 10px;
      background-color: green;
      color: #fff;
      width: 175px !important;
      border-radius: 8px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &--tarejected {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .save {
      padding: 10px;
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #246bfd;
      color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }

    .reset {
      padding: 10px;
      background-color: transparent;
      color: #246bfd;
      cursor: pointer;
    }

    .rejected {
      padding: 10px;
      background-color: red;
      opacity: 0.8;
      color: #fff;
      width: 175px !important;
      border-radius: 8px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &--tapending {
    display: flex;
    margin-top: 10px;
    justify-content: right;
    .pending {
      padding: 10px;
      background-color: yellow;
      color: #000;
      border-radius: 8px;
      margin-right: 20px;
    }
  }

  &--notmarked {
    margin-top: 15px;
    display: flex;

    .save {
      padding: 10px;
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #246bfd;
      color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }

    .reset {
      padding: 10px;
      background-color: transparent;
      color: #246bfd;
      cursor: pointer;
    }
  }

  &--adinapproved {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .release {
      padding: 10px;
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #246bfd;
      background-color: transparent;
      color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }
    .approve {
      padding: 10px;
      background-color: green;
      color: #fff;
      width: 175px !important;
      border-radius: 8px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &--adinrejected {
    display: flex;
    margin-top: 10px;
    justify-content: right;
    .rejected {
      padding: 10px;
      background-color: red;
      opacity: 0.8;
      color: #fff;
      width: 175px !important;
      border-radius: 8px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &--adinpending {
    display: flex;
    justify-content: right;
    margin-top: 15px;

    .reject {
      padding: 10px;
      background-color: red;
      color: #fff;
      width: 120px !important;
      border-radius: 8px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .approve {
      padding: 10px;
      background-color: #246bfd;
      color: #fff;
      width: 120px !important;
      border-radius: 8px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &--adinreleased {
    display: flex;
    margin-top: 15px;
    .save {
      padding: 10px;
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #246bfd;
      color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }

    .unrelease {
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #246bfd;
      color: #fff;
      border-radius: 8px;
      cursor: pointer;
    }

    .reset {
      padding: 10px;
      background-color: transparent;
      color: #246bfd;
      cursor: pointer;
    }
  }

  &--adinnotassigned {
    display: flex;
    margin-top: 15px;

    .save {
      padding: 10px;
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #246bfd;
      color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }

    .release {
      padding: 10px;
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #246bfd;
      background-color: transparent;
      color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }

    .reset {
      padding: 10px;
      color: #246bfd;
      cursor: pointer;
    }
  }

  &--adinreleasedassigned {
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;

    .save {
      padding: 10px;
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #246bfd;
      color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      cursor: pointer;
    }

    .approve {
      padding: 10px;
      background-color: green;
      color: #fff;
      width: 175px !important;
      border-radius: 8px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .unrelease {
      width: 150px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #246bfd;
      color: #fff;
      border-radius: 8px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.cursor-pointer-megp {
  cursor: pointer;
}
