@import './../styles/variables';


.textarea {
  width: 100%;
  position: relative;
  height: auto;
  padding-top: 10px;

  .textarea__elmt {
    resize: none;
    width: 100%;
    color: map-get($colors, pure);
    padding-top: 14px;
    border: none;
    border-bottom: 1px solid map-get($colors, secondary-grey);
    line-height: 1.1;
    outline: none;
    background-color: transparent;
    font-size: 26px * $auth-factor;
    padding-bottom: 5px;
    padding-left: 0;
    max-height: 200px;
    overflow: auto;
  }

  .textarea__label .textarea__content {
    position: absolute;
    left: 0;
    bottom: 11px;
    transition: all 0.3s ease;
    color: map-get($colors, secondary-grey);
    pointer-events: none;
    font-size: 26px * $auth-factor;
  }

  .textarea__label {
    margin: 0 !important;
  }

  .textarea__elmt:focus + .textarea__label .textarea__content,
  .textarea__elmt:valid + .textarea__label .textarea__content {
    transform: translateY(-130%);
    font-size: 18px * $auth-factor;
    transition: all 0.5s ease;
  }
}
