@import './../styles/variables';

.stu__leaderboard {
  background-color: #1b1c28;
  width: 560px * $stu-leaderboard-card;
  padding: 15px * $stu-leaderboard-card 20px * $stu-leaderboard-card;
  position: relative;
  border-radius: 15px;

  @media (max-width: 590.98px) {
    width: auto;
  }

  &__heading {
    color: #fff;
    font-weight: 500;
    line-height: 16px;
    font-size: 16px * $stu-leaderboard-card;
    margin-bottom: 15px * $stu-leaderboard-card;
  }
}
