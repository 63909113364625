@import './../styles/variables';

.reset-password_description {
  height: 84px;
  width: 700px;
  left: auto;
  font-weight: bold;
  color: white;
  font-size: 23px;
  text-align: center;
  span {
    color: map-get($colors, secondary-blue);
  }
}

.reset-password_sub_description {
  width: 456px;
  height: 28px;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  margin-top: 30px;
  span {
    color: map-get($colors, secondary-blue);
  }
}

@media (max-width: 1399.98px) {
  .reset-password_description {
    width: 405px;
  }
  .reset-password_sub_description {
    width: 405px;
  }
}

@media (max-width: 991.98px) {
  .reset-password_description {
    margin-top: 100px; //here
    width: 300px;
  }
  .reset-password_sub_description {
    width: 300px;
    margin-top: 100px; //here
  }
}

//mobile
@media (max-width: 575.98px) {
  .reset-password_description {
    width: 280px;
  }
  .reset-password_sub_description {
    width: 280px;
    // margin-top: 290px;
  }
}

@media (max-width: 419.98px) {
  .reset-password_description {
    width: 200px;
  }
  .reset-password_sub_description {
    width: 200px;
    // margin-top: 250px;
  }
}
