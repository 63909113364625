.navigator-right-panel {
  .navigator-right-panel__counter {
    text-align: center;
    color: #fff;
    font-size: 25px;
    span {
      color: #979797;
      margin-right: 3px;
    }
  }

  .navigator-right-panel__text {
    color: #979797;
    font-size: 17px;
    text-align: center;
    margin-top: 25px;
  }

  .navigator-right-panel__labels {
    display: flex;
    color: #979797;
    font-size: 12px;
    justify-content: space-between;
    padding: 0 36px;
    margin-top: 35px;
  }

  .navigator-right-panel__answered {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      background-color: blue;
      border-radius: 2px;
    }
  }

  .navigator-right-panel__not-answered {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      background-color: #979797;
      border-radius: 2px;
    }
  }

  .question-navigator-btn--not-seen {
    background-color: #292c3f;
  }

  .navigator-right-panel__answer-grid {
    padding-left: 18px;
    padding-right: 6px;
    margin-top: 20px;
    display: flex;
    gap: 17px 18px;
    align-content: flex-start;
    flex-wrap: wrap;
    height: calc(100vh - 425px);
    overflow-y: auto;
  }
}

// // Medium devices (tablets, less than 992px)
// @media (max-width: 991.98px) {
//   .navigator-right-panel {
//     .navigator-right-panel__answer-grid {
//       height: auto;
//       padding-bottom: 20px;
//     }
//   }
// }
