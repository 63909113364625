@import './../styles/variables';

.filter-popup {
  width: 550px;
  height: auto;
  z-index: 10;
  top: 0px;
  left: 120px;
  background-color: map-get($colors, primary-blue-light);
  padding: 30px;
  border-radius: 10px;
  .fq-popup__grade {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #33364e;
    padding-bottom: 20px;

    .grade {
      color: map-get($colors, grey) !important;
    }

    div:nth-child(1) {
      color: map-get($colors, pure);
      width: 150px;
      margin-bottom: 10px;
    }

    div:nth-child(2) {
      flex-wrap: wrap;
      div {
        width: 90px;
      }
    }
  }
  .filter-group2 {
    display: flex;
    .filter-subject {
      width: 50%;
      padding-right: 10px;
      .flabel {
        font-size: 14px;
        color: #979797;
      }
      select {
        width: 100%;
        color: #fff;
        background-color: #1f2130;
        border: none;
        border-bottom: 1px solid #6a6878;
      }
    }

    .filter-status {
      padding-left: 10px;
      width: 50%;
      .flabel {
        font-size: 14px;
        color: #979797;
      }
      select {
        width: 100%;
        color: #fff;
        background-color: #1f2130;
        border: none;
        border-bottom: 1px solid #6a6878;
      }
    }
  }
  .fq-popup__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    & > div:nth-child(1) {
      color: map-get($colors, secondary-blue);
      cursor: pointer;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      color: map-get($colors, secondary-blue);

      & > div:nth-child(1) {
        margin-right: 20px;
      }
    }

    .apply {
      padding-left: 22px;
      padding-right: 22px;
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 8px;
      background-color: map-get($colors, secondary-blue);
      color: map-get($colors, pure);
      font-weight: 500;
    }
  }
}

@media (max-width: 1190px) {
  .filter-popup {
    top: 50px;
    left: -308px;
  }
}
@media (max-width: 767px) {
  .filter-popup {
    top: 50px;
    left: -308px;
    width: 400px;
  }
}
@media (max-width: 502px) {
  .filter-popup {
    top: 50px;
    left: 0px;
    width: 350px;

    .flabel {
      font-size: 8px;
      color: #979797;
    }
  }
}
