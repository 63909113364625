@import './../styles/variables';

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  list-style-type: none !important;
  margin-bottom: 0;
  padding-top: 20px;
  padding-left: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }
}

.pagination-container li {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 30px;
  width: 30px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  background-color: map-get($colors, primary-blue-three);
  color: map-get($colors, grey);
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.pagination-container li.active {
  background-color: map-get($colors, secondary-blue);
  color: map-get($colors, pure);
}

.pagination-container li.disabled {
  background-color: map-get($colors, grey);
}
.pagination-container li:hover:not(.active) {
  background-color: map-get($colors, pure);
}
