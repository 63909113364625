.create-label-modal {
  left: calc(50% - 320px) !important;
  width: 550px;
  z-index: 100;
  position: absolute;
  top: 35vh;
  left: 10%;
  color: #fff;
  background-color: #1f2130;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  padding: 25px 50px 25px 50px;
  align-items: center;

  &__input {
    width: 70%;
    border-radius: 5px;
    border: none;
    padding-left: 10px;

    &:focus {
      outline: none;
    }
  }

  &__btn {
    background-color: #246bfd;
    border-radius: 5px;
    color: #fff;
    line-height: 1;
    padding: 10px;
  }
}
