@import './../styles/variables';

.tab {
  text-align: left;
  padding: 8px 0px;
  padding-left: 28px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  display: block;
  font-weight: 400;
}

.sub-tab {
  text-align: left;
  padding: 6px 0px;
  padding-left: 50px;
  color: map-get($colors, grey);
  font-size: 15px;
  cursor: pointer;
  font-weight: 400;
  line-height: 1;
}

.active-tab {
  background-color: map-get($colors, secondary-blue);
}

.active-sub-tab {
  color: map-get($colors, secondary-blue);
}

.animate-menu-arrow {
  transform: rotate(90deg);
}
