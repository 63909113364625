.lesson-list-1{

    margin-top: 40px;

    &__title{
        color: #73847f;
    }

    &__list{
        display:flex;
        flex-wrap: wrap;

    }
  
}