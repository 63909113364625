@import './../styles/variables';

.user-card-table-header {
  margin-top: 15px;
  justify-content: space-between;
  margin-bottom: 10px;

  div {
    font-size: 13px;
  }

  & > div:nth-child(1) {
    color: map-get($colors, grey);
    display: flex;
    width: 550px;
    padding-left: 30px;

    & > div:nth-child(1) {
      width: 34%;
    }
    & > div:nth-child(2) {
      width: 2%;
    }

    & > div:nth-child(3) {
      width: 34%;
    }

    & > div:nth-child(4) {
      padding-left: 30px;
      width: 30%;
    }
  }

  & > div:nth-child(2) {
    width: 80px;
    color: map-get($colors, grey);
    text-align: left;
    padding-left: 5px;
  }
}

.user-list {
  height: calc(100vh - 320px) !important;
  overflow: auto;
}
