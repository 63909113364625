.label-tag {
  padding: 5px;
  display: flex;
  background-color: #246bfd;
  border-radius: 5px;
  font-size: 12px !important;
  line-height: normal !important;
  top:0 !important;

  &__name {
    color: #fff;
  }

  &__icon {
    margin-left: 5px;
    cursor: pointer;
  }
}
