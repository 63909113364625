.primary-nav-mobile {
  background-color: transparent;
  width: 100px;
  height: calc(100% - 30px);
  flex-direction: column;
  padding-top: 35px;
}

.mobile-nav__secondary {
  width: calc(100% - 100px);
  & > div {
    width: 90%;
    margin-left: auto;
  }
}
