@import './../styles/variables';

.answer-card-mcq {
  padding: 20px 30px;
  background-color: map-get($colors, primary-blue-light);
  border-radius: 12px;
  display: flex;

  & > div {
    display: flex;
    & > div:nth-child(1) {
      margin-right: 10px;
      color: map-get($colors, grey);
    }

    & > div:nth-child(2) {
      color: map-get($colors, pure);
    }
  }
}

.answer-card-tf {
  padding: 20px 30px;
  display: flex;
  background-color: map-get($colors, primary-blue-light);
  border-radius: 12px;

  & > div:nth-child(1) {
    margin-right: 10px;
    color: map-get($colors, grey);
  }

  & > div:nth-child(2) {
    color: map-get($colors, pure);
  }
}
