.grades-tab-marks {
  margin-top: 15px;
  max-width: fit-content;
  min-width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
  height: calc(100vh - 280px);

  &__header {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #979797;
    font-weight: 300;
    font-size: 12px;

    &-student_index {
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 30px;
      width: 35px;
      max-width: 35px;
      min-width: 35px;
    }
    &-students {
      max-width: 250px;
      width: 250px;
      min-width: 250px;
      display: flex;
      align-items: center;
    }

    &-paper-name {
      max-width: 150px;
      width: 150px;
      min-width: 150px;
      display: flex;
      align-items: center;
      margin: 0px 10px;
      justify-content: center;

      span {
        word-wrap: break-word;
        word-break: break-all;
      }
    }
    &-rank {
      max-width: 50px;
      width: 50px;
      min-width: 50px;
      display: flex;
      align-items: center;
      margin: 0px 10px;
      justify-content: center;
    }
    &-total-marks {
      max-width: 100px;
      width: 100px;
      min-width: 100px;
      display: flex;
      align-items: center;
      margin: 0px 10px;
      justify-content: center;
    }
  }

  &__body {
    width: fit-content;
    min-width: 100%;
    margin-top: 10px;
  }

  &__body__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 33px;
    align-items: center;
    color: white;
    background: #1f2130;
    box-shadow: 0px 0px 8.89855px #181924;
    border-radius: 4px;
    font-size: 12px;

    &__student_index {
      display: flex;
      align-items: center;
      padding-left: 15px;
      padding-right: 25px;
      width: 30px;
      max-width: 30px;
      min-width: 30px;
      color: #979797;
    }

    &__student-name {
      max-width: 250px;
      width: 250px;
      min-width: 250px;
      display: flex;
      align-items: center;
      padding-left: 5px;
      cursor: pointer;
    }

    &__marks {
      max-width: 150px;
      width: 150px;
      min-width: 150px;
      display: flex;
      align-items: center;
      margin: 0px 10px;
      font-style: normal;
      font-weight: 300;
      justify-content: center;
      line-height: 16px;
      color: #979797;
    }
    &__rank {
      max-width: 50px;
      width: 50px;
      min-width: 50px;
      display: flex;
      align-items: center;
      margin: 0px 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
      color: #ffffff;
      justify-content: center;
    }
    &__total-marks {
      max-width: 100px;
      width: 100px;
      min-width: 100px;
      display: flex;
      align-items: center;
      margin: 0px 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
      color: #ffffff;
      justify-content: center;
    }
  }
}
