@import './../styles/variables';

.mrk-dist-graph {
  display: flex;
  color: #8b8b8b;
  font-size: 10px * $distribution-graph;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px * $distribution-graph;
  min-height: 120px;

  &__bar-container {
    width: 35px * $distribution-graph;
    text-align: center;

    @media (max-width: 590.98px) {
      width: 22px * $distribution-graph;
    }
  }

  &__bar {
    background-color: aqua;
    border-radius: 3px * $distribution-graph;
    transition-duration: 1s;

    &--0 {
      height: 0px * $distribution-graph;
    }
    &--1 {
      height: 1px * $distribution-graph;
    }
    &--2 {
      height: 2px * $distribution-graph;
    }
    &--3 {
      height: 3px * $distribution-graph;
    }
    &--4 {
      height: 4px * $distribution-graph;
    }
    &--5 {
      height: 5px * $distribution-graph;
    }
    &--6 {
      height: 6px * $distribution-graph;
    }
    &--7 {
      height: 7px * $distribution-graph;
    }
    &--8 {
      height: 8px * $distribution-graph;
    }
    &--9 {
      height: 9px * $distribution-graph;
    }
    &--10 {
      height: 10px * $distribution-graph;
    }

    &--11 {
      height: 11px * $distribution-graph;
    }
    &--12 {
      height: 12px * $distribution-graph;
    }
    &--13 {
      height: 13px * $distribution-graph;
    }
    &--14 {
      height: 14px * $distribution-graph;
    }
    &--15 {
      height: 15px * $distribution-graph;
    }
    &--16 {
      height: 16px * $distribution-graph;
    }
    &--17 {
      height: 17px * $distribution-graph;
    }
    &--18 {
      height: 18px * $distribution-graph;
    }
    &--19 {
      height: 19px * $distribution-graph;
    }
    &--20 {
      height: 20px * $distribution-graph;
    }

    &--21 {
      height: 21px * $distribution-graph;
    }
    &--22 {
      height: 22px * $distribution-graph;
    }
    &--23 {
      height: 23px * $distribution-graph;
    }
    &--24 {
      height: 24px * $distribution-graph;
    }
    &--25 {
      height: 25px * $distribution-graph;
    }
    &--26 {
      height: 26px * $distribution-graph;
    }
    &--27 {
      height: 27px * $distribution-graph;
    }
    &--28 {
      height: 28px * $distribution-graph;
    }
    &--29 {
      height: 29px * $distribution-graph;
    }
    &--30 {
      height: 30px * $distribution-graph;
    }

    &--31 {
      height: 31px * $distribution-graph;
    }
    &--32 {
      height: 32px * $distribution-graph;
    }
    &--33 {
      height: 33px * $distribution-graph;
    }
    &--34 {
      height: 34px * $distribution-graph;
    }
    &--35 {
      height: 35px * $distribution-graph;
    }
    &--36 {
      height: 36px * $distribution-graph;
    }
    &--37 {
      height: 37px * $distribution-graph;
    }
    &--38 {
      height: 38px * $distribution-graph;
    }
    &--39 {
      height: 39px * $distribution-graph;
    }
    &--40 {
      height: 40px * $distribution-graph;
    }

    &--41 {
      height: 41px * $distribution-graph;
    }
    &--42 {
      height: 42px * $distribution-graph;
    }
    &--43 {
      height: 43px * $distribution-graph;
    }
    &--44 {
      height: 44px * $distribution-graph;
    }
    &--45 {
      height: 45px * $distribution-graph;
    }
    &--46 {
      height: 46px * $distribution-graph;
    }
    &--47 {
      height: 47px * $distribution-graph;
    }
    &--48 {
      height: 48px * $distribution-graph;
    }
    &--49 {
      height: 49px * $distribution-graph;
    }
    &--50 {
      height: 50px * $distribution-graph;
    }

    &--51 {
      height: 51px * $distribution-graph;
    }
    &--52 {
      height: 52px * $distribution-graph;
    }
    &--53 {
      height: 53px * $distribution-graph;
    }
    &--54 {
      height: 54px * $distribution-graph;
    }
    &--55 {
      height: 55px * $distribution-graph;
    }
    &--56 {
      height: 56px * $distribution-graph;
    }
    &--57 {
      height: 57px * $distribution-graph;
    }
    &--58 {
      height: 58px * $distribution-graph;
    }
    &--59 {
      height: 59px * $distribution-graph;
    }
    &--60 {
      height: 60px * $distribution-graph;
    }

    &--61 {
      height: 61px * $distribution-graph;
    }
    &--62 {
      height: 62px * $distribution-graph;
    }
    &--63 {
      height: 63px * $distribution-graph;
    }
    &--64 {
      height: 64px * $distribution-graph;
    }
    &--65 {
      height: 65px * $distribution-graph;
    }
    &--66 {
      height: 66px * $distribution-graph;
    }
    &--67 {
      height: 67px * $distribution-graph;
    }
    &--68 {
      height: 68px * $distribution-graph;
    }
    &--69 {
      height: 69px * $distribution-graph;
    }
    &--70 {
      height: 70px * $distribution-graph;
    }

    &--71 {
      height: 71px * $distribution-graph;
    }
    &--72 {
      height: 72px * $distribution-graph;
    }
    &--73 {
      height: 73px * $distribution-graph;
    }
    &--74 {
      height: 74px * $distribution-graph;
    }
    &--75 {
      height: 75px * $distribution-graph;
    }
    &--76 {
      height: 76px * $distribution-graph;
    }
    &--77 {
      height: 77px * $distribution-graph;
    }
    &--78 {
      height: 78px * $distribution-graph;
    }
    &--79 {
      height: 79px * $distribution-graph;
    }
    &--80 {
      height: 80px * $distribution-graph;
    }

    &--81 {
      height: 81px * $distribution-graph;
    }
    &--82 {
      height: 82px * $distribution-graph;
    }
    &--83 {
      height: 83px * $distribution-graph;
    }
    &--84 {
      height: 84px * $distribution-graph;
    }
    &--85 {
      height: 85px * $distribution-graph;
    }
    &--86 {
      height: 86px * $distribution-graph;
    }
    &--87 {
      height: 87px * $distribution-graph;
    }
    &--88 {
      height: 88px * $distribution-graph;
    }
    &--89 {
      height: 89px * $distribution-graph;
    }
    &--90 {
      height: 90px * $distribution-graph;
    }

    &--91 {
      height: 91px * $distribution-graph;
    }
    &--92 {
      height: 92px * $distribution-graph;
    }
    &--93 {
      height: 93px * $distribution-graph;
    }
    &--94 {
      height: 94px * $distribution-graph;
    }
    &--95 {
      height: 95px * $distribution-graph;
    }
    &--96 {
      height: 96px * $distribution-graph;
    }
    &--97 {
      height: 97px * $distribution-graph;
    }
    &--98 {
      height: 98px * $distribution-graph;
    }
    &--99 {
      height: 99px * $distribution-graph;
    }
    &--100 {
      height: 100px * $distribution-graph;
    }
  }

  &__range {
    margin-top: 7px * $distribution-graph;

    @media (max-width: 590.98px) {
      &-segment:after {
        content: '\a';
        white-space: pre;
      }
    }
  }
}
