.assign-subject-card-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  .assign-subject-card-list-item {
    padding: 5px 10px;
    background-color: #1f2130;
    border-radius: 6px;
    max-width: 95%;

    &__name {
      color: #fff;
    }

    margin-bottom: 10px;
  }
}
