.section-title{
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #73847F;
  }

  .section-container{
    height: calc(100vh - 180px);
    overflow-y: auto;
  }