.stu-exam-stats {
  margin-top: 10px;
  padding: 20px;
  background: #1b1c28;
  box-shadow: 0px 0px 9.46941px #181924;
  border-radius: 14.2041px;

  &__section {
    display: flex;
    margin-top: 10px;
  }
}
