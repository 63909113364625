

.create-notice-waper {
  color: white;

  .s0 {
    display: flex;
    align-items: center;
    .btn-back {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #246bfd;
      border-radius: 7px;
      background-color: #2c2e41;
      cursor: pointer;
    }
    .s1-title {
      margin: 0;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      color: #ffffff;
      padding-left: 10px;
    }
  }
  .cn-main-content {
    margin-top: 30px;
    .cn-label {
      font-weight: 500;
      font-size: 18px;
      color: #73847f;
    }
    .s2 {
      margin-top: 40px;

      .cn-input {
        color: white;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        background-color: #181924;
        border: none;
        border-bottom: 1px solid #6a6878;
        outline: none;
      }
      .input-error {
        border-bottom: 1px solid #ff5e00;
      }
    }
    .s3 {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      .cn-date {
        input {
          color: white;
          background-color: #181924;
          border: none;
          border-bottom: 1px solid #6a6878;
          outline: none;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          padding-bottom: 8px;
        }
        input[type='date']::-webkit-calendar-picker-indicator {
          color: rgba(0, 0, 0, 0);
          opacity: 1;
          background: url(./../assets/dateicon.png) no-repeat;
          width: 20px;
          height: 20px;
          border-width: thin;
        }
      }
      .cn-time {
        input {
          color: white;
          background-color: #181924;
          border: none;
          border-bottom: 1px solid #6a6878;
          outline: none;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          padding-bottom: 8px;
        }
        input[type='time']::-webkit-calendar-picker-indicator {
          color: rgba(0, 0, 0, 0);
          opacity: 1;
          background: url(../assets/timeicon.png) no-repeat;
          width: 18px;
          height: 18px;
          border-width: thin;
        }
      }
      .cn-duration {
        input {
          max-width: 175px;
          color: white;
          background-color: #181924;
          border: none;
          border-bottom: 1px solid #6a6878;
          outline: none;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          padding-bottom: 8px;
        }
      }
    }
    .s4 {
      margin-top: 40px;
      .file-list {
        margin-top: 14px;
        display: flex;
        .file-w {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-right: 5px;

          .file-name {
            max-width: 60px;
            text-align: center;
            word-wrap: break-word;
            font-weight: 400;
            font-size: 10px;
          }
          .file-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            div {
              width: 59px;
              height: 59px;
              background: #2c2e41;
              border-radius: 6.25455px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              .close {
                position: absolute;
                top: 5px;
                right: 5px;
                color: #f05a5a;
                font-size: 16px;
                cursor: pointer;
              }
              .micon {
                font-size: 30px;
                color: #474a66;
              }
            }
          }
        }

        .add-new-m {
          padding-left: 25px;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            .an-icon {
              background-color: #246bfd;
            }
          }
          .an-icon {
            background-color: #474a66;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #181924;
          }
          .an-text {
            padding-left: 10px;
          }
        }
      }
    }
    .s5 {
      margin-top: 60px;
      .cn-ch-content {
        .cn-row {
          margin-top: 40px;
          display: flex;
          .cn-sub-label {
            font-weight: 500;
            font-size: 14px;
            color: #73847f;
            padding-right: 20px;
          }
          .gredes {
            max-width: 400px;
            .pretty {
              width: 100px;
              margin: 0;
            }
          }
          .subjects {
            max-width: 600px;
            .pretty {
              width: 300px;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .f-button {
    height: 40px;
    background-color: #246bfd;
    color: white;
    padding: 0 22px;
    border-radius: 8px;
    &:hover {
      background-color: #2e74ff;
    }
  }
}

.error-message {
  color: #dc3545;
  font-size: 12px;
}
