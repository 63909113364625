.exam-info-pill {
  background-color: #1f2130;
  border-radius: 10px;
  color: white;
  margin-right: 20px;
}

.exam-info-pill-count {
  height: 80px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  overflow: hidden;
  padding: 15px 25px;
  span {
    color: #979797;
  }
}

.exam-info-pill-time {
  height: 80px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 25px;
  line-height: 1;
  font-size: 14px;

  & > div:nth-child(1),
  & > div:nth-child(3) {
    color: #979797;
    font-weight: 500;
  }
}

.exam-info-pill-duration {
  min-height: 80px;
  height: auto;
  width: auto;
  line-height: 1;
  font-size: 14px;
  flex-direction: column;
  display: flex;
  padding: 15px 25px;

  & > div:nth-child(1) {
    color: #979797;

    & > div:nth-child(2) {
      color: #fff;
    }
  }
}

@media (max-width: 1184.98px) {
  .exam-info-pill {
    margin-top: 10px;
    margin-right: 0px;
  }

  .exam-info-pill,
  .exam-info-pill-count,
  .exam-info-pill-time,
  .exam-info-pill-duration {
    width: 100%;
  }

  .exam-info-pill-duration {
    align-items: center;
  }

  .exam-info-pill-time {
    div {
      text-align: center;
    }
  }
}
