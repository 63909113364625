@import './../styles/variables';

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  text-align: center;

  &__img-404 img {
    background-image: url('./../assets/pageNotFound.png');
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 369px;
    max-height: 275px;
  }

  &__main-title {
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    margin-top: 2%;
  }

  &__info {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #979797;
    margin-top: 5px;
    max-width: 415px;
    font-family: 'Roboto', sans-serif;
  }
}

.btn-group-404 {
  font-weight: 500;
  line-height: 23px;
  margin-top: 23px;
  align-items: center;
  width: flex;
}

@media (max-width: 398px) {
  .page-not-found {
    &__img-404 img {
      max-width: 334px !important;
      max-height: 249px !important;
    }
    &__main-title {
      font-size: 32px !important;
      line-height: 38px !important;
    }
    &__info {
      line-height: 17px;
    }
  }
}

@media (max-width: 280px) {
  .page-not-found {
    &__img-404 img {
      max-width: 260px !important;
      max-height: 200px !important;
    }
    &__main-title {
      font-size: 24px !important;
      line-height: 38px !important;
    }
    &__info {
      font-size: 10px;
      line-height: 17px;
    }
  }
}
