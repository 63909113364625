@import './../styles/variables';

.tab-stu-exam-live {
    margin-top: 40px;
    height: calc(100vh - 120px);
    overflow-y: auto;
  
    .tab-stu-exam-live__current-title,
    .tab-stu-exam-live__upcoming-title,
    .tab-stu-exam-live__completed-title {
      color: map-get($colors, secondary-grey);
      cursor: pointer;
      span {
        margin-right: 5px;
      }
      margin-bottom: 15px;
    }
  
    .tab-stu-exam-live__upcoming-title,
    .tab-stu-exam-live__completed-title {
      margin-top: 45px;
    }
  
    .tab-stu-exam-live__current-cards,
    .tab-stu-exam-live__upcoming-cards,
    .tab-stu-exam-live__completed-cards {
      & > div {
        margin-bottom: 8px;
      }
    }
  }