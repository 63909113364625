.question-navigator-btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #246bfd;
  border-radius: 5px;
  cursor: pointer;
  span {
    color: #fff;
    font-size: 12px;
  }
}

.question-navigator-btn--not-answered {
  background-color: #979797;
}

.question-navigator-btn--current-question {
  border: 2px solid #fff;
}
