@import './../styles/variables';
.reset-password {
  width: 528px * $auth-factor;

  @include theme() {
    background-color: theme-get('authComp-bg');
  }
  padding: (60px * $auth-factor) (45px * $auth-factor) (55px * $auth-factor);

  border-radius: 15px * $auth-factor;

  .reset-password__title {
    font-size: 40px * $auth-factor;
    color: #fff;
    text-align: center;
  }

  .reset-password__subtitle {
    color: #fff;
    font-size: 19.5px * $auth-factor;
    text-align: center;
    padding-bottom: 40px;
    line-height: 1;
    font-weight: 400;
  }

  .reset-password__btn {
    margin-top: 80px * $auth-factor !important;

    border-radius: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px * $auth-factor;
    background-color: map-get($colors, secondary-blue);
    padding: (12px * $auth-factor) 0;
    font-size: 20px * $auth-factor;
    color: map-get($colors, pure);
  }
}
