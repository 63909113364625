.card-wraper {
  color: #fff;
  text-align: left;
  display: flex;
  background-color: #292c41;
  padding: 17px 25px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #181924;
  margin-top: 12px;

  .card-l {
    padding-right: 50px;
    flex: 1;
    .an-active {
      font-weight: 300;
      font-size: 12px;
      color: #20d167;
      margin-left: 10px;
    }
    .an-inactive {
      font-weight: 300;
      font-size: 12px;
      color: #f05a5a;
      margin-left: 10px;
    }
    .an-upcoming {
      font-weight: 300;
      font-size: 12px;
      color: #ffd977;
      margin-left: 10px;
    }
    label {
      font-weight: 500;
      font-size: 16px;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      color: #b0b0b0;
    }
  }
  .card-r {
    text-align: left;
    width: 250px;
    font-size: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    div {
      .stitle {
        font-weight: 300;
        font-size: 12px;
        color: #b0b0b0;
      }
      .svalue {
        font-weight: 300;
        font-size: 12px;
        color: #ffffff;
        margin-left: 8px;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      .markasread {
        color: #246bfd;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 8px;
      }
      .buttonx {
        width: 131px;
        background-color: #246bfd;
        text-align: center;
        margin-left: 10px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        padding-top: 7px;
        padding-bottom: 7px;
        color: white;
      }
      .ed-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        .edit {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #404462;
          border-radius: 4px;
          margin: 2px;
          cursor: pointer;
        }
        .delete {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f05a5a;
          border-radius: 4px;
          margin: 2px;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .card-wraper {
    display: block;
    .card-r {
      margin-top: 20px;
      width: 100%;
      .actions {
        display: block;

        .markasread {
          display: block;
        }
        .buttonx {
          margin: 10px 0px;
          width: 100%;
        }
      }
    }
  }
}
