.answer-card {
  border-radius: 6px;
  background-color: #1f2130;
  padding: 8px 17px 8px 25px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;

  .answer-card__question-no {
    margin-right: 15px;
    margin-left: 20px;
    font-size: 14px;
    color: #979797;
  }

  .answer-card__answer {
    p {
      margin-bottom: 0;
    }
  }
}

.answer-card--selected {
  background-color: #1d2564;
}

@media (max-width: 575.98px) {
  .answer-card {
    padding: 4px 17px 4px 25px;
    .answer-card__not-selected-circle {
      font-size: 14px;
    }

    .answer-card__selected {
      font-size: 14px;
    }

    .answer-card__question-no {
      margin-right: 12px;
      margin-left: 12px;
      font-size: 12px;
      color: #979797;
    }

    .answer-card__times-icon,
    .answer-card__correct-tick {
      font-size: 10px;
    }

    .answer-card__answer {
      font-size: 13px;
    }
  }
}
