.exam-p-info {
  .exam-p-info__counter {
    font-size: 80px;
    color: #fff;
    text-align: center;
    margin-top: 50px;
    span {
      color: #8b8b8b;
      font-weight: 300;
    }
  }

  .exam-p-info__cards {
    margin-top: 50px;
    flex-wrap: nowrap;
    overflow: auto;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .exam-p-info {
    .exam-p-info__cards {
      margin-top: 50px;
      flex-wrap: wrap;
      overflow: hidden;
    }
  }
}
