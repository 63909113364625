.content-list-header {

    display: flex;
    align-items: center;
    color: #fff;
    margin-bottom: 2rem;
    padding-left: 25px;
    padding-right: 35px;
    padding-top: 1.4rem;


    &__title {
        color: "#fff"
    }

    &__actions {
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    &__eye {
        border: 1.5px solid #246BFD;
        line-height: 1;
        padding: .5rem;
        border-radius: 8px;
        cursor: pointer;
        margin-right:3px;
    }

    &__btn {
        background-color: #246BFD;
        padding: 5px 15px;
        border-radius: 8px;
        cursor: pointer
    }

}



@media (min-width:768px) and (max-width:830px) {
    .content-list-header{
    padding-left: 0;
    padding-right: 0;
    }
}