@import './../../../styles/variables';

.textinput {
    position: relative;
    height: 80px * $text-input-factor;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.textinput input {
    width: 100%;
    height: 100%;
    padding-top: 3px * $text-input-factor;
    padding-left: 0;
    border: none;
    outline: none;
    font-size: 26px * $text-input-factor;
    background: transparent;
    transition: none;
    margin-bottom: 1px; // change this rule appropiately

    @include theme() {
        color: theme-get('color-2');
    }
}

.text-input-readonly {
    pointer-events: none;
}

.textinput input[name='password'] {
    //This was used to align the asterisks with the bottom border og the input
    padding-top: 15px * $text-input-factor;
}

.textinput label {
    position: absolute;
    bottom: 0px;
    left: 0%;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid map-get($colors, secondary-grey);
    // color: #73847f;
    font-size: 26px * $text-input-factor;

    @include theme() {
        color: map-get($colors, 'secondary-grey');
    }
}

.textinput-label-text {
    position: absolute;
    bottom: 5px * $text-input-factor;
    left: 0px;
    transition: all 0.5s ease;
    //   transauth-input: translateY(4.5px); //myprop
}

.textinput input:focus+.textinput-label .textinput-label-text,
.textinput input:valid+.textinput-label .textinput-label-text {
    transform: translateY(-120%);
    font-size: 20px * $text-input-factor;

    // color: #73847f;
    @include theme() {
        color: map-get($colors, 'secondary-grey');
    }
}

.input-padding {
    padding-top: 3px * $text-input-factor !important;
}

.input-padding-password {
    padding-top: 15px * $text-input-factor !important;
    color: transparent !important;
}

.err-styles {
    font-size: 11px;
    line-height: 1;
    font-weight: 400;
    color: #dc3545;
    text-align: left;
}