.stu-taa-card {
  background-color: #1f2130;
  padding: 10px 20px;
  border-radius: 5px;
  height: auto;
  overflow: hidden;
  transition: all 0.3s;
  min-width: 100%;
  width: fit-content;

  &__head,
  &__cb-list {
    display: flex;
    color: #fff;
  }

  &__cb-item {
    min-width: 120px;
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    color: #979797;
    font-size: 10px;
  }

  &__arrow-btn {
    cursor: pointer;
    color: #6a6878;
    font-size: 12px;
    align-self: center;
    min-width: 20px;
  }

  &__stu-name,
  &__paper-name {
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
  }

  &__papers {
    display: flex;
  }

  &__papers {
    margin-top: 5px;
  }

  &__radio-list {
    display: flex;

    &-item {
      min-width: 120px;
      margin: 0px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
