.under-construction {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  .sec1 {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .sec2 {
    font-size: 64px;
    font-weight: 800;
    line-height: 1;
  }
}
.back-btn {
  width: 170px;
  height: 45px;
  background-color: #2c2e41;
  text-align: center;
  border-radius: 7px;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  .icon {
    color: #246bfd;
    margin-top: -5px;
  }
}

.user-construction-img {
  background-image: url('./../assets/underconstruction.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  width: auto;
  overflow: visible;
  margin-right: 13px;
  margin-left: auto;
}

@media (max-width: 450px) {
  .under-construction {
    .sec1 {
      font-size: 40px !important;
    }
    .sec2 {
      font-size: 50px !important;
      line-height: 50px;
    }
  }
}

@media (max-width: 350px) {
  .under-construction {
    .sec1 {
      font-size: 30px !important;
    }
    .sec2 {
      font-size: 35px !important;
      line-height: 50px;
    }
  }
}
