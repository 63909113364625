.shared-btn{
    background-color: #246bfd;
    padding:8px 12px;
    border-radius: 8px;
    color:#fff;
    display: flex;
    align-items: center;
    &:focus{
        outline: none;
    }
    &--disabled{
        background:grey;
        cursor: none;
    }
    &--hidden{
            display:none;
    }
    &-pkgcreate{
    height: 40px;
    width: 227px;
    border: 1px solid #246BFD;
    border-radius: 8px;
    display: block;
    &--back{
        background: transparent;
    }
    }
}

