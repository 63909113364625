@import './../styles/variables';

.breadcrum {
  .home-icon-bg {
    width: 25px;
    height: 25px;
    background-color: map-get($colors, primary-blue-light);
    position: relative;
    border-radius: 5px;
    .home-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  div {
    font-size: 15px;
    color: #fff;
  }
}
