@import './../styles/variables';


.number-box {
  background-color: map-get($colors, primary-blue-three);
  color: map-get($colors, grey);
  width: 30px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
