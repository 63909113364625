 .PackageAccessCodes {
   width: calc(100% - 10px);
   height: 110px;
   background: #1f2130;
   box-shadow: 0px 0px 8px #181924;
   border-radius: 8px;
   display: flex;
   align-items: center;
   cursor: pointer;
   position: relative;
   margin-top: 40px;

   &__image {
     width: 133px;
     min-width: 133px;
     height: 110px;
     position: relative;

     img {
       width: 100%;
       height: 100%;
       object-fit: cover;
       border-radius: 8px 0px 0px 8px;
     }
   }

   &__grade {
     position: absolute;
     display: flex;
     flex-direction: column;
     align-items: center;
     margin-top: 60px;
     margin-left: 15px;
     width: 67px;
     height: 23px;
     background: #f3b619;
   }

   &__content {
     display: flex;
     flex-direction: column;
     align-items: left;
     margin-left: 22px;
     margin-right: 22px;
     width: inherit;

     &-title {
       font-family: Roboto, sans-serif;
       font-style: normal;
       font-weight: 500;
       font-size: 16px;
       line-height: 19px;
       color: #ffffff;
       max-width: calc(100% - 0px);
     }

     &-createdBy {
       font-family: 'Roboto';
       font-style: normal;
       font-weight: 400;
       font-size: 14px;
       line-height: 16px;
       display: flex;
       align-items: center;
       color: #a4a4a4;
       margin-top: 2px;
     }

     &-year {
       font-family: 'Roboto';
       font-style: normal;
       font-weight: 700;
       font-size: 14px;
       line-height: 16px;
       display: flex;
       align-items: center;
       color: #4f98c0;
       margin-top: 5px;
     }
   }

   &__button {
     width: 100%;
     height: 35px;
     // position: relative;
     // float: right;
     justify-content: right;

     &-create {
       width: 197px;
       float: right;
       margin-right: 50px;
     }
   }
 }

 .bulkCode {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   flex-flow: wrap;
   margin-top: 25px;
   column-gap: 10px;
   row-gap: 15px;
   height: calc(100vh - 400px) !important;
   overflow: auto;
   cursor: pointer;

   &__codebox {
     align-items: center;
     //  width: calc(100%/3 - 25px);
     height: 77px;
     background: #1f2130;
     box-shadow: 0px 0px 8px #181924;
     border-radius: 2px;
     padding: 20px;

     &-code {
       font-size: 14px;
       width: max-content;
     }

     &-expire {
       font-size: 12px;
       color: #979797;
     }

     &-availability {
       font-size: 12px;
       text-align: end;
       color: #979797;

     }

     &-amount {
       font-size: 13px;
       text-align: end;
     }

     &-downicon {
       text-align: center;
       padding-left: 5px;
     }
   }
 }