@import './../styles/variables';

.role-badge-text {
  color: map-get($colors, grey);
  font-size: 12.5px;
  font-weight: 300;
  line-height: 1;
}

.role-badge-icon {
  background-color: #fff;
  margin-left: 9px;
  border-radius: 50%;
  position: relative;
  width: 13px;
  height: 13px;
}

.instructor-verified-tick {
  left: -1.5px;
  top: -1px;
}
