.question-results-header {
    .question-results-header__details {
      line-height: 1;
  
      & > div:first-child {
        color: #fff;
        font-size: 20px;
      }
  
      & > div:last-child {
        color: #979797;
      }
    }
  
    .question-results-header__time {
      color: #fff;
      width: 68px;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      & > div:first-child {
        font-size: 13px;
      }
  
      & > div:last-child {
        font-size: 11px;
        color: #88888a;
      }
    }
  }
  
  .question-results-header__score {
    margin-left:auto;
  }
  
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .question-results-header {
      .question-results-header__details {
        line-height: 1;
  
        & > div:first-child {
          color: #fff;
          font-size: 16px;
        }
  
        & > div:last-child {
          color: #979797;
          font-size: 13px;
        }
      }
    }
  }
  