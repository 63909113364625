@import './src/styles/variables';

.step_one {
  margin-top: 100px;
  margin-right: 10%;

  &_content-card {
    background-color: #1F2130;
    padding: 10px;
    border-radius: 10px;
    margin-right: 10px;
    min-width: 100px;

    &__title {
      color: #73847F;
      font-size: 15px;
      font-weight: 500;
    }

    &__content {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
    }
  }

  &_image {
    margin-top: 10px;
    width: 260.11px;
    height: 259px;
    background-color: map-get($colors, primary-blue-three);
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    margin-left: 2%;
    }
}

.step_two {
  margin-top: 100px;
}

.step_three {
  margin-top: 100px;

  &_content-card {
    background-color: #1F2130;
    border-radius: 10px;
    margin-right: 10px;
    min-width: 100px;

    &__title {
      color: #73847F;
      font-size: 15px;
      font-weight: 500;
    }

    &__content {
      color: #fff;
      font-size: 15px;
      font-weight: 500;

      &--year {
        color: #4F98C0;
        font-size: 16px;
        font-weight: 700;
        margin-right: 40px;
      }

      &--author {
        color: #A4A4A4;
        font-size: 12px;
        font-weight: 400;
      }

      &--subject {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
        margin-right: 40px;
      }

      &--grade {
        color: #A4A4A4;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}
.btn-medium {
  width: 227px;
  display: block;
  margin-top: 2%
}

.space__top-sm {
  margin-top: 2.5%;
}

.w-100>.textinput {
  width: 96%;
}