@import './../styles/variables';

.paper-preview-modal {
  z-index: 100;
  position: absolute;
  top: 10vh;
  width: 86.29%;
  height: 88vh;
  color: #fff;
  background-color: #181924;
  border-radius: 8px;
  padding: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid white;

  .paper-preview-modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }

  .paper-preview-modal__title {
    display: flex;
    padding-left: 30px;
    padding-top: 20px;
    align-content: center;

    &--text {
      font-size: 18px;
      padding-left: 20px;
      display: flex;
    }

    &--type {
      font-size: 14px;
      color: #cdcdcd;
      padding-right: 20px;
      border-right: 1px solid #cdcdcd;
      padding-top: 5px;

      span {
        background-color: #404462;
        padding: 4px 5px;
        border-radius: 4px;
      }
    }
  }

  .paper-preview-modal__content {
    margin-top: 40px;
    overflow: auto;
    height: calc(100% - 100px);
    padding: 30px;
  }
}
