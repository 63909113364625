@import './../styles/variables';


.hamburger {
  width: 35px;
  height: 35px;
  background-color: map-get($colors, secondary-blue);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;

  &__bar {
    height: 3px;
    background-color: #fff;
    border-radius: 1px;
  }
}
