.idgst-tabs {
    margin-top: 25px;
    &__tabs {
      display: flex;
      color: #fff;
      line-height: 1;
      text-align: center;
  
      &-dashboard,
      &-pay-grade,
      &-settings,
      &-ta-payments {
        background: #1f2130;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 0px;
        width: 180px;
        height: 35px;
        border-radius: 8px 8px 0px 0px;
        cursor: pointer;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
  
      &-active {
        background-color: #246bfd;
      }
    }
  
    &__body {
      height: calc(100vh - 135px);
      overflow: auto;
      padding-right: 10px;
    }
  }
  