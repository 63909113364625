@import './../styles/variables';

.create-common-access-code-modal {
  z-index: 100;
  position: absolute;
  top: 10vh;
  left: 10%;
  width: 80%;
  color: map-get($colors, pure);
  background-color: map-get($colors, primary-blue-light);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  padding: 25px 40px 30px;

  .create-common-access-code-modal__header {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .create-common-access-code-modal__main {
    display: flex;

    .create-common-access-code-modal-inputs {
      width: 100%;

      .w-100>.textinput {
        width: 100% !important;
      }

      .create-common-access-code-modal-autoGBtn {
        font-size: 12px;
        position: absolute;
        top: 100px;
        left: 380px;
        color: #246BFD;
        z-index: 10;
        cursor: pointer;
      }
    }
  }

  .create-common-access-code-modal__btn {
    background-color: map-get($colors, secondary-blue);
    margin-top: 25px;
    color: map-get($colors, pure);
    border-radius: 8px;
    width: 55%;
    height: 36px;
    padding: 2px 0;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
  }

  .btn-disable-add-sub {
    background-color: map-get($colors, grey);
    cursor: default;
  }
}

@media (min-width: 700px) {
  .create-common-access-code-modal {
    left: calc(50% - 220px);
    width: 500px;

    .create-common-access-code-modal__main {
      margin-top: 30px;
      display: flex;
    }
  }
}

.create-common-access-code-modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.create-common-access-code-modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms;
}

.create-common-access-code-modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.create-common-access-code-modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 300ms;
}

@media (max-width: 739.98px) {
  .create-common-access-code-modal {
    .create-common-access-code-modal__main {
      display: block;

      .create-common-access-code-modal-inputs {
        width: 100%;
      }

    }
  }
}

@media (max-width: 370.98px) {
  .create-common-access-code-modal {
    .create-common-access-code-modal__main {
      .create-common-access-code-modal-image {
        width: 100%;
      }
    }
  }
}


.pkg-choice {
  color: #979797;
  font-size: 14px;

  div:nth-child(1) {
    flex-grow: 1;
  }

  div:nth-child(2) {
    flex-grow: 2;
    text-align: right;
  }

  div:nth-child(3) {
    flex-grow: 0.1;
    align-items: center;
  }

  div:nth-child(4) {
    flex-grow: 1;
    text-align: right;
  }

  div:nth-child(5) {
    flex-grow: 0.1;
  }
}

.code-discount {
  gap: 20px;

  div:nth-child(1) {
    flex: 1;
    padding-top: 6pt;
  }

  div:nth-child(2) {
    flex: 1;
  }
}

.c-details {
  div:nth-child(1) {
    flex: 1;
    margin-right: 5px;
  }

  div:nth-child(2) {
    flex: 1;
    margin-right: 5px;
  }

  div:nth-child(3) {
    flex: 2;
  }
}