.TA-assigned-papers {
  width: auto;
  max-height: calc(98vh - 350px) !important;
  overflow-y: auto;
  cursor: pointer;

  &__exam-card {
    width: calc(100% - 10px);
    overflow-x: hidden;
    height: 100px;
    background: #1f2130;
    box-shadow: 0px 0px 8px #181924;
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: #979797;
    margin-bottom: 12px;
    position: relative;

    &-exam-duration {
      margin: 0px 20px;
      display: flex;
      flex-direction: column;
      font-size: 12px;

      &-start {
        display: flex;
        flex-direction: row;
        gap: 10px;
        letter-spacing: 1px;
        margin: 5px 0px;
      }
      &-end {
        display: flex;
        flex-direction: row;
        gap: 10px;
        letter-spacing: 1px;
        margin: 5px 0px;
      }
    }

    &-exam-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-title {
        width: 230px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        margin: 5px 0px;
      }
      &-exam-assignedon {
        font-size: 12px;
        margin: 5px 0px;
      }
    }
    &-rejected-icon {
      color: #f05a5a;
      font-size: 16px;
      position: absolute;
      right: 0;
      margin-right: 20px;
    }
  }

  @media screen and (min-width: 1600px) {
    &__exam-card-exam-duration {
      font-size: 12px !important;
    }
    &__exam-card-exam-content-title {
      width: 280px !important;
      font-size: 14px !important;
    }
    &__exam-card-exam-content-exam-assignedon {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 1435px) {
    &__exam-card-exam-duration {
      font-size: 9px !important;
    }
    &__exam-card-exam-content-title {
      width: 200px !important;
      font-size: 12px !important;
    }
    &__exam-card-exam-content-exam-assignedon {
      font-size: 10px !important;
    }
  }
}
