@import './../styles/variables';

.subject-card {
  width: 16%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  margin-right: 4%;
  margin-bottom: 4%;
  cursor: pointer;

  .subject-card-img {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: auto;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    display: block;
    height: 150px;
    position: relative;

    .subject-card-img__overlay {
      padding: 0px 35px 0px 35px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      width: 100%;
      background: rgba($color: #131520, $alpha: 0.7);
      opacity: 0;
      transition: 0.4s ease;

      & > div {
        padding: 13px 14px;
        background-color: #1f2130;
        border-radius: 8px;
      }
    }

    .subject-card-img__grade {
      position: absolute;
      bottom: 10px;
      left: 10px;
      padding: 3.5px 3px;
      background-color: map-get($colors, primary-yellow);
      line-height: 1;
      color: map-get($colors, pure);
      font-weight: 400;
      font-size: 13px;
    }
  }

  .subject-card-img:hover .subject-card-img__overlay {
    opacity: 1;
  }

  .subject-card-description {
    font-size: calc(11.5px + 0.1vw);
    line-height: 1;
    padding: 7% 7% 0% 7%;
    color: map-get($colors, pure);
    background-color: map-get($colors, primary-blue-light);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
    height: 87px;

    .subject-card-year {
      color: map-get($colors, blue-type-1);
    }
  }
}

@media (max-width: 1149.98px) {
  .subject-card {
    width: 30%;
    margin-right: 3.33%;
  }
}

@media (max-width: 899.98px) {
  .subject-card {
    width: 45%;
    margin-right: 5%;
  }
}

@media (max-width: 575.98px) {
  .subject-card {
    width: 100%;
    margin-right: 0;
    .subject-card-description {
      font-size: 16px;
      height: auto;
      padding: 15px;
    }
  }
}
