@import './../styles/variables';

.tab_active {
  background-color: map-get($colors, secondary-blue);
}
.tab_deactivate {
  background-color: map-get($colors, primary-blue-light);
}

.tab-stu-exam-practise {
  margin-top: 40px;
  height: calc(100vh - 120px);
  overflow-y: auto;

  .tab-stu-exam-practise__current-title,
  .tab-stu-exam-practise__upcoming-title,
  .tab-stu-exam-practise__completed-title {
    color: map-get($colors, secondary-grey);
    cursor: pointer;
    span {
      margin-right: 5px;
    }
    margin-bottom: 15px;
  }

  .tab-stu-exam-practise__upcoming-title,
  .tab-stu-exam-practise__completed-title {
    margin-top: 45px;
  }

  .tab-stu-exam-practise__current-cards,
  .tab-stu-exam-practise__upcoming-cards,
  .tab-stu-exam-practise__completed-cards {
    & > div {
      margin-bottom: 8px;
    }
  }
}
