@import './../styles/variables';

.acc-loading-indicator {
  margin-left: 28px;
  margin-top: 50px;
}

.edit-profile-span {
  color: map-get($colors, secondary-blue);
  font-size: 14px;
  cursor: pointer;
  transition: none;
}

.edit-profile-span-disable {
  color: map-get($colors, grey);
  cursor: default;
}

.edit-bio-span {
  color: map-get($colors, secondary-blue);
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
  transition: none;
}

.edit-bio-span-disable {
  color: map-get($colors, grey);
  cursor: default;
}

.change-email-span {
  color: map-get($colors, secondary-blue);
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
  margin-top: 10px;
  transition: none;
}

.change-email-span-disable {
  color: map-get($colors, grey);
  cursor: default;
}

.change-phone-span {
  color: map-get($colors, secondary-blue);
  font-size: 14px;
  cursor: pointer;
  line-height: 1;
  margin-top: 10px;
  transition: none;
}

.change-phone-span-disable {
  color: map-get($colors, grey);
  cursor: default;
}

.card-section {
  margin-top: 20px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  display: flex;

  span {
    color: map-get($colors, grey);
  }

  .form-card {
    width: 200px;
    display: inline-block;
    margin-left: 20px;
  }

  .btn-add-card {
    color: map-get($colors, secondary-blue);
    font-size: 14px;
    background-color: transparent;
    outline: none;
    border: none;
    width: 200px;
    margin-left: 20px;
    display: inline-block;
  }
}


.account-margin {
  margin-top: 16px;
}

.ab-l-col {
  padding-left: 45px !important;
  padding-right: 70px !important;
  transition: none;
}

.ab-r-col {
  padding-left: 0 !important;
  padding-right: 100px !important;
  transition: none;
}

.acc-e-margin {
  margin-top: 58px;
}

@media (max-width: 991.98px) {
  .ab-r-col {
    padding-left: 45px !important;
    padding-right: 70px !important;
  }
}

@media (max-width: 767.98px) {
  .acc-loading-indicator {
    margin-left: 0;
  }

  .ab-l-col {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .ab-r-col {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 575.98px) {
  .account-margin {
    margin-top: 10px;
  }
}
