.defaultCard {
  background: #1B1C28;



  // margin: 20px;
  padding: 20px;
  display: flex;
  height: 89px;

  &-sub-exams{
    background: #24273A;
    border-radius: 0;
    padding-left: 4rem;
    // height: 40px;
    // padding-top: 30px;
    // padding-bottom: 12px;
  padding-bottom: 0px;
    padding-top: 10px;
    height: 50px;

  }


  &-img-container{
    width: 97px;
    height: 68px;
    background: #181924;
    border-radius: 8px 0px 0px 8px;
  }

  &-sub-units{
    background: #1F2130 !important;
    box-shadow: none;
    // padding-left:2rem;
    // padding-top: 2px;
    padding:0;
    padding-left: 2.5rem;
    // padding-top: 0px;
    // padding-bottom: 1rem;

 &>div:first-child>div>.testing-units{
  padding-top: 1.9rem;
  padding-bottom: 2rem;
 }

    &>.text-white{
      padding-top: .8rem;
      margin-bottom: 14px;
    }


    &>.defaultCard-img-container{
      width: 60px;
      height: 50px;
    }

    &>.text-white>.defaultCard__title{
      padding-top: 1rem;
    }
    

    &>div{
      display: inline-flexbox;
      // height: 70px;
    }


    &-exams{
      background: #24273A;
      border-radius: 0;
      padding-left: 7rem;
      box-shadow: none;
      margin-left:-2rem;
      padding-bottom: 10px;
      padding-top: 10px;
      height: 50px;

    }
    &-exams_2{
      background: #24273A;
      border-radius: 0;
      box-shadow: none;
      margin-left: -5.5rem;
      padding-left: 7rem;
      padding-bottom: 10px;
      padding-top: 10px;
      height: 50px;
    }
  }


  &__checkbox {
    height: 11px;
    width: 11px;
    border: 1px solid #6A6878;
    cursor: pointer;
  }

  &__title {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  &___content {
    color: #979797;
    font-size: 13px;
    font-weight: 400;

  }

  &__exam_type {
    background-color: #404462;
    border-radius: 4px;
    color: #CDCDCD;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 8px;

  }

  &-img{
object-fit: scale-down;
    &-lesson{
      border-radius: 10px 0px 0px 10px;
      width: 97px;
      height: 68px;
    }

    &-unit{
      border-radius: 8px 0px 0px 8px;
      width: 60px;
      height: 52px;
      object-fit: cover;

      &[data-role="dummy"]{
        object-fit: scale-down;
      }
    }
  }
}


.treeview-model-border{
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.treeview_container{
  background: #1B1C28;
  box-shadow: 0px 0px 8px #181924;
  border-radius: 8px;

  &>div{
  padding-top: 10px;
  padding-bottom: 10px;
  }
  
  &:first-child> div:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px 0px 8px #181924;
  }
}
.treeview-model-border ~ div > div:last-child{
  border-bottom: 15px solid #1F2130;
}
.treeview-model-border ~ div > div:first-child{
  border-top: 2px solid #1F2130;
}
.defaultCard-sub-units> div:last-child{
  border-bottom: 5px solid #1F2130;
}

.defaultCard-sub-units-exams-container{
  height: 50px !important;
}
.defaultCard-sub-units-exams-container_first-child{
  padding-top:14px;
  height: 62px !important;
}
.testing-units{
  height: 20px;
  padding-top: 2rem;
  padding-bottom: 1.8rem;
}

.unit-exams-padding{
  // &>div>div:first-child{
  //   padding-top:1.8rem;
  //   padding-bottom: 1.3rem;
  // }
  // &>div>div:last-child{
  //   padding-top:1.8rem;
  //   padding-bottom: 1.3rem;
  // }


  &>div{
    &>div{
      height:45px;

    }
  }

  &>div>div>div{
    padding-top:3px 0;
  }

  &>div:first-child>div>div{
    padding-top: 6px;
  }

  &>div:last-child>div>div{
    padding-bottom: 6px;
  }
}