.box-body {
  display: flex;
}

.indicator {
  width: 4px;
  height: 20px;
  margin-right: 3px;
  background-color: #404462;
  border-radius: 1px;
}
.bg-1 {
  background-color: #20d167;
}
.bg-2 {
  background-color: #96ea2f;
}
.bg-3 {
  background-color: #fafe00;
}
.bg-4 {
  background-color: #efae32;
}
.bg-5 {
  background-color: #e56261;
}
