.assign-user-model{
    
    left: calc(50% - 320px) !important;
    width:550px;
    height:500px;
    z-index: 100;
    position: absolute;
    top: 10vh;
    left: 10%;
    color: #fff;
    background-color: #1f2130;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    padding: 25px 40px 30px;


    &__header{
        margin-bottom: 40px;
    }

    &__search{
        margin-bottom:20px;
    }

    &__userlist{
        overflow: auto;
        height: 70%
    }
}

.instructor-ta-card{
    box-shadow: 0px 0px 8px #181924;
    border-radius: 6px;
    padding:5px;
    cursor: pointer;
    overflow: hidden;
}

.assign-user-model__searchbox{
    width:410px !important
}