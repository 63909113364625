@import './../styles/variables';

.preview-header {
  margin-bottom: 38px;
}

.preview-header-ab-t {
  font-size: 12px;
  line-height: 1;
  color: map-get($colors, grey);
}

.preview-header-ab-b {
  font-size: 14px;
  color: map-get($colors, pure);
}
